import React from 'react';
import { useSelector } from 'react-redux';
import { SkeletonText } from 'ui/components/skeleton';
import { ListHorizontal } from 'ui/components';
import { formatDate } from 'utilities/common';

export const InformationOrder = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const dummy = Array.from({ length: 8 }, (v, i) => i);

  return (
    <section className="pt-2 mb-3" id="order">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Informasi Order
        </div>
      )}

      <div className="row mb-4">
        <div className="col-12">
          {StateLoading ? (
            dummy?.map((d, i) => (
              <ListHorizontal
                key={i}
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            ))
          ) : (
            <>
              <ListHorizontal title="Kode Order" content={data?.order?.code} />

              {data?.order?.customer && (
                <ListHorizontal
                  title="Customer"
                  content={data?.order?.customer?.name}
                />
              )}

              <ListHorizontal
                title="Layanan"
                content={
                  data?.order?.service_type
                    ? `${data?.order?.service_type?.service?.name} - ${data?.order?.service_type?.name}`
                    : '-'
                }
              />

              <ListHorizontal title="Dibuat Oleh" content={data?.order?.created_by} />

              <ListHorizontal
                title="Dibuat Tanggal"
                content={formatDate(data?.order?.created_at)}
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
};
