import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Skeletons } from '../skeleton';

import {
  Button,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  HiOutlineCloudDownload,
  HiOutlineSearch,
  HiOutlineX,
} from 'react-icons/hi';

const TableTool = ({
  name,
  onSearch,
  children,
  downloadable,
  onDownload,
  className,
  dropdown,
  isLoading }) => {
  const StateSearch = useSelector(
    (state) => state?.Table?.data[name]?.textSearch
  );

  const [searchTerm, setSearchTerm] = React.useState('');

  const Download = () => {
    if (!downloadable) return null;

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-top`}>Download XLS</Tooltip>}
      >
        <Button
          variant="secondary"
          className="ms-2"
          onClick={onDownload}
          style={{ height: 35 }}
        >
          <HiOutlineCloudDownload size={18} color="white" />
        </Button>
      </OverlayTrigger>
    );
  };

  React.useEffect(() => {
    if (StateSearch === searchTerm) return;

    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  React.useEffect(() => {
    if (StateSearch === '') return null;

    setSearchTerm(StateSearch);
  }, [StateSearch]);

  const c = cn(className, 'd-flex table-tools justify-content-end');

  return (
    <div
      className={cn(
        dropdown ? 'd-flex flex-column justify-content-center' : c
      )}
    >
      {isLoading ? (
        <>
          <Skeletons height="35px" width="200px" />
          <div className="ms-2">
            <Skeletons height="35px" width="50px" />
          </div>
        </>
      ) : (
        <>
          <InputGroup className="search flex-nowrap input-group-flat">
            <FormControl
              placeholder="Search ..."
              value={searchTerm}
              onChange={(input) => setSearchTerm(input.target.value)}
              className="bg-white"
            />
            <div className="input-group-text">
              {searchTerm ? (
                <HiOutlineX onClick={() => setSearchTerm('')} />
              ) : (
                <HiOutlineSearch />
              )}
            </div>
          </InputGroup>
          <Download />
        </>
      )}
      {children}
    </div>
  );
};

TableTool.defaultProps = {
  downloadable: false,
};

export default TableTool;
