import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import cn from 'classnames';

import { Location } from 'services/action';

import { Form, Select } from 'ui/components';
import { SkeletonsForm, Skeletons } from 'ui/components/skeleton';
import FormMaps from './form.location.map';

const initialState = {
  isLoading: true,
  name: '',
  personal: false,
  contact_person: '',
  phone_number: '',
  village: '',
  address: '',
  // wrt: null,
  location: null,
};

export const FormLocation = ({
  data,
  customer_id,
  type,
  onClose,
  onSuccess,
  selected,
}) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const dummy = Array.from({ length: 6 }, (v, i) => i);

  const [
    {
      name,
      personal,
      contact_person,
      phone_number,
      village,
      address,
      // wrt,
      isLoading,
      location,
    },
    setState,
  ] = React.useState(initialState);

  // const [time, setTime] = React.useState(null);
  const [postalCode, setPostalCode] = React.useState(null);

  const clearState = () => {
    setState({ ...initialState });
  };

  const $handleSubmit = async (c) => {
    const payload = {
      name,
      personal,
      contact_person: personal ? name : contact_person,
      phone_number,
      address,
      village_id: village?.village_id,
      type: type,
      customer_id: customer_id,
      location,
      latitude: location?.latitude,
      longitude: location?.longitude,
      // wrt: wrt?.value,
    };

    let submit = null;

    if (type === 'hub') {
      if (data) {
        payload.hub_coverages = data?.coverages
        submit = await Dispatch(Location.$updateHub(data?.id, payload));
      } else {
        submit = await Dispatch(Location.$createHub(payload));
      }
    } else {
      if (data && data?.id) {
        submit = await Dispatch(Location.$update(data?.id, payload));
      } else {
        submit = await Dispatch(Location.$create(payload));
      }
    }

    if (submit?.message === 'success') {
      clearState();
      if (selected) {
        onSuccess(submit?.data);
      } else {
        onSuccess();
      }
    }
  };

  const $handlePointSelected = async (val) => {
    setState((prevState) => ({
      ...prevState,
      location: {
        latitude: val?.latitude,
        longitude: val?.longitude,
      },
    }));
  };

  const $handlePointSync = async (val) => {
    setState((prevState) => ({
      ...prevState,
      address: val?.format_address,
      location: {
        latitude: val?.latitude,
        longitude: val?.longitude,
      },
    }));

    console.log('=====================', val)

    setPostalCode(val?.postal_code);
  };

  // React.useEffect(() => {
  //   // bikin jam
  //   let ts = [];
  //   const date = new Date('2015-03-25 00:00:00');

  //   for (let i = 0; i < 24; i++) {
  //     let v = date.getHours().toString().concat(':00');
  //     date.setHours(date.getHours() + 1);

  //     if (v.split(':')[0].length === 1) {
  //       v = '0' + v;
  //     }

  //     ts.push({ value: v, label: v });
  //   }

  //   setTime(ts);
  // }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;
    setState((prevState) => ({
      ...prevState,
      name: data?.name,
      personal: (data?.name === data?.contact_person) && data?.type !== 'hub' ? true : false,
      contact_person: data?.contact_person,
      phone_number: data?.phone_number,
      village: data?.region,
      address: data?.address,
      wrt: data?.wrt ? { value: data?.wrt, label: data?.wrt } : '',
      location: { latitude: data?.latitude, longitude: data?.longitude },
    }));
  }, [data]);

  console.log(postalCode, "hahaha")

  if (isLoading) {
    return (
      <Modal.Body>
        <div className="row m-0">
          <div className="col-md-6 order-last">
            {dummy?.map((d, i) => (
              <SkeletonsForm count={1} key={i} />
            ))}
          </div>
          <div className="col-md-6">
            <SkeletonsForm count={1} />
            <Skeletons height="350px" />
          </div>
        </div>
      </Modal.Body>
    );
  }

  return (
    <>
      <Modal.Body>
        <div className="row text-start">
          <div className="col-md-6 order-last">
            <div className="form-group mb-3 ">
              <Form.Input
                value={name || ''}
                required
                type="text"
                label="Nama"
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    name: e?.target?.value,
                  }))
                }
                hint={
                  type !== 'hub' && (
                    <Form.Checkbox
                      value={personal}
                      onChange={(e) => {
                        if (!personal) {
                          setState((prevState) => ({
                            ...prevState,
                            personal: true,
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            personal: false,
                          }));
                        }
                      }}
                      iconSize={18}
                      label="Personal"
                    />
                  )
                }
                error={StateForm?.errors?.name}
              />

              {!personal && (
                <Form.Input
                  required
                  value={contact_person || ''}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      contact_person: e?.target?.value,
                    }));
                  }}
                  type="text"
                  label="Kontak Person"
                  error={StateForm?.errors?.contact_person}
                />
              )}

              <Form.Input
                value={phone_number || ''}
                required
                type="text"
                label="Nomor Handphone"
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    phone_number: e?.target?.value,
                  }))
                }
                error={StateForm?.errors?.phone_number}
              />

              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Wilayah"
                  placeholder=""
                  url="/region"
                  value={village}
                  initialValue={village?.village_id}
                  params={
                    data && {
                      search: village?.postal_code,
                    }
                  }
                  row={(d) => {
                    return {
                      value: d?.village_id,
                      label: `${d?.village_name}, ${d?.district_name}, ${d?.regency_name}, ${d?.province_name} ${d?.postal_code} `,
                    };
                  }}
                  onSelected={(c) => {
                    setState((prevState) => ({
                      ...prevState,
                      village: c,
                    }));
                  }}
                  onResearch={(c) => {
                    setState((prevState) => ({
                      ...prevState,
                      village: c?.original,
                    }));
                  }}
                  research={postalCode}
                  keys={postalCode}
                  hint="cari berdasarkan kecamatan/kabupaten/kota/provinsi/kode pos. e.g: 44152"
                  errors={StateForm?.errors?.village_id}
                />
              </div>

              <Form.Textbox
                required
                value={address || ''}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
                rows={4}
                label="Alamat Lengkap"
                error={StateForm?.errors?.address}
              />

              {/* <div className="mb-3">
                <label className="form-label">Pilih Waktu Penerimaan</label>
                <Select
                  isClearable
                  classNamePrefix="select"
                  placeholder=""
                  value={wrt}
                  options={time}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      wrt: e,
                    }))
                  }
                />
              </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <FormMaps
              data={data}
              address={address}
              region={village}
              selectedLocation={location}
              onSelected={$handlePointSelected}
              onSync={$handlePointSync}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className="btn btn-light w-100"
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                'btn btn-primary w-100'
              )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
