import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';

const NavigationBack = ({ to }) => {
    const history = useSelector((state) => state?.App?.routeHistory);

    const goTo = (to) => {
        return history?.from ? history?.from : to;
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Kembali</Tooltip>}
        >
            <Link to={goTo(to)} className='btn btn-secondary px-2'>
                <Fi.FiChevronsLeft size="17" />
            </Link>
        </OverlayTrigger>
    );
};

export default NavigationBack;
