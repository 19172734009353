import React from 'react';
import * as Pin from 'react-icons/ri';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const LocationTooltipContent = ({ data }) => {
    return (
        <div className='tooltip-content'>
            <small>{data?.name}</small>
            <p>{data?.address}</p>

            <small>{`${data?.region?.district_name}, ${data?.region?.regency_name}, ${data?.region?.province_name}`}</small><br />
            <small>CP: {data?.contact_person} {`/ ${data?.phone_number}`}</small>
        </div>
    )
}

const LocationTooltip = ({ data, children }) => {
    return (
        <div className="table-col">
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-top`}><LocationTooltipContent data={data} /></Tooltip>
                }
            >
                {children ? children : <div className='row'>
                    <div className="col-md-2 text-center">
                        <Pin.RiMapPin2Fill color="gray" size={25} />
                    </div>
                    <div className="col-md-10">
                        <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                            {data?.name}
                        </div>
                        <small className="info text-uppercase text-ellipsis">
                            {data?.region ? `${data?.region?.district_name}, ${data?.region?.regency_name}, ${data?.region?.province_name}` : null}
                        </small>
                    </div>
                </div>}
            </OverlayTrigger>
        </div>
    )
}

export default LocationTooltip;