import config from 'services/table/const';
import { formatDate } from 'utilities/common';
import Action from './action';
import DataLocation from 'ui/components/data/location';

let table = { ...config };
table.url = '/tms/delivery/order';
table.filter = { load_shipment: true };
table.columns = {
  code: {
    title: 'Kode',
    sortable: true,
    width: 80,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.code}
          </div>
          {row?.order?.ref_code && (
            <small className="info">REF: {row?.order?.ref_code}</small>
          )}
        </div>
      );
    },
  },

  customer: {
    title: 'Pengirim',
    sortable: true,
    alias: 'customer.name',
    width: 120,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.shipper?.name}
          </div>
          {row?.customer?.name && (
            <small className="info text-uppercase">
              CUST: {row?.customer?.name}
            </small>
          )}
        </div>
      );
    },
  },

  service_type: {
    title: 'Service',
    width: 120,
    component: (row) => {
      return !row?.service_type ? null : (
        <div className="table-col row">
          <div className="col-md-3">
            <img
              src={row?.service_type?.service?.image}
              height={40}
              width={40}
              alt=""
            />
          </div>
          <div className="col-md-9">
            <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
              {row?.service_type?.name}
            </div>
            <small className="info text-uppercase">
              {row?.service_type?.service?.name}
            </small>
          </div>
        </div>
      );
    },
  },

  pickup_address: {
    title: 'Alamat Pickup',
    width: 120,
    component: (row) => {
      return !row?.pickup_address ? null : (
        <DataLocation data={row?.pickup_address} type="pickup" />
      );
    },
  },

  shipment_count: {
    title: 'Proses',
    sortable: false,
    class: 'text-center',
    classHeader: 'text-center',
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          {row?.type === 'ltl' && (
            <div className="fs-8 fw-semibold">
              {row?.shipment_count?.delivered} / {row?.shipment_count?.total}
            </div>
          )}
        </div>
      );
    },
  },

  total_charges: {
    title: 'Total Harga',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
  },

  document_status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 100,
    component: (row) => {
      return (
        <span className={`badge status-${row?.document_status}-lt ms-auto `}>
          {row?.document_status}
        </span>
      );
    },
  },

  created_by: {
    title: 'Dibuat Oleh',
    width: 60,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.created_by}
          </div>
          <small className="info text-uppercase">
            {formatDate(row?.created_at)}
          </small>
        </div>
      );
    },
  },

  action: {
    title: '',
    width: 40,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};
export default table;
