/* eslint-disable no-unused-vars */
import { getStore } from './services/store';
import { App, Auth } from './services/action';
import { Rest } from './utilities/rest';
import Geocode from 'react-geocode';
import moment from 'moment';
var idLocale = require('moment/locale/id');

moment.locale('id', idLocale);

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default async function bootstrap() {
  const { dispatch, getState } = getStore();
  const session = getState().Auth;

  Geocode.setApiKey(GOOGLE_KEY);
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  if (session.isAuthenticated) {
    // should read new profile
    const loggedin = await dispatch(Auth.$fetchProfile());
    if (!loggedin) {
      await dispatch(Auth.$logout());
    }
  }

  Rest.events.on('unauthorized', async (errors) => {
    await dispatch(Auth.$logout());
  });

  await dispatch(App.$appReady());
}
