import React from 'react';
import { useSelector } from 'react-redux';

import { ListHorizontal } from 'ui/components';
import { SkeletonText } from 'ui/components/skeleton';
import { formatDate, currencyFormat } from 'utilities/common';

export const DetailOrder = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const dummy = Array.from({ length: 10 }, (v, i) => i);

  return (
    <section id="shipment">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Informasi Order
        </div>
      )}

      <div className="row mb-4">
        {StateLoading || !data ? (
          dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonText count={1} />
            </div>
          ))
        ) : (
          <>
            {data?.ref_code && (
              <ListHorizontal title="Kode Ref" content={data?.ref_code} />
            )}

            <ListHorizontal
              title="Layanan"
              content={
                data?.service_type
                  ? `${data?.service_type?.service?.name} - ${data?.service_type?.name}`
                  : '-'
              }
            />
            <ListHorizontal
              title="Total Charges"
              content={currencyFormat(data?.total_charges, 'IDR')}
            />
            <ListHorizontal
              title="Total Cost"
              content={currencyFormat(data?.total_cost, 'IDR')}
            />
            <ListHorizontal
              title="Dibuat Oleh"
              content={data?.created_by}
            />
            <ListHorizontal
              title="Dibuat Tanggal"
              content={formatDate(data?.created_at)}
            />

            {data?.note && (
              <ListHorizontal title="Catatan" content={data?.note} />
            )}
          </>
        )}
      </div>
    </section>
  );
};

export const DetailSender = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const dummy = Array.from({ length: 10 }, (v, i) => i);

  return (
    <section id="sender">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Informasi Pengirim
        </div>
      )}

      <div className="row mb-4">
        {StateLoading || !data ? (
          dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonText count={1} />
            </div>
          ))
        ) : (
          <>
            {data?.customer && (
              <ListHorizontal
                title="Customer"
                content={data?.customer?.name}
              />
            )}
            {data?.shipper && (
              <ListHorizontal
                title="Shipper"
                content={data?.shipper?.name}
              />
            )}
            <ListHorizontal
              title="Proses"
              content={`${data?.shipment_count?.delivered} / ${data?.shipment_count?.total}`}
            />
          </>
        )}
      </div>
    </section>
  );
};
