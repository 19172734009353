import React from 'react';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import * as Fi from 'react-icons/fi';

const initialState = {
  privilege: null,
};

const Action = ({ data }) => {
  const Session = useSelector((state) => state.Auth?.session);

  const [,setState] = React.useState(initialState);

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.tms.delivery',
    });
    setState((prevState) => ({ ...prevState, privilege: x }));
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/order/shipment/${data?.id}`}
        >
          <Fi.FiClipboard size={15} color="#202020" className="me-2" />
          Lihat Detil
        </Link>

        {data?.order?.id && (
          <Link
            className="dropdown-item d-flex align-items-center"
            role="button"
            to={`/order/${data?.order?.id}`}
          >
            <Fi.FiClipboard size={15} color="#202020" className="me-2" />
            Lihat Dokumen Order
          </Link>
        )}
      </DropdownButton>
    </>
  );
};

export default Action;
