import React from 'react';
import _ from 'underscore';
import { useDispatch } from 'react-redux';

import { Order } from 'services/action';
import { Page } from 'ui/components';
import {
  Action,
  DetailSender,
  DetailOrder,
  Do,
  MapTrucking,
} from './component/section';
import { ContactInformation } from '../shipment/component/detail';

const DetailScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const [data, setData] = React.useState(null);
  const [legs, setLegs] = React.useState(null);
  const $onLoad = async (id) => {
    const req = await Dispatch(Order.$show(id, { load_shipment: true }));

    if (req?.message === 'success') {
      convertToLegs(req?.data);
    }
  };

  const convertToLegs = async (data) => {
    data?.delivery_orders?.forEach((d, i) =>
      console.log(
        `${d?.type} > ${d?.shipment?.origin?.name} - ${d?.shipment?.destination?.name}`
      )
    );

    if (data?.service_type?.service?.id === '61cc19d9f42b986ab50c659b') {
      let chain = [];
      let legs = [];
      let pp = [];
      let dp = [];

      let mapping = [];

      data?.delivery_orders?.forEach((el, i) => {
        mapping.push({
          pickup: el?.shipment?.origin,
          destination: el?.shipment?.destination,
          type: el?.type,
        });
      });

      // const ppx = _.groupBy(
      //   data?.delivery_orders,
      //   (el, i) => el?.shipment?.origin?.id
      // );

      data?.delivery_orders?.forEach((el, i) => {
        if (el?.type === 'pickup' || el?.type === 'drop_point') {
          const oexist = _.findWhere(pp, {
            location_id: el?.shipment?.origin?.id,
          });

          if (!oexist) {
            pp.push({
              delivery_order: el,
              location_id: el?.shipment?.origin?.id,
              type: 'pickup',
              location: el?.shipment?.origin,
            });
          }

          const dexist = _.findWhere(dp, {
            location_id: el?.shipment?.destination?.id,
            type: 'drop_point',
          });

          if (!dexist) {
            dp.push({
              delivery_order: el,
              type: 'drop_point',
              location_id: el?.shipment?.destination?.id,
              location: el?.shipment?.destination,
            });
          }
        } else {
          chain.push({
            delivery_order: el,
            type: 'pickup',
            location_id: el?.shipment?.origin?.id,
            location: el?.shipment?.origin,
          });

          chain.push({
            delivery_order: el,
            type: 'drop_point',
            location_id: el?.shipment?.destination?.id,
            location: el?.shipment?.destination,
          });
        }
      });

      // console.log('---------------');
      // console.log(ppx);
      // console.log(pp);
      // console.log(dp);
      // console.log(chain);
      // console.log('---------------');

      legs = [...pp, ...dp, ...chain];

      setLegs(legs);
    }

    setData(data);
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, []);

  return (
    <Page className="col-lg-10 col-md-10 bordered">
      <Page.Header
        pretitle="Order"
        title={data?.code}
        status={data?.document_status}
        backTo="/order"
      >
        <Action data={data} onSubmit={() => $onLoad(match?.params?.id)} />
      </Page.Header>

      <Page.Body className="bg-white border-top padding">
        <div className="row">
          <div className="col-md-4">
            <DetailOrder data={data} />
          </div>
          <div className="col-md-4">
            <DetailSender data={data} />
          </div>
          <div className="col-md-4">
            {data?.pickup_address &&
              data?.service_type?.service?.id !== '61cc19d9f42b986ab50c659b' && (
                <ContactInformation
                  title="Alamat Pickup"
                  data={data?.pickup_address}
                />
              )}
          </div>
        </div>

        {data?.service_type?.service?.id === '61cc19d9f42b986ab50c659b' ? (
          <MapTrucking data={legs} />
        ) : (
          <Do
            data={data?.delivery_orders}
            onSubmit={() => $onLoad(match?.params?.id)}
          />
        )}
      </Page.Body>
    </Page>
  );
};

export default DetailScreen;
