import React from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { Public, Location } from 'services/action';
import { formatDate } from 'utilities/common';
import { Alert } from 'ui/components';
import _ from 'underscore';

export const BulkImport = ({ customer, serviceName, onImported }) => {
  const Dispatch = useDispatch();
  const ref = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const $checkAddress = async (e) => {
    setLoading(true);

    const payload = {
      type: 'point',
      name: e?.name,
      contact_person: e?.contact_person,
      phone_number: e?.phone_number,
      address: e?.address,
      postal_code: e?.postal_code,
      is_bulkimport: true,
    };

    if (customer) {
      payload.customer_id = customer?.id;
      payload.type = e?.type
    }

    const req = await Dispatch(Location.$create(payload));
    if (req?.message === 'success' && req?.data) {
      return req?.data;
    }

    return e;
  };

  const $handleImport = async (blob) => {
    setLoading(true);
    setError(false);

    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (blob) {
        reader?.readAsArrayBuffer(blob);
        reader.onload = async function (e) {
          const buffer = e?.target?.result;
          const wb = XLSX?.read(buffer, { type: 'buffer' });
          const wsname = wb?.SheetNames[0];
          const ws = wb?.Sheets[wsname];
          const data = XLSX?.utils?.sheet_to_json(ws, { range: 1 });
          resolve(data);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      }
    });

    promise.then(async (data) => {
      if (data) {
        let array = data;

        if ((typeof array[0]?.TANGGAL_TERIMA !== "string") || (typeof array[0]?.JAM_TERIMA !== "string") || (typeof array[0]?.NAMA_PENERIMA !== "string")) {
          setLoading(false);
          setError(true);

          return
        }

        const originUniqueByKey = [...new Map(array.map(item =>
          [item["NAMA_PICKUP"], item])).values()];

        const destinationUniqueByKey = [...new Map(array.map(item =>
          [item["NAMA_PENERIMA"], item])).values()];

        const opromises = originUniqueByKey?.map(async (temp) => {
          const cao = await $checkAddress({
            name: temp?.NAMA_PICKUP,
            contact_person: temp?.KONTAK_PERSON_PICKUP || temp?.NAMA_PICKUP,
            phone_number: temp?.NOMOR_HP_PICKUP?.toString(),
            address: temp?.ALAMAT_PICKUP,
            postal_code: temp?.KODE_POS_PICKUP?.toString(),
            type: 'pickup_point'
          });

          return cao
        })

        const dpromises = destinationUniqueByKey?.map(async (temp) => {
          const cad = await $checkAddress({
            name: temp?.NAMA_PENERIMA,
            contact_person: temp?.KONTAK_PERSON_PENERIMA || temp?.NAMA_PENERIMA,
            phone_number: temp?.NOMOR_HP_PENERIMA?.toString(),
            address: temp?.ALAMAT_PENERIMA,
            postal_code: temp?.KODE_POS_PENERIMA?.toString(),
            type: 'drop_point'
          });

          return cad
        })

        const origins = await Promise.all(opromises)
        const destinations = await Promise.all(dpromises)

        array?.forEach(async (temp) => {
          let origin = null
          let destination = null
          let etdAt = null
          let etdTime = null
          if (temp?.NAMA_PICKUP || temp?.KONTAK_PERSON_PICKUP) {
            _.each(origins, (o) => {
              if (o.name === temp?.NAMA_PICKUP) {
                origin = o
              }
            })

            etdAt = {
              default: new Date(temp?.TANGGAL_PICKUP),
              formatted: formatDate(temp?.TANGGAL_PICKUP, 'YYYY-MM-DD'),
            }

            etdTime = temp?.JAM_PICKUP?.replace('.', ':')
          }

          _.each(destinations, (d) => {
            if (d.name === temp?.NAMA_PENERIMA) {
              destination = d
            }
          })

          const payload = {
            origin,
            destination,
            instruction: temp?.CATATAN,
            etd_at: etdAt,
            etd_time: etdTime,
            eta_at: {
              default: new Date(temp?.TANGGAL_TERIMA),
              formatted: formatDate(temp?.TANGGAL_TERIMA, 'YYYY-MM-DD'),
            },
            eta_time: temp?.JAM_TERIMA?.replace('.', ':'),
            ref_code: temp?.REF_CODE?.toString(),
            cod_value: parseFloat(temp?.COD_VALUE || 0),
            type: temp?.TYPE ? temp?.TYPE : 'drop_point',
          };

          if (temp?.NAMA_BARANG) {
            payload.items = [{
              item_name: temp?.NAMA_BARANG?.toString(),
              quantity: parseFloat(temp?.JUMLAH_KOLI || 0),
              weight: parseFloat(temp?.BERAT || 0),
            }];
          }

         await onImported(payload);
        });

        setTimeout(function () {
          setLoading(false);
        }, 10000);
      }
    });
  };

  return (
    <div>
      <div className="bg-white border-top mb-3 border-bottom px-3 py-3">
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Bulk Import
        </div>

        {loading ? (
          <div className="text-center my-4">
            <div className="truck-wrapper">
              <div className="truck">
                <div className="truck-container"></div>
                <div className="glases"></div>
                <div className="bonet"></div>
                <div className="base"></div>
                <div className="base-aux"></div>
                <div className="wheel-back"></div>
                <div className="wheel-front"></div>
                <div className="smoke"></div>
              </div>
            </div>
            <h3 className="title m-0 p-0">
              Mohon tunggu, proses import sedang berlangsung
            </h3>
          </div>
        ) : (
          <div>
            <Alert
              show={error}
              variant="danger"
              headerContent={
                <h3 className="list-inline text-secondary title m-0 p-0">
                  <li>Format template anda salah, lakukan terlebih perintah dibawah ini pada file excel.</li>
                  <li>1. CTRL + A</li>
                  <li>2. CTRL + 1</li>
                  <li>3. Pilih format Text.</li>
                </h3>
              }
            />
            <div className="d-flex justify-content-center align-items-center gap-2 my-3">
              <div
                className="btn btn-primary py-0"
                onClick={() => Dispatch(Public.$download(serviceName))}
              >
                Download Template
              </div>
              <div>
                <div
                  className="btn btn-outline-secondary py-0"
                  onClick={() => {
                    ref?.current?.click();
                  }}
                >
                  Upload File
                </div>
                <input
                  ref={ref}
                  type="file"
                  onChange={(e) => {
                    $handleImport(e?.target?.files[0]);
                  }}
                  className="form-control d-none"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </div>
            <small className="form-hint text-center">
              Masukkan shipment secara bersamaan dan sekaligus melalui template
              yang sudah disediakan. <br />
              Jangan menambahkan kolom pada template
            </small>
          </div>
        )}
      </div>
    </div>
  );
};
