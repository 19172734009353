import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { Table } from 'services/action';
import TableRender from './table';
import TablePagination from './pagination';
import TableTools from './tools';
import TableWrapper from './wrapper';
import TableCardRender from './table-card';

const useTable = (name, config, isLoad = true) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector((state) => state?.Table?.data[name]);

  const onPageChange = (p) => {
    Dispatch(Table.$pagination(name, p, StateTable));
  };

  const onLimitChange = (l) => {
    Dispatch(Table.$limit(name, l, StateTable));
  };

  const onSorted = (x) => {
    Dispatch(Table.$sorting(name, x, StateTable));
  };

  const onSearched = (t) => {
    Dispatch(Table.$search(name, t, StateTable));
  };

  const filter = (field, value) => {
    Dispatch(Table?.$filter(name, field, value, StateTable));
  };

  const onDownload = () => {
    Dispatch(Table.$download(name, StateTable));
  };

  const boot = (filters) => {
    let state = _.clone(StateTable)

    if (filters) {
      config.filter = filters

      if (state) {
        state.filter = filters
        state.textSearch = ''
      }
    }

    Dispatch(Table.$initialized(name, config, state));
  }

  const Render = () => {
    if (typeof StateTable === 'undefined') return null;

    return (
      <TableWrapper>
        <TableRender
          name={name}
          onSorted={onSorted}
          columns={config?.columns}
        />
      </TableWrapper>
    );
  };

  const Pagination = () => {
    return (
      <TablePagination
        position="right"
        name={name}
        onChangePage={onPageChange}
        onChangeLimit={onLimitChange}
        limit={StateTable?.limitPerPage}
      />
    );
  };

  const Tools = ({
    children,
    downloadable,
    printable,
    className,
    dropdown,
    isLoading,
  }) => {
    return (
      <TableTools
        name={name}
        onSearch={onSearched}
        children={children}
        downloadable={downloadable}
        printable={printable}
        onDownload={onDownload}
        className={className}
        dropdown={dropdown}
        isLoading={isLoading}
      />
    );
  };

  const Card = () => {
    if (typeof StateTable === 'undefined') return null;

    return (
      <TableWrapper>
        <TableCardRender name={name} columns={config?.columns} />
      </TableWrapper>
    );
  };

  React.useEffect(() => {
    if (!isLoad) return;
    Dispatch(Table.$initialized(name, config, StateTable));

  }, [name, config]);

  return {
    Card,
    Render,
    Tools,
    Pagination,
    filter,
    boot,
    State: StateTable,
  };
};

export default useTable;
