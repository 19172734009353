import React from 'react';
import cn from 'classnames';

const ListHorizontal = ({ title, content, className }) => {
  const c = cn(
    className ? className : '',
    'row py-1 mb-1 list-item m-0 align-items-center'
  );

  return (
    <div className={c}>
      <div className="col-3 p-0">{title}</div>
      <div className="col-1 p-0">:</div>
      <div className="col-8 p-0 text-start title">{content}</div>
    </div>
  );
};

export default ListHorizontal;
