/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import _ from 'underscore';

import MenuRoute from './menu.route';

const MenuConsended = ({ fluid }) => {
  const History = useHistory();
  const session = useSelector((state) => state?.Auth?.session);
  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <div className="navbar-collapse collapse" id="navbar-menu">
      <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
        <div className={fluid ? 'container-fluid' : 'container-xl'}>
          <ul className="navbar-nav">
            {MenuRoute.map((menu, index) => {
              let link = null;

              if (session?.privileges) {
                let x = _.findWhere(session?.privileges, {
                  service: menu?.service,
                });

                if (x) {
                  if (menu?.action?.includes(x?.action)) {
                    link = !menu.isMaster ? (
                      !menu.isDropdown ? (
                        <li key={index} className="nav-item">
                          <NavLink
                            to={menu.path}
                            className="nav-link"
                            activeClassName="active"
                            isActive={(match, location) => {
                              const splitLocation =
                                location.pathname.split('/');

                              if (splitLocation[1] === menu.isActive) {
                                return true;
                              }

                              if (
                                (menu.isActive === 'invoice' &&
                                  splitLocation[1] === 'invoice') ||
                                (menu.isActive === 'invoice' &&
                                  splitLocation[1] === 'unpaid')
                              ) {
                                return true;
                              }
                            }}
                          >
                            <div className="d-flex justify-content-center">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      ) : (
                        <li key={index} className="nav-item">
                          <Nav>
                            <NavDropdown
                              active={splitLocation[1] === menu?.isActive}
                              menuVariant="dark"
                              // className="no-carret"
                              title={
                                <div className="d-flex justify-content-center align-items-center m-0">
                                  <span
                                    className="nav-link-icon d-inline-block m-0 pe-2"
                                    style={{
                                      width: 'auto',
                                      height: 'auto',
                                    }}
                                  >
                                    {menu.icon}
                                  </span>
                                  {menu?.title}
                                </div>
                              }
                            >
                              {menu?.child.map((c, index) => {
                                let menu = null;

                                if (session?.privileges) {
                                  let x = _.findWhere(session?.privileges, {
                                    service: c?.service,
                                  });

                                  if (session?.privileges && x) {
                                    if (c?.action?.includes(x?.action)) {
                                      menu = (
                                        <NavDropdown.Item
                                          key={index}
                                          onClick={() => History.push(c?.path)}
                                          active={
                                            splitLocation[2] === c?.isActive ||
                                            (splitLocation?.length === 2 &&
                                              splitLocation[1] === c?.isActive)
                                          }
                                        >
                                          <div className="d-flex justify-content-center">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                              {c?.icon}
                                            </span>
                                            <span className="nav-link-title">
                                              {c?.title}
                                            </span>
                                          </div>
                                        </NavDropdown.Item>
                                      );
                                    }
                                  }
                                } else {
                                  menu = (
                                    <NavDropdown.Item
                                      key={index}
                                      onClick={() => History.push(c?.path)}
                                      active={
                                        splitLocation[2] === c?.isActive ||
                                        (splitLocation?.length === 2 &&
                                          splitLocation[1] === c?.isActive)
                                      }
                                    >
                                      <div className="d-flex justify-content-center">
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                          {c?.icon}
                                        </span>
                                        <span className="nav-link-title">
                                          {c?.title}
                                        </span>
                                      </div>
                                    </NavDropdown.Item>
                                  );
                                }

                                return menu;
                              })}
                            </NavDropdown>
                          </Nav>
                        </li>
                      )
                    ) : null;
                  }
                }
              } else {
                link = !menu.isMaster ? (
                  !menu.isDropdown ? (
                    <li key={index} className="nav-item">
                      <NavLink
                        to={menu.path}
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) => {
                          const splitLocation = location.pathname.split('/');

                          if (splitLocation[1] === menu.isActive) {
                            return true;
                          }

                          if (
                            (menu.isActive === 'invoice' &&
                              splitLocation[1] === 'invoice') ||
                            (menu.isActive === 'invoice' &&
                              splitLocation[1] === 'unpaid')
                          ) {
                            return true;
                          }
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </NavLink>
                    </li>
                  ) : (
                    <li key={index} className="nav-item">
                      <Nav>
                        <NavDropdown
                          active={splitLocation[1] === menu?.isActive}
                          menuVariant="dark"
                          // className="no-carret"
                          title={
                            <div className="d-flex justify-content-center align-items-center m-0">
                              <span
                                className="nav-link-icon d-inline-block m-0 pe-2"
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                }}
                              >
                                {menu.icon}
                              </span>
                              {menu?.title}
                            </div>
                          }
                        >
                          {menu?.child.map((c, index) => {
                            let menu = null;

                            if (session?.privileges) {
                              let x = _.findWhere(session?.privileges, {
                                service: c?.service,
                              });

                              if (session?.privileges && x) {
                                if (c?.action?.includes(x?.action)) {
                                  menu = (
                                    <NavDropdown.Item
                                      key={index}
                                      onClick={() => History.push(c?.path)}
                                      active={
                                        splitLocation[2] === c?.isActive ||
                                        (splitLocation?.length === 2 &&
                                          splitLocation[1] === c?.isActive)
                                      }
                                    >
                                      {c?.title}
                                    </NavDropdown.Item>
                                  );
                                }
                              }
                            } else {
                              menu = (
                                <NavDropdown.Item
                                  key={index}
                                  onClick={() => History.push(c?.path)}
                                  active={
                                    splitLocation[2] === c?.isActive ||
                                    (splitLocation?.length === 2 &&
                                      splitLocation[1] === c?.isActive)
                                  }
                                >
                                  <div className="d-flex justify-content-center">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                      {c?.icon}
                                    </span>
                                    <span className="nav-link-title">
                                      {c?.title}
                                    </span>
                                  </div>
                                </NavDropdown.Item>
                              );
                            }

                            return menu;
                          })}
                        </NavDropdown>
                      </Nav>
                    </li>
                  )
                ) : null;
              }
              return link;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuConsended;
