import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { Manifest } from 'services/action';
import { formatDate } from 'utilities/common';
import { FaMapMarkerAlt, FaRegDotCircle } from 'react-icons/fa';
import { ListRoute, ListItem } from 'ui/components';
import { Skeletons } from 'ui/components/skeleton';

const manifestType = {
    'last_mile': 'Last Mile',
    'pickup': 'First Mile',
    'console': 'Mid Mile',
    'forwarder': 'Forward'
}

const initialState = {
    data: null,
};

export const ManifestList = ({ delivery }) => {
    const Dispatch = useDispatch();
    const StateLoading = useSelector(
        (state) => state?.Activity?.services['Manifest.GetByDelivery']
    );

    const [{ data }, setState] = React.useState(initialState);

    const $onLoad = async () => {
        const res = await Dispatch(Manifest.$getByDelivery(delivery?.id, { sort_by: '-id' }));

        if (res?.message === 'success') {
            setState((prevState) => ({ ...prevState, data: res?.data }));
        }
    };

    const Origin = ({ manifest }) => {
        var pickup = _.filter(manifest?.legs, function (l) { return l?.type === 'pickup'; });

        const origin = pickup[0];

        if (!origin) return;

        return (
            <ListItem
                className="border-0"
                title={<div>Asal : {origin?.location?.name}</div>}
                content={
                    <div>
                        <span
                            className="pe-2 rounded "
                            style={{
                                backgroundColor: 'rgba(255,255,255,0.8)',
                            }}
                        >
                            {`${origin?.location?.region?.regency_name} - ${origin?.location?.region?.province_name}`}
                        </span>
                    </div>
                }
            />
        )
    }

    const Destination = ({ manifest }) => {
        var drop = _.filter(manifest?.legs, function (l) { return l?.type === 'drop_point'; });
        const destination = drop[0];

        if (!destination) return;

        return (
            <ListItem
                className="border-0"
                title={<div>TUJUAN : {destination?.location?.name}</div>}
                content={
                    <div>
                        <span
                            className="pe-2 rounded "
                            style={{
                                backgroundColor: 'rgba(255,255,255,0.8)',
                            }}
                        >
                            {`${destination?.location?.region?.regency_name} - ${destination?.location?.region?.province_name}`}
                        </span>
                    </div>
                }
            />
        )
    }

    const ReceivedBy = ({ manifest }) => {
        var drop = _.filter(manifest?.legs, function (l) { return l?.type === 'drop_point'; });
        const destination = drop[0];

        return (
            <div className="row border-top">
                <div className="col-md-6">
                    <div className="list-item py-2 border-0">
                        <div className="page-pretitle">Status</div>
                        <div className="title fw-semibold">
                            <span className={`badge status-${destination?.status}-lt ms-auto`}>
                                {destination?.status?.replace('new', 'Draft')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {destination?.status === 'delivered' && <div className="list-item py-2 border-0">
                        <div className="page-pretitle">Diterima Oleh</div>
                        <div className="title fw-semibold">{destination?.received_by}</div>
                    </div>}
                    {destination?.status === 'disputed' && <div className="list-item py-2 border-0">
                        <div className="page-pretitle">Catatan</div>
                        <div className="title fw-semibold">{destination?.disputed_note}</div>
                    </div>}
                </div>
            </div>
        );
    }

    React.useEffect(() => {
        if (!delivery) return;

        $onLoad();
    }, [delivery]);

    if (StateLoading) {
        return <Skeletons height="100px" width="100%" />
    }

    return (
        <section className="pt-2 mb-3" id="manifest">
            <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
                Manifest
            </div>

            <div className="table-responsive m-0 table-overflow">
                {data?.map((row, i) => (
                    <div className="col-md-12 mb-3" key={i}>
                        <div className="row m-0 border rounded">
                            <div className="col-md-6 p-2">
                                <ListItem
                                    className="cursor-pointer list-item py-2"
                                    title={formatDate(row?.schedule_at, 'DD/MM/YYYY')}
                                    content={
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>{row?.code}</div>
                                            <span className={`badge status-${row?.type}-lt ms-auto me-1`}>
                                                {manifestType[row?.type]}</span>
                                        </div>
                                    }
                                />
                                <ListItem title="Dioperasikan Oleh" content={row?.vendor ? row?.vendor?.name : 'INTERNAL'} />
                                <ListItem title="Driver/Kurir" content={row?.moda ? row?.moda?.land_freight?.driver?.name : ''} />
                            </div>
                            <div className="col-md-6 border-start p-2">
                                <div className="px-1 pt-1 mb-3">
                                    <ListRoute
                                        iconOrigin={<FaRegDotCircle size="18" color="green" />}
                                        origin={<Origin manifest={row} />}
                                        iconDestination={<FaMapMarkerAlt size="18" color="#2f83ba" />}
                                        destination={<Destination manifest={row} />}
                                    />
                                </div>

                                <ReceivedBy manifest={row} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
