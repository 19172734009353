import React from 'react';
import cn from 'classnames';

import Empty from './empty';
import LeftBar from './leftBar';
import RightBar from './rightBar';
import MainBar from './mainBar';
import Header from './section.header';
import Body from './section.body';
import Footer from './section.footer';
import Navigation from './section.navigation';
import Link from './navigation.back';

const MainSection = ({ children, className, type, onSubmit }) => {
  const cls = cn(className ? className : 'col-lg-12', 'mainbar');

  if (type === 'form') {
    return (
      <form
        className={cls}
        onSubmit={(e) => {
          onSubmit(e);
          e.preventDefault();
        }}
      >
        {children}
      </form>
    );
  }

  return <div className={cls}>{children}</div>;
};

const Page = ({
  type,
  onSubmit,
  children,
  className,
  config,
  isOnboarding,
}) => {
  const SectionHeader = () => {
    if (!config?.title) return null;

    return (
      <Header
        title={config?.title}
        pretitle={config?.pretitle}
        action={config?.action}
        submenu={config?.submenu}
      />
    );
  };

  return (
    <div className="row m-0 row-body">
      <div
        className={cn(
          'container-fluid p-0 m-0 overflow-hidden',
          isOnboarding ? 'bg-white' : null
        )}
      >
        <div className="row gx-lg-4 justify-content-center">
          <MainSection className={className} type={type} onSubmit={onSubmit}>
            <SectionHeader />

            {children}
          </MainSection>
        </div>
      </div>
    </div>
  );
};

Page.LeftBar = LeftBar;
Page.MainBar = MainBar;
Page.RightBar = RightBar;
Page.Empty = Empty;

Page.Header = Header;
Page.Body = Body;
Page.Footer = Footer;
Page.Navigation = Navigation;
Page.Back = Link;

export default Page;
