import * as Action from './action';

const defineInitialState = () => ({
  origin: null,
  destinations: null,
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$reset.ACTION:
      return defineInitialState();
    case Action.$setOrigin.ACTION:
      return {
        origin: action?.origin,
        destinations: [],
      };
    case Action.$setDestination.ACTION:
      return {
        ...state,
        destinations:
          action?.destination === null ? null : [action?.destination],
      };
    case Action.$setDestinations.ACTION:
      return {
        ...state,
        destinations: action?.destinations,
      };
    default:
      return state;
  }
}
