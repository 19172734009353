import UserScreen from './index';

const routes = [
  {
    path: '/profile/setting',
    component: UserScreen,
    userId: [1, 2, 3, 4, 5, 6],
  },
];

export default routes;
