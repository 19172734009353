import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $processing, $done } from 'services/activity/action';

export const MODULE = 'Public';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $download = ActionCreator.Async(
  MODULE,
  'Get.Template',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($download.OPERATION));

      return await Rest.GET(`${API_URL}/public/template/shipment`, payload)
        .then((response) => {
          dispatch($download.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($download.OPERATION)));
    };
  }
);
