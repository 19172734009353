import React from 'react';
import { Skeletons } from 'ui/components/skeleton';
import Chart from 'react-apexcharts'

const defaultOption = {
    chart: {
        type: "bar",
        fontFamily: 'inherit',
        height: 240,
        parentHeightOffset: 0,
        toolbar: {
            show: false,
        },
        animations: {
            enabled: false
        },
        stacked: true,
        zoom: {
            enabled: false,
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '50%',
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        opacity: 1,
    },
    grid: {
        padding: {
            top: -20,
            right: 0,
            left: -4,
            bottom: -4
        },
        strokeDashArray: 4,
        xaxis: {
            lines: {
                show: true
            }
        },
    },
    xaxis: {
        labels: {
            padding: 0,
        },
        tooltip: {
            enabled: false
        },
        axisBorder: {
            show: false,
        },
        type: 'datetime',
    },
    yaxis: {
        labels: {
            padding: 4
        },
    },
    tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
    },

    labels: [],
    colors: ['#2f83ba', '#A2C6ED', '#35B244', '#333333'],
    legend: {
        show: false,
    },
}


const CardGraph = ({ data }) => {
    const [options, setOptions] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!data) return null;

        let opt = defaultOption
        opt.labels = data?.label
        setOptions(opt)
        setLoading(false)

    }, [data]);

    return loading || !data ? (
        <Skeletons height="200px" />
    ) : (
        <Chart options={options} series={data?.service} type="bar" height={200} />
    )
}

export default CardGraph;