import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';

import { SkeletonText, SkeletonTable } from 'ui/components/skeleton';

export const InformationItems = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );
  return (
    <section className="pt-2 mb-3" id="items">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Rincian Item
        </div>
      )}

      <div className="pt-2">
        {StateLoading ? (
          <SkeletonTable headerCount={4} bodyCount={2} />
        ) : (
          <div className="table-responsive">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                    Nama Item
                  </td>
                  <td
                    className="text-center"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Koli
                  </td>
                  <td
                    className="text-center"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Koli Pickup
                  </td>
                  <td
                    className="text-center"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Koli Terkirim
                  </td>
                  <td
                    className="text-end"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Berat (Kg)
                  </td>
                  <td
                    className="text-end"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Volumetrik (Kg)
                  </td>
                  <td
                    className="text-end"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    Aktual Berat (Kg)
                  </td>
                </tr>
              </thead>
              <tbody>
                {data?.map((d, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                      {d?.item_name}
                    </td>
                    <td
                      className="text-center"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.quantity}
                    </td>
                    <td
                      className="text-center"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.quantity_pickuped || 0}
                    </td>
                    <td
                      className="text-center"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.quantity_delivered || 0}
                    </td>
                    <td
                      className="text-end"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.weight}
                    </td>
                    <td
                      className="text-end"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.m3?.toFixed(2)}
                    </td>
                    <td
                      className="text-end"
                      style={{ whiteSpace: 'nowrap', width: '15%' }}
                    >
                      {d?.m3 > d?.weight ? d?.m3?.toFixed(2) : d?.weight}
                    </td>
                  </tr>
                ))}
                <tr className="bg-light fw-semibold">
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>Total</td>
                  <td
                    className="text-center fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        return s + item?.quantity;
                      },
                      0
                    )}
                  </td>
                  <td
                    className="text-center fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        return s + (item?.quantity_pickuped || 0) ;
                      },
                      0
                    )}
                  </td>
                  <td
                    className="text-center fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        return s + (item?.quantity_delivered || 0) ;
                      },
                      0
                    )}
                  </td>
                  <td
                    className="text-end fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        return s + item?.weight;
                      },
                      0
                    )}
                  </td>
                  <td
                    className="text-end fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        return s + item?.m3;
                      },
                      0
                    )?.toFixed(2)}
                  </td>
                  <td
                    className="text-end fw-semibold"
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                  >
                    {_.reduce(
                      data,
                      function (s, item) {
                        const kg = item?.m3 > item.weight ? item?.m3 : item.weight;

                        return s + kg;
                      },
                      0
                    )?.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};
