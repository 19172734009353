/**
 * All services state definitions
 */

import * as Activity from './activity/state';
import * as App from './app/state';
import * as Table from './table/state';
import * as Grid from './grid/state';
import * as Form from './form/state';
import * as Auth from './auth/state';
import * as Map from './map/state';

const $state = {
  Activity,
  App,
  Table,
  Grid,
  Form,
  Auth,
  Map,
};

export default $state;
