import React from 'react';
import { Link } from 'react-router-dom';
import { BiAddToQueue } from 'react-icons/bi';
import cn from 'classnames';
import { Skeletons } from '../skeleton';
import NavigationBack from './navigation.back';

const Header = ({
  pretitle,
  title,
  children,
  action,
  isOnboarding,
  className,
  isLoading,
  status,
  backTo,
}) => {
  const ButtonCreate = () => {
    if (!action?.url) return null;

    return isLoading ? (
      <Skeletons height="35px" width="200px" />
    ) : (
      <Link to={action?.url} className="btn btn-primary py-1">
        <BiAddToQueue color="white" size="14" />
        <span className="d-none d-md-block ms-1">{action?.text}</span>
      </Link>
    );
  };

  return (
    <div className={cn('page-header d-print-none ', className)}>
      <div className="row justify-content-between align-items-center">
        {isOnboarding ? (
          isOnboarding
        ) : (
          <>
            <div className="col-auto">
              {pretitle ? (
                <div className="page-pretitle">{pretitle}</div>
              ) : null}
              {title ? <h2 className="page-title">{title} &nbsp; {status && <span className={`badge status-${status}-lt ms-auto `}>
                {status
                  ?.replace('_', ' ')
                  ?.replace('console', 'transit')}
              </span>
              }</h2> : null}
            </div>
            <div className="col-auto mt-2 mt-md-0">
              <div
                className="btn-list justify-content-md-end"
                style={{ flexWrap: 'unset' }}
              >
                <ButtonCreate />
                {backTo && <NavigationBack to={backTo} />}
                {children}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
