import React from 'react';
import * as Fa from 'react-icons/fa';

const MenuRoute = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Fa.FaHome size={18} />,
    isActive: '',
    action: ['read_only'],
  },

  {
    title: 'Orders',
    path: '',
    icon: <Fa.FaFileSignature size={18} />,
    isActive: 'order',
    action: ['read_only', 'manage'],
    service: 'nvo.tms.manifest',
    isDropdown: true,
    child: [
      {
        title: 'Order List',
        path: '/order',
        isActive: 'order',
        icon: <Fa.FaFileSignature size={17} />,
        action: ['read_only', 'manage'],
      },
      {
        title: 'Shipment',
        path: '/order/shipment',
        icon: <Fa.FaTruckMoving size={17} />,
        isActive: 'shipment',
        action: ['read_only'],
      },
    ],
  },
];

export default MenuRoute;
