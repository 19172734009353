import React from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import * as Bs from 'react-icons/bs';

const initialState = {
  isShow: false,
};

const Tutorial = () => {
  const [{ isShow }, setState] = React.useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const $handleClose = () => {
    clearState();
  };

  return (
    <>
      <div className="d-flex align-items-center py-2">
        <Bs.BsInfoCircleFill size={20} color="#2f83ba" className="me-2" />
        <span>
          Pelajari tentang Order disini.{' '}
          <span
            className="fw-bold text-primary ms-1 text-decoration-underline cursor-pointer"
            onClick={() =>
              setState((prevState) => ({ ...prevState, isShow: true }))
            }
          >
            Pelajari Order
          </span>
        </span>
      </div>

      <Modal show={isShow} onHide={$handleClose} centered size="md">
        <Modal.Header className="text-center d-block p-0" closeButton>
          <div className="py-3">
            <div className="fw-bold fs-6">Pelajari Order</div>
          </div>
        </Modal.Header>
        <Modal.Body className="px-2 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="pb-2">
                <div className="fs-6 fw-semibold">Apa itu order ?</div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Dokumen kesepakatan yang dibuat antara shipper dan carrier untuk melakukan pengiriman. untuk carrier dokumen ini mengandung besaran ongkos yang harus dibayar oleh customer.
                Untuk order sebenarnya ada 2 jenis service yaitu Kargo (LTL) atau Trucking (FTL).
                Service kargo, pada setiap document order bisa terdapat beberapa shipment. yang mereferrensikan titik pengiriman. sedangkan untuk trucking, shipment yang terbuat mereferrensikan setiap titik muat dan atau drop point.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="py-2">
                <div className="fs-6 fw-semibold">
                  Kenapa tidak ada fitur tambahkan job order ?
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Karena dalam proses tambahkan, edit, atau hapus job order
                dilakukan otomatis oleh sistem.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tutorial;
