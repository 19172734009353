import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StateProvider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import { getStore, setStore } from './services/store';
import { setConfig } from './utilities/config';
import App from './app';
import bootstrap from './boot';
import 'ui/themes/styles.scss';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

setConfig();
setStore();

const AppContainer = () => {
  return (
    <StateProvider store={getStore()}>
      <Router>
        <App />
        <ToastContainer limit={1} />
      </Router>
    </StateProvider>
  );
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));

bootstrap();

serviceWorker.unregister();
