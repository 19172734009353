import OrderScreen from './list/index';
import CreateScreen from './list/create';
import DetailScreen from './list/detail';
import UpdateScreen from './list/update';

import ShipmentScreen from './shipment/index';
import DetailShipmentScreen from './shipment/detail';

const routes = [
  // list
  {
    path: '/order',
    component: OrderScreen,
    action: ['read_only', 'manage'],
    service: ['nvo.tms.delivery'],
  },
  {
    path: '/order/create',
    component: CreateScreen,
    action: ['manage'],
    service: ['nvo.tms.delivery'],
  },
  // shipment
  {
    path: '/order/shipment',
    component: ShipmentScreen,
    action: ['read_only', 'manage'],
    service: ['nvo.tms.delivery'],
  },
  {
    path: '/order/shipment/:id',
    component: DetailShipmentScreen,
    action: ['read_only', 'manage'],
    service: ['nvo.tms.delivery'],
  },
  {
    path: '/order/:id',
    component: DetailScreen,
    action: ['read_only', 'manage'],
    service: ['nvo.tms.delivery'],
  },
  {
    path: '/order/:id/update',
    component: UpdateScreen,
    action: ['manage'],
    service: ['nvo.tms.delivery'],
  },
];

export default routes;
