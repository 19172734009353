import * as ActionCreator from 'utilities/action.creator';

export const MODULE = 'Maps';

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $setOrigin = ActionCreator.Default(MODULE, 'setOrigin', (origin) =>
  $setOrigin.action({ origin })
);

export const $setDestination = ActionCreator.Default(
  MODULE,
  'setDestination',
  (destination) => $setDestination.action({ destination })
);

export const $setDestinations = ActionCreator.Default(
  MODULE,
  'setDestinations',
  (destinations) => $setDestinations.action({ destinations })
);
