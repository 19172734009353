import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $failure, $requesting, $success } from '../form/action';
import { $processing, $done } from 'services/activity/action';
import _ from 'underscore';

export const MODULE = 'Location';
const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const qs = require('qs');

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'List', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/location`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $create = ActionCreator.Async(MODULE, 'Create', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/location`, payload)
      .then((response) => {
        dispatch($create.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $show = ActionCreator.Async(MODULE, 'Show', (id) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/location/${id}`)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/location/${id}`, payload)
      .then((response) => {
        dispatch($update.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $delete = ActionCreator.Async(MODULE, 'Delete', (id) => {
  return async (dispatch) => {
    dispatch($processing($delete.OPERATION));

    return await Rest.DELETE(`${API_URL}/location/${id}`)
      .then((response) => {
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($delete.OPERATION)));
  };
});

// hub
export const $getHub = ActionCreator.Async(MODULE, 'List.Hub', (payload) => {
  return async (dispatch) => {
    dispatch($processing($getHub.OPERATION));

    return await Rest.GET(`${API_URL}/location/hub`, payload)
      .then((response) => {
        dispatch($getHub.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($getHub.OPERATION)));
  };
});

export const $createHub = ActionCreator.Async(
  MODULE,
  'Create.Hub',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/location/hub`, payload)
        .then((response) => {
          dispatch($createHub.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $showHub = ActionCreator.Async(MODULE, 'Show.Hub', (id) => {
  return async (dispatch) => {
    dispatch($processing($showHub.OPERATION));

    return await Rest.GET(`${API_URL}/location/hub/${id}`)
      .then((response) => {
        dispatch($showHub.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($showHub.OPERATION)));
  };
});

export const $updateHub = ActionCreator.Async(
  MODULE,
  'Update.Hub',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/hub/${id}`, payload)
        .then((response) => {
          dispatch($updateHub.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deleteHub = ActionCreator.Async(MODULE, 'Delete.Hub', (id) => {
  return async (dispatch) => {
    dispatch($processing($deleteHub.OPERATION));

    return await Rest.DELETE(`${API_URL}/location/hub/${id}`)
      .then((response) => {
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return error;
      })
      .finally(() => dispatch($done($deleteHub.OPERATION)));
  };
});

export const $showDefaultHub = ActionCreator.Async(
  MODULE,
  'Show.DefaultHub',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($showDefaultHub.OPERATION));

      return await Rest.GET(`${API_URL}/location/hub/default`)
        .then((response) => {
          dispatch($showDefaultHub.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($showDefaultHub.OPERATION)));
    };
  }
);

export const $defaultHub = ActionCreator.Async(
  MODULE,
  'Default',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/hub/${id}/change/hub`, payload)
        .then((response) => {
          dispatch($defaultHub.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $updateCoverage = ActionCreator.Async(
  MODULE,
  'Update.Hub.Coverage',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(
        `${API_URL}/location/hub/${id}/change/coverage`,
        payload
      )
        .then((response) => {
          dispatch($updateCoverage.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $activated = ActionCreator.Async(
  MODULE,
  'Activated',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/hub/${id}/activated`, payload)
        .then((response) => {
          dispatch($activated.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deactivated = ActionCreator.Async(
  MODULE,
  'Deactivated',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(
        `${API_URL}/location/hub/${id}/deactivated`,
        payload
      )
        .then((response) => {
          dispatch($deactivated.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

// sender
export const $getSender = ActionCreator.Async(
  MODULE,
  'List.Sender',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($getSender.OPERATION));

      return await Rest.GET(`${API_URL}/location/sender`, payload)
        .then((response) => {
          dispatch($getSender.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getSender.OPERATION)));
    };
  }
);

export const $createSender = ActionCreator.Async(
  MODULE,
  'Create.Sender',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/location/sender`, payload)
        .then((response) => {
          dispatch($createSender.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $showSender = ActionCreator.Async(MODULE, 'Show.Sender', (id) => {
  return async (dispatch) => {
    dispatch($processing($showSender.OPERATION));

    return await Rest.GET(`${API_URL}/location/sender/${id}`)
      .then((response) => {
        dispatch($showSender.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($showSender.OPERATION)));
  };
});

export const $updateSender = ActionCreator.Async(
  MODULE,
  'Update.Sender',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/sender/${id}`, payload)
        .then((response) => {
          dispatch($updateSender.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deleteSender = ActionCreator.Async(
  MODULE,
  'Delete.Sender',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($deleteSender.OPERATION));

      return await Rest.DELETE(`${API_URL}/location/sender/${id}`)
        .then((response) => {
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($deleteSender.OPERATION)));
    };
  }
);

// recipient
export const $getRecipient = ActionCreator.Async(
  MODULE,
  'List.Recipient',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($getRecipient.OPERATION));

      return await Rest.GET(`${API_URL}/location/recipient`, payload)
        .then((response) => {
          dispatch($getRecipient.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getRecipient.OPERATION)));
    };
  }
);

export const $createRecipient = ActionCreator.Async(
  MODULE,
  'Create.Recipient',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/location/recipient`, payload)
        .then((response) => {
          dispatch($createRecipient.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $showRecipient = ActionCreator.Async(
  MODULE,
  'Show.Recipient',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($show.OPERATION));

      return await Rest.GET(`${API_URL}/location/recipient/${id}`)
        .then((response) => {
          dispatch($showRecipient.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($showRecipient.OPERATION)));
    };
  }
);

export const $updateRecipient = ActionCreator.Async(
  MODULE,
  'Update.Recipient',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/recipient/${id}`, payload)
        .then((response) => {
          dispatch($updateRecipient.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deleteRecipient = ActionCreator.Async(
  MODULE,
  'Delete.Recipient',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($deleteRecipient.OPERATION));

      return await Rest.DELETE(`${API_URL}/location/recipient/${id}`)
        .then((response) => {
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($deleteRecipient.OPERATION)));
    };
  }
);

// pickup
export const $getPickup = ActionCreator.Async(
  MODULE,
  'List.Pickup',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($getPickup.OPERATION));

      return await Rest.GET(`${API_URL}/location/pickup`, payload)
        .then((response) => {
          dispatch($getPickup.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getPickup.OPERATION)));
    };
  }
);

export const $createPickup = ActionCreator.Async(
  MODULE,
  'Create.Pickup',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/location/pickup`, payload)
        .then((response) => {
          dispatch($createPickup.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $showPickup = ActionCreator.Async(MODULE, 'Show.Pickup', (id) => {
  return async (dispatch) => {
    dispatch($processing($showPickup.OPERATION));

    return await Rest.GET(`${API_URL}/location/pickup/${id}`)
      .then((response) => {
        dispatch($showPickup.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($showPickup.OPERATION)));
  };
});

export const $updatePickup = ActionCreator.Async(
  MODULE,
  'Update.Pickup',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/location/pickup/${id}`, payload)
        .then((response) => {
          dispatch($updatePickup.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deletePickup = ActionCreator.Async(
  MODULE,
  'Delete.Pickup',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($deletePickup.OPERATION));

      return await Rest.DELETE(`${API_URL}/location/pickup/${id}`)
        .then((response) => {
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($deletePickup.OPERATION)));
    };
  }
);

// google place
export const $googlePlace = ActionCreator.Async(
  MODULE,
  'googlePlace',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      const body = {
        result_type: 'street_address',
        latlng: `${payload.latitude},${payload.longitude}`,
        key: GOOGLE_KEY,
      };

      const query = qs.stringify(body);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?${query}`;

      return await fetch(url, { method: 'GET' })
        .then((response) =>
          response?.json().then((p) => {
            let formatted_address = '';
            let shorted_address = '';
            let postal_code = '';

            if (p?.results[0]?.formatted_address || false) {
              let post = _.last(p?.results[0]?.address_components);

              if (post?.types?.includes('postal_code')) {
                postal_code = post?.long_name;
              }

              formatted_address = p?.results[0]?.formatted_address;
              const strings = formatted_address.match(/([^,]*),(.*)/);
              shorted_address = strings[1];
            }

            dispatch($success.action());

            return {
              longitude: payload.longitude,
              latitude: payload.latitude,
              address: formatted_address,
              format_address: shorted_address,
              postal_code: postal_code,
              note: ' ',
            };
          })
        )
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($googlePlace.OPERATION)));
    };
  }
);

export const $download = ActionCreator.Async(
  MODULE,
  'Get.Template',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($download.OPERATION));

      return await Rest.GET(`${API_URL}/public/template/location`, payload)
        .then((response) => {
          dispatch($download.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($download.OPERATION)));
    };
  }
);

export const $createBulk = ActionCreator.Async(
  MODULE,
  'Create.Bulk',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/location/bulk`, payload)
        .then((response) => {
          dispatch($createBulk.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);
