import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Shipment } from 'services/action';
import { Skeletons } from 'ui/components/skeleton';

import { Page } from 'ui/components';
import {
  InformationOrder,
  InformationShipment,
  ContactInformation,
  InformationItems,
  InformationPricing,
  Log,
  Document,
  ManifestList,
} from './component/detail';

const Maps = React.lazy(() => import('ui/components/mapbox/map.shipment'));

const DetailScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const [data, setData] = React.useState(null);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Shipment.Show']
  );

  const $onLoad = async (id) => {
    const req = await Dispatch(Shipment.$show(id, { load_shipment: true }));

    if (req?.message === 'success') {
      setData(req?.data);
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, []);

  if (StateLoading) {
    return (
      <Page className="col-lg-10 col-md-10 bordered">
        <Skeletons height="100px" width="100%" />
        <Page.Body className="bg-white padding">
          <div className="mb-3">
            <Skeletons height="50px" width="100%" />
          </div>
          <div className="mb-3">
            <Skeletons height="50px" width="100%" />
          </div>
          <div className="mb-3">
            <Skeletons height="50px" width="100%" />
          </div>
          <div className="mb-3">
            <Skeletons height="50px" width="100%" />
          </div>
        </Page.Body>
      </Page>
    )
  }

  return (
    <Page className="col-lg-10 col-md-10 bordered">
      <Page.Header
        pretitle="Shipment"
        title={data?.shipment?.code}
        status={data?.document_status}
        backTo="/order/shipment"
      />

      <Page.Body className="bg-white padding">
        <div className="row m-0">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <InformationOrder data={data} />
              </div>
              <div className="col-md-6">
                <InformationShipment data={data} />
              </div>
            </div>

            <div className="mb-3">
              <Suspense fallback={<div>Loading...</div>}>
                <Maps
                  data={data}
                  className="map-container rounded"
                />
              </Suspense>
            </div>

            <div className="row">
              {data?.shipment?.origin && (
                <div className="col-md-6">
                  <ContactInformation
                    data={data?.shipment?.origin}
                    title="Origin"
                    id="sender"
                  />
                </div>
              )}
              {data?.shipment?.destination && (
                <div className="col-md-6">
                  <ContactInformation
                    data={data?.shipment?.destination}
                    title="Destinasi"
                    id="recipient"
                  />
                </div>
              )}
            </div>

            {data?.shipment?.items && (
              <InformationItems data={data?.shipment?.items} />
            )}

            <InformationPricing data={data} />

            <ManifestList delivery={data} shipment={data?.shipment} />
          </div>
          <div className="col-md-3">
            <Document id={data?.shipment?.id} status={data?.document_status} />
            <Log code={data?.shipment?.code} />
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};

export default DetailScreen;
