import React from 'react';
import * as Fi from 'react-icons/fi';
import { Form, Select } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';
import _ from 'underscore';

const initialState = {
  items: [
    {
      item_name: '',
      quantity: '',
      weight: '',
      long: '',
      Wide: '',
      high: '',
      metric: '',
    },
  ],
};

const options = [
  { value: 'Aerospace Material', label: 'Aerospace Material' },
  {
    value: 'Hasil Pertanian / Buah / Sayuran',
    label: 'Hasil Pertanian / Buah / Sayuran',
  },
  {
    value: 'Hasil Peternakan / Daging / Ikan',
    label: 'Hasil Peternakan / Daging / Ikan',
  },
  {
    value: 'Otomotif / Sparepart / Mekanikal',
    label: 'Otomotif / Sparepart / Mekanikal',
  },
  { value: 'FMCG / Consumer Goods', label: 'FMCG / Consumer Goods' },
  {
    value: 'Peralatan Berat / Industrial',
    label: 'Peralatan Berat / Industrial',
  },
  { value: 'Oil & Gas / Bahan Kimia', label: 'Oil & Gas / Bahan Kimia' },
  { value: 'Furniture', label: 'Furniture' },
  { value: 'Constructions Material', label: 'Constructions Material' },
  { value: 'Obat-obatan / Healthcare', label: 'Obat-obatan / Healthcare' },
  { value: 'Peralatan Medis', label: 'Peralatan Medis' },
  { value: 'Elektronik', label: 'Elektronik' },
  { value: 'Lain-lain', label: 'Lain-lain' },
];

export const FormItems = ({
  service,
  serviceType,
  setItem,
  submitted,
  initialValue,
  errors,
  loading,
  isEmptyList,
}) => {
  const [{ items }, setState] = React.useState(initialState);
  const dummy = Array.from({ length: 3 }, (v, i) => i);

  const $handleItemsChange = async (i, e) => {
    let newItems = [...items];

    if (e) {
      if (e?.target) {
        newItems[i][e?.target.name] = e.target.valueNumber
          ? e.target.valueNumber
          : e.target.value;
      } else {
        newItems[i]['item'] = e;
        newItems[i]['item_name'] = e.value;
      }
    } else {
      newItems[i]['item'] = null;
      newItems[i]['item_name'] = '';
    }

    if (
      newItems[i]['long'] &&
      newItems[i]['Wide'] &&
      newItems[i]['high'] &&
      serviceType?.dividers
    ) {
      const m =
        ((parseFloat(newItems[i]['long']) *
          parseFloat(newItems[i]['Wide']) *
          parseFloat(newItems[i]['high'])) /
          serviceType?.dividers) *
        newItems[i]['quantity'];

      newItems[i]['metric'] = m?.toFixed(2);
    } else {
      newItems[i]['metric'] = null;
    }

    setState((prevState) => ({ ...prevState, items: newItems }));
  };

  const $addMoreFields = async (i) => {
    setState((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          item: prevState.items[0].item,
          item_name: prevState.items[0].item?.value,
          quantity: '',
          weight: '',
          long: null,
          Wide: null,
          high: null,
          metric: null,
          quantity_delivered: null,
          quantity_pickuped: null,
          quantity_returned: null,
          unit_price: null
        },
      ],
    }));
  };

  const $removeField = async (i) => {
    let newItems = [...items];
    newItems.splice(i, 1);
    setState((prevState) => ({ ...prevState, items: newItems }));
  };

  React.useEffect(() => {
    let newItem = [];

    if (items) {
      items?.forEach((item) => {
        if (
          item?.item_name &&
          (item?.quantity ||
          (item?.weight || item?.metric))
        ) {
          newItem.push({
            item: item?.item,
            item_name: item?.item_name,
            quantity: item?.quantity ? parseFloat(item?.quantity) : 0,
            weight: item?.weight ? parseFloat(item?.weight) : 0,
            long: item?.long ? parseFloat(item?.long) : null,
            Wide: item?.Wide ? parseFloat(item?.Wide) : null,
            high: item?.high ? parseFloat(item?.high) : null,
            metric: item?.metric ? parseFloat(item?.metric) : null,
            quantity_delivered: item?.quantity_delivered ? parseFloat(item?.quantity_delivered) : null,
            quantity_pickuped: item?.quantity_pickuped ? parseFloat(item?.quantity_pickuped) : null,
            quantity_returned: item?.quantity_returned ? parseFloat(item?.quantity_returned) : null,
            unit_price: item?.unit_price ? parseFloat(item?.unit_price) : null
          });
        }
      });
    }

    setItem(newItem);
  }, [items]);

  React.useEffect(() => {
    if (submitted) {
      setState({
        items: [
          {
            item: null,
            item_name: '',
            quantity: '',
            weight: '',
            long: '',
            Wide: '',
            high: '',
            metric: '',
            quantity_delivered: '',
            quantity_pickuped: '',
            quantity_returned: '',
            unit_price: ''
          },
        ],
      });
    }
  }, [submitted]);

  React.useEffect(() => {
    if (!isEmptyList) return;
    setState({
      items: [
        {
          item: null,
          item_name: '',
          quantity: '',
          weight: '',
          long: '',
          Wide: '',
          high: '',
          metric: '',
          quantity_delivered: '',
          quantity_pickuped: '',
          quantity_returned: '',
          unit_price: ''
        },
      ],
    });
  }, [isEmptyList]);

  React.useEffect(() => {
    if (initialValue) {



      let items = [];
      initialValue?.forEach((item) => {
        let opt = null;
        // ini penanda bahwa tidak ada optionnya
        let keyOptions = -1;

        _.each(options, function (i, key) {
          if (i?.value === item.item_name) {
            keyOptions = key;
            return;
          }
        });

        if (keyOptions >= 0) {
          opt = options[keyOptions];
        } else {
          opt = { value: item.item_name, label: item.item_name };
          options.push(opt);
        }

        items.push({
          item: opt,
          item_name: opt?.value,
          quantity: item?.quantity ? parseFloat(item?.quantity) : '',
          weight: item?.weight ? parseFloat(item?.weight) : '',
          long: item?.long ? parseFloat(item?.long) : null,
          Wide: item?.Wide ? parseFloat(item?.Wide) : null,
          high: item?.high ? parseFloat(item?.high) : null,
          metric: item?.m3 ? parseFloat(item?.m3) : item?.metric ? parseFloat(item?.metric) : null,
          quantity_delivered: item?.quantity_delivered ? parseFloat(item?.quantity_delivered) : null,
          quantity_pickuped: item?.quantity_pickuped ? parseFloat(item?.quantity_pickuped) : null,
          quantity_returned: item?.quantity_returned ? parseFloat(item?.quantity_returned) : null,
          unit_price: item?.unit_price ? parseFloat(item?.unit_price) : null
        });
      });

      if (items.length === 0) {
        items = initialState?.items
      }

      setState((prevState) => ({ ...prevState, items: items }));
    }
  }, [initialValue]);

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            Muatan Barang
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex flex-row-reverse pt-1">
            <button
              type="button"
              className="btn btn-xs btn- "
              onClick={() => $addMoreFields()}
            >
              <Fi.FiPlus /> Tambah Item
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="row">
          {dummy?.map((d, i) => (
            <div className="col-4" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      ) : (
        items?.map((e, i) => (
          <div className="row align-items-end border-bottom pt-3 pb-1" key={i}>
            <div className="col-11">
              <div className="row">
                <div
                  className={
                    serviceType?.dividers
                      ? 'col-md-3 pe-md-0'
                      : 'col-md-6 pe-md-0'
                  }
                >
                  <div className="mb-3">
                    <label className={`form-label ${service?.auto_manifest ? '': 'required'}`}>Komoditas</label>

                    <Select.Createable
                      isClearable
                      placeholder=""
                      classNamePrefix={errors ? 'is-invalid' : 'select'}
                      value={e?.item}
                      options={options}
                      name="item_name"
                      onChange={(e) => $handleItemsChange(i, e)}
                      error={errors}
                    />
                  </div>
                </div>
                <div
                  className={
                    serviceType?.dividers
                      ? 'col-md-2 pe-md-0'
                      : 'col-md-3 pe-md-0'
                  }
                >
                  <Form.Input
                    autoComplete="off"
                    label="Koli"
                    name="quantity"
                    type="number"
                    required={service?.auto_manifest? false: true}
                    value={e?.quantity}
                    onChange={(e) => $handleItemsChange(i, e)}
                    error={errors}
                    showErrorMessage={false}
                  />
                </div>
                <div
                  className={serviceType?.dividers ? 'col-md-2' : 'col-md-3'}
                >
                  <Form.Input
                    autoComplete="off"
                    label="Berat (Kg)"
                    name="weight"
                    type="number"
                    required={service?.auto_manifest? false: true}
                    value={e?.weight}
                    onChange={(e) => $handleItemsChange(i, e)}
                    error={errors}
                    showErrorMessage={false}
                  />
                </div>

                {serviceType?.dividers > 0 && (
                  <>
                    <div className="col-md-3">
                      <div className="row ">
                        <label className="form-label fs-8 ms-md-n2">
                          Dimensi (P x L x T) (cm)
                        </label>
                        <div className="col-4 p-md-0 pe-0">
                          <Form.Input
                            className="rounded-start rounded-0"
                            autoComplete="off"
                            pattern="[0-9]{0,50}"
                            name="long"
                            type="number"
                            value={e?.long}
                            onChange={(e) => $handleItemsChange(i, e)}
                          />
                        </div>
                        <div className="col-4 p-0">
                          <Form.Input
                            className="rounded-0"
                            autoComplete="off"
                            pattern="[0-9]{0,50}"
                            name="Wide"
                            type="number"
                            value={e?.Wide}
                            onChange={(e) => $handleItemsChange(i, e)}
                          />
                        </div>
                        <div className="col-4 p-md-0 ps-0">
                          <Form.Input
                            className="rounded-end rounded-0"
                            autoComplete="off"
                            pattern="[0-9]{0,50}"
                            name="high"
                            type="number"
                            value={e?.high}
                            onChange={(e) => $handleItemsChange(i, e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 pe-md-0">
                      <Form.Input
                        autoComplete="off"
                        pattern="[0-9]{0,50}"
                        label="Volumetrik (Kg)"
                        name="metric"
                        type="number"
                        disabled
                        value={e?.metric}
                        onChange={(e) => $handleItemsChange(i, e)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="col-1 ps-0 ps-md-2">
              <button
                type="button"
                className="btn btn-danger rounded p-2 mb-3"
                style={{ minHeight: 30, minWidth: '100%' }}
                onClick={() => $removeField(i)}
                disabled={items?.length === 1}
              >
                <Fi.FiX />
              </button>
            </div>
          </div>
        ))
      )}
      {errors ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors}
        </div>
      ) : null}
    </div>
  );
};
