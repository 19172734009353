import DashboardScreen from './index';

const routes = [
  {
    path: '/',
    component: DashboardScreen,
    action: ['read_only'],
    service: 'nvo.tms.dashboard',
  },
];

export default routes;
