import { Rest } from 'utilities/rest';
import { $processing, $done } from '../activity/action';
import * as ActionCreator from 'utilities/action.creator';

const MODULE = 'Grid';
const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $initialized = ActionCreator.Default(
  MODULE,
  'Initialized',
  (name, config, state) => {
    return async (dispatch) => {
      let grid = state;

      if (typeof state === 'undefined') {
        grid = config;
      }

      dispatch($request(name, grid));
    };
  }
);

export const $filter = ActionCreator.Default(
  MODULE,
  'Filter',
  (name, field, value, config) => {
    return async (dispatch) => {
      let grid = config;

      if (field === 'date_range') {
        grid.filter.start_at = value[0] ? value[0] : '';
        grid.filter.end_at = value[1] ? value[1] : '';
      } else {
        grid.filter[field] = value;
      }

      grid.currentPage = 1;

      dispatch($request(name, grid));
    };
  }
);

export const $search = ActionCreator.Default(
  MODULE,
  'Search',
  (name, text, config) => {
    return async (dispatch) => {
      let grid = config;
      grid.textSearch = text;
      grid.currentPage = 1;

      dispatch($request(name, grid));
    };
  }
);

export const $pagination = ActionCreator.Default(
  MODULE,
  'Pagination',
  (name, page, config) => {
    return async (dispatch) => {
      let grid = config;

      grid.currentPage = page;

      dispatch($request(name, grid));
    };
  }
);

export const $limit = ActionCreator.Default(
  MODULE,
  'Limit',
  (name, limit, config) => {
    return async (dispatch) => {
      let grid = config;
      grid.limitPerPage = limit;
      grid.currentPage = 1;

      dispatch($request(name, grid));
    };
  }
);

export const $download = ActionCreator.Default(
  MODULE,
  'Download',
  (name, grid) => {
    return async (dispatch) => {
      let p = params(grid);
      p.downloadable = true;

      return await Rest.GET(`${API_URL}${grid.url}`, p)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          return false;
        });
    };
  }
);

export const $reload = ActionCreator.Default(MODULE, 'Reload', (name, grid) => {
  return async (dispatch) => {
    dispatch($request(name, grid));
  };
});

const params = (grid) => {
  let params = {
    page: grid.currentPage,
    limit: grid.limitPerPage,
    search: grid.textSearch,
    downloadable: grid.downloadable,
  };

  Object.keys(params).map(function (key) {
    return (
      (params[key] === '' || params[key] === 0 || params[key] === null) &&
      delete params[key]
    );
  });

  // apply filter into params
  if (grid.filter !== null) {
    const filters = grid.filter;

    Object.keys(filters).map(function (key) {
      if (filters[key] !== '' && filters[key] !== null) {
        params[key] = filters[key];

        if (typeof filters[key] === 'object') {
          params[key] = filters[key].join('.');
        }
      }

      return filters;
    });
  }

  return params;
};

export const $request = ActionCreator.Async(MODULE, 'Request', (name, grid) => {
  return async (dispatch) => {
    dispatch($processing($request.OPERATION));

    return await Rest.GET(`${API_URL}${grid.url}`, params(grid))
      .then((response) => {
        grid.total = response?.total;
        grid.data = response?.data;
        grid.isEmpty = false;

        if (
          !response?.data ||
          response?.data?.length === 0 ||
          response?.total === 0
        ) {
          grid.isEmpty = true;
          grid.data = [];
          grid.total = 0;
        }

        dispatch($request.success({ name, grid }));

        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => {
        dispatch($done($request.OPERATION));
      });
  };
});
