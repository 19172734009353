import React from 'react';
import * as Fi from 'react-icons/fi';
import _ from 'underscore';
import { Modal } from 'react-bootstrap';

import { DatePicker, Select, Form } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';
import { FormLocation } from 'ui/screens/authorized/_shared';

import { formatDate } from 'utilities/common';
import { FormItems } from './items';
import { useSelector } from 'react-redux';

const initialState = {
  delivery_order_id: '',
  ref_code: '',
  origin: null,
  destination: null,
  pickup_note: '',
  instruction: '',
  unit_price: null,
  cod_value: null,
  items: null,
  etd_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
  etd_time: '',
  eta_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
  eta_time: '',
  loading: true,
  is_add_pickup: false,
  is_add_drop: false,
  is_add_reverse: false,
  is_add_point: false,
  errors: {},
  submitted: false,
  showForm: false,
  showFormOrigin: false,
  showFormEdit: false,
  showFormOriginEdit: false,
  showFormShipment: true,
  type: '',
};

export const Shipments = ({
  customer,
  onSave,
  onUpdate,
  onAddPoint,
  data,
  service,
  serviceType,
  firstData,
  lastData,
  isContract,
  isEmptyList,
  onCancel,
}) => {
  const Session = useSelector((state) => state.Auth?.session);
  const [privilege, setPrivilege] = React.useState(null);

  const [
    {
      delivery_order_id,
      ref_code,
      origin,
      destination,
      pickup_note,
      instruction,
      unit_price,
      cod_value,
      items,
      etd_at,
      etd_time,
      eta_at,
      eta_time,
      loading,
      is_add_pickup,
      is_add_drop,
      is_add_reverse,
      is_add_point,
      errors,
      submitted,
      showForm,
      showFormOrigin,
      showFormShipment,
      showFormEdit,
      showFormOriginEdit,
    },
    setState,
  ] = React.useState(initialState);

  const $clear = () => {
    if (onCancel) {
      onCancel();
    }
    setState({ ...initialState, showFormShipment: false });
  };

  const $resetState = () => {
    setState({ ...initialState });
  };

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      showForm: false,
      showFormOrigin: false,
      showFormEdit: false,
      showFormOriginEdit: false,
    }));
  };

  const $copyPoint = () => {
    setState((prevState) => ({
      ...initialState,
      is_add_point: true,
      is_add_pickup: false,
      is_add_drop: false,
      is_add_reverse: false,
      showFormShipment: true,
    }));
  };

  const $copyOrigin = () => {
    setState((prevState) => ({
      ...initialState,
      origin: prevState?.origin,
      pickup_note: prevState?.pickup_note,
      etd_at: prevState?.etd_at,
      etd_time: prevState?.etd_time,
      is_add_drop: true,
      is_add_pickup: false,
      showFormShipment: true,
    }));
  };

  const $copyDestination = () => {
    setState((prevState) => ({
      ...initialState,
      destination: prevState?.destination,
      instruction: prevState?.instruction,
      eta_at: prevState?.eta_at,
      eta_time: prevState?.eta_time,
      is_add_pickup: true,
      is_add_drop: false,
      showFormShipment: true,
    }));
  };

  const $copyReverse = () => {
    setState((prevState) => ({
      ...initialState,
      origin: prevState?.destination,
      destination: prevState?.origin,
      pickup_note: '',
      instruction: '',
      etd_at: prevState?.eta_at,
      eta_at: prevState?.eta_at,
      eta_time: '',
      etd_time: '',
      is_add_pickup: false,
      is_add_drop: false,
      is_add_reverse: true,
      showFormShipment: true,
    }));
  };

  const $submit = async () => {
    let tp = '';

    if (service?.id === '61bb02262a457fc68763b774') {
      tp = 'drop_point';
    } else if (service?.auto_manifest) {
      if (is_add_drop) {
        tp = 'drop_point';
      } else if (is_add_pickup) {
        tp = 'pickup';
      } else if (is_add_reverse) {
        tp = 'reverse';
      } else if (is_add_point) {
        tp = 'point'
      } else {
        tp = 'pickup';
      }
    } else {
      tp = 'drop_point';
    }

    const payload = {
      ref_code,
      origin,
      destination,
      pickup_note,
      instruction,
      unit_price,
      cod_value,
      items,
      etd_at,
      etd_time,
      eta_at,
      eta_time,
      type: tp,
    };

    if (delivery_order_id) {
      payload.delivery_order_id = delivery_order_id;
    }

    const error = {};

    if (service?.auto_manifest) {
      if (!origin) {
        error.origin = 'Lokasi pickup harus diisi';
      }
      if (!etd_at) {
        error.etd_at = 'Tanggal dan waktu pickup harus diisi';
      }
    }

    if (!destination) {
      error.destination = 'Lokasi tujuan harus diisi';
    }

    if (!destination) {
      error.destination = 'Lokasi tujuan harus diisi';
    }

    if (!eta_at) {
      error.eta_at = 'Tanggal dan waktu pengiriman harus diisi';
    }

    if (service?.id !== '61cc19d9f42b986ab50c659b') {
      if (items?.length === 0) {
        error.items = 'Detil barang harus diisi';
      }
    }

    setState((prevState) => ({
      ...prevState,
      errors: error,
    }));

    if (_.isEmpty(error)) {
      if (data) {
        onUpdate(payload);
      } else {
        onSave(payload);
      }

      setState((prevState) => ({
        ...prevState,
        loading: true,
        submitted: true,
      }));

      return true;
    }
    return false;
  };

  const $handleAddShipment = async () => {
    $submit().then((res) => {
      if (res === true) {
        $clear();
      }
    });
  };

  const $handleAddPoint = async () => {
    if (!showFormShipment) {
      onAddPoint('point');
      return;
    }

    $submit().then((res) => {
      if (res === true) {
        $copyPoint();
      }
    });
  };

  const $handleAddDrop = async () => {
    if (!showFormShipment) {
      onAddPoint('drop');
      return;
    }

    $submit().then((res) => {
      if (res === true) {
        $copyOrigin();
      }
    });
  };

  const $handleAddPickup = async () => {
    if (!showFormShipment) {
      onAddPoint('pickup');
      return;
    }

    $submit('drop_point').then((res) => {
      if (res === true) {
        $copyDestination();
      }
    });
  };

  const $handleAddReverse = async () => {
    if (!showFormShipment) {
      onAddPoint('reverse');
      return;
    }

    $submit().then((res) => {
      if (res === true) {
        $copyReverse();
      }
    });
  };

  const $getPrivilege = async () => {
    const payload = {};

    const location = _.findWhere(Session?.privileges, {
      service: 'nvo.platform.location',
    });

    if (location) {
      payload.location = location;
    }

    setPrivilege(payload);
  };

  React.useEffect(() => {
    $getPrivilege();
  }, [Session]);

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }, 2000);
  }, [loading]);

  React.useEffect(() => {
    if (_.isEmpty(errors)) return;

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        errors: {},
      }));
    }, 6000);
  }, [errors]);

  React.useEffect(() => {
    if (!data) return;

    setState((prevState) => ({
      ...prevState,
      loading: true,
      delivery_order_id: data?.delivery_order_id,
      ref_code: data?.ref_code,
      origin: data?.origin,
      destination: data?.destination,
      pickup_note: data?.pickup_note,
      instruction: data?.instruction,
      unit_price: data?.unit_price,
      cod_value: data?.cod_value,
      items: data?.items || null,
      etd_at: {
        default: data?.etd_at?.default,
        formatted: data?.etd_at?.formatted,
      },
      etd_time: data?.etd_time,
      eta_at: {
        default: data?.eta_at?.default,
        formatted: data?.eta_at?.formatted,
      },
      eta_time: data?.eta_time,
      type: firstData ? 'pickup' : data?.type,
      is_add_drop: firstData ? false : data?.type === 'drop_point'  ? true : false,
      is_add_pickup: firstData ? false : data?.type === 'pickup' ? true : false,
      is_add_point: firstData ? false : data?.type === 'point' ? true : false,
      showFormShipment: true,
    }));
  }, [data]);

  React.useEffect(() => {
    if (!lastData) return;

    setState((prevState) => ({
      ...prevState,
      loading: true,
      ...lastData,
      pickup_note: '',
      instruction: '',
      items: null,
      type: firstData ? 'pickup' : lastData?.type,
      is_add_drop: lastData?.type === 'drop_point' ? true : false,
      is_add_pickup: lastData?.type === 'pickup' ? true : false,
      is_add_reverse: lastData?.type === 'reverse' ? true : false,
      is_add_point: lastData?.type === 'point' ? true : false,
      showFormShipment: true,
    }));
  }, [lastData]);

  React.useEffect(() => {
    if (!customer) return;

    setState((prevState) => ({
      ...prevState,
      destination: null,
      origin: null,
    }));
  }, [customer]);

  React.useEffect(() => {
    if (!isEmptyList) return;
    $resetState();
  }, [isEmptyList]);


  return (
    <div className="" id="form-shipment">
      <div className="bg-white row mb-3 border-bottom px-3 py-3">
        <div className="col-sm-3">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            New Shipment
          </div>
        </div>
        <div className="col-sm-9 py-1">
          <div className="d-flex flex-md-row-reverse flex-wrap gap-2">
            {service?.auto_manifest && !isEmptyList && (
              <>
                <button
                  type="button"
                  className="btn btn-xs btn- "
                  onClick={$handleAddPoint}
                >
                  Tambah Point
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn- "
                  onClick={$handleAddReverse}
                >
                  PP (Pulang Pergi)
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn- "
                  onClick={$handleAddPickup}
                >
                  Tambah Pickup Point
                </button>
              </>
            )}
            {!isEmptyList && (
              <button
                type="button"
                className="btn btn-xs btn- "
                onClick={$handleAddDrop}
              >
                Tambah Drop Point
              </button>
            )}

            {showFormShipment && (
              <button
                type="button"
                className="btn btn-xs btn-danger "
                onClick={$clear}
                disabled={isEmptyList}
              >
                Batal
              </button>
            )}

            {showFormShipment && (
              <button
                type="button"
                className="btn btn-xs btn-primary "
                onClick={$handleAddShipment}
              >
                {data ? 'Simpan Perubahan' : 'Simpan'}
              </button>
            )}
          </div>
        </div>
      </div>

      {showFormShipment && (
        <div className="mb-3 px-md-4 py-2">
          {service?.auto_manifest ? (
            <div className="row border bg-white">
              <div className="col-md-6 p-3 pt-4 border-end border-sm-none">
                <div className="hr-text hr-text-left text-primary mb-3 ">
                  Origin
                </div>
                {loading ? (
                  <SkeletonsForm count={3} />
                ) : (
                  <div>
                    <div className="mb-3">
                      <label className="form-label required fs-8">
                        Lokasi Pickup
                      </label>
                      <div className="row">
                        <div className={'col-10 pe-0'}>
                          <Select.Remote
                            label=""
                            placeholder=""
                            url="/location"
                            value={origin}
                            params={{
                              search: origin ? origin?.name : '',
                              customer_id: customer?.id,
                              exclude: customer ? '' : 'customer'
                            }}
                            initialValue={origin?.id}
                            keys={`${customer?.id}-${origin?.id}`}
                            row={(d) => {
                              return { value: d.id, label: d.name };
                            }}
                            onSelected={(c) =>
                              setState((prevState) => ({
                                ...prevState,
                                origin: c,
                              }))
                            }
                            errors={errors?.origin}
                            disabled={is_add_drop || is_add_reverse}
                          />
                        </div>
                        <div className="col-2 text-end ps-0">
                          {origin ? (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showFormOriginEdit: true,
                                }))
                              }
                              disabled={
                                Session?.privileges &&
                                privilege?.location?.action !== 'manage'
                              }
                            >
                              <Fi.FiEdit color="white" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showFormOrigin: true,
                                }))
                              }
                              disabled={
                                origin ||
                                (Session?.privileges &&
                                  privilege?.location?.action !== 'manage')
                              }
                            >
                              <Fi.FiPlus color="white" />
                            </button>
                          )}
                        </div>

                        {origin && (
                          <div className="col-12">
                            <div className="card card-form mt-2">
                              <div className="card-body text-uppercase text-muted fs-9 p-2">
                                <p>{origin?.address}</p>

                                <small>{`${origin?.region?.district_name}, ${origin?.region?.regency_name}, ${origin?.region?.province_name}`}</small>
                                <br />
                                <small>
                                  CP: {origin?.contact_person}{' '}
                                  {`/ ${origin?.phone_number}`}
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {isContract ? (
                      <div className="row">
                        <Form.Time
                          label="Waktu Keberangkatan"
                          value={etd_time}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              etd_time: e,
                            }))
                          }
                          disabled={is_add_drop}
                          error={errors?.etd_at}
                          required
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-8">
                          <div className="mb-3">
                            <label className="form-label fs-8 required">
                              Jadwal Pickup
                            </label>
                            <div
                              className={
                                errors?.etd_at
                                  ? 'border border-danger rounded'
                                  : ''
                              }
                            >
                              {is_add_drop ? (
                                <Form.Input
                                  required
                                  type="text"
                                  value={etd_at?.formatted}
                                  disabled
                                />
                              ) : (
                                <DatePicker
                                  type="normal"
                                  value={etd_at?.default || null}
                                  format="dd/MM/yyyy"
                                  onChange={(date) =>
                                    setState((prevState) => ({
                                      ...prevState,
                                      etd_at: {
                                        default: date,
                                        formatted: formatDate(
                                          date,
                                          'YYYY-MM-DD'
                                        ),
                                      },
                                    }))
                                  }
                                />
                              )}
                            </div>
                            {errors?.etd_at && (
                              <div
                                className="invalid-feedback"
                                style={{ display: 'block' }}
                              >
                                {errors?.etd_at}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <Form.Time
                            label="Waktu Pickup"
                            value={etd_time}
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                etd_time: e,
                              }))
                            }
                            disabled={is_add_drop}
                            error={errors?.etd_at}
                            required
                          />
                        </div>
                      </div>
                    )}

                    {!isContract && (
                      <Form.Textbox
                        label="Shipper Note"
                        value={pickup_note}
                        name="pickup_note"
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            pickup_note: e?.target?.value,
                          }))
                        }
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="col-md-6 p-3 pt-4">
                <div className="hr-text hr-text-left text-primary mb-3 ">
                  Destination
                </div>
                {loading ? (
                  <SkeletonsForm count={3} />
                ) : (
                  <div>
                    <div className="mb-3">
                      <label className="form-label required fs-8">
                        Alamat Penerima
                      </label>
                      <div className="row">
                        <div className={'col-10 pe-0'}>
                          <Select.Remote
                            label=""
                            placeholder=""
                            url="/location"
                            params={{
                              search: destination ? destination?.name : '',
                              customer_id: customer?.id,
                              type: (!customer || service?.id === '61cc19d9f42b986ab50c659b') ? '' : 'drop_point',
                              exclude: customer ? '' : 'customer'
                            }}
                            value={destination}
                            initialValue={destination?.id}
                            keys={`${customer?.id}-${destination?.id}`}
                            row={(d) => {
                              return { value: d.id, label: d.name };
                            }}
                            onSelected={(c) =>
                              setState((prevState) => ({
                                ...prevState,
                                destination: c,
                              }))
                            }
                            disabled={is_add_pickup || is_add_reverse}
                            errors={errors?.destination}
                          />
                        </div>
                        <div className="col-2 text-end ps-0">
                          {destination ? (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showFormEdit: true,
                                }))
                              }
                              disabled={
                                Session?.privileges &&
                                privilege?.location?.action !== 'manage'
                              }
                            >
                              <Fi.FiEdit color="white" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showForm: true,
                                }))
                              }
                              disabled={
                                destination ||
                                (Session?.privileges &&
                                  privilege?.location?.action !== 'manage')
                              }
                            >
                              <Fi.FiPlus color="white" />
                            </button>
                          )}
                        </div>
                        {destination && (
                          <div className="col-12">
                            <div className="card card-form mt-2">
                              <div className="card-body text-uppercase text-muted fs-9 p-2">
                                <p>{destination?.address}</p>

                                <small>{`${destination?.region?.district_name}, ${destination?.region?.regency_name}, ${destination?.region?.province_name}`}</small>
                                <br />
                                <small>
                                  CP: {destination?.contact_person}{' '}
                                  {`/ ${destination?.phone_number}`}
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {isContract ? (
                      <div className="row">
                        <Form.Time
                          label="Waktu Kedatangan"
                          value={eta_time}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              eta_time: e,
                            }))
                          }
                          disabled={is_add_pickup}
                          error={errors?.eta_time}
                          required
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-8">
                          <div className="mb-3">
                            <label className="form-label fs-8 required">
                              Jadwal Penerimaan
                            </label>
                            <div
                              className={
                                errors?.eta_at
                                  ? 'border border-danger rounded'
                                  : ''
                              }
                            >
                              {is_add_pickup ? (
                                <Form.Input
                                  required
                                  type="text"
                                  value={eta_at?.formatted}
                                  disabled
                                />
                              ) : (
                                <DatePicker
                                  type="normal"
                                  value={eta_at?.default || null}
                                  format="dd/MM/yyyy"
                                  onChange={(date) =>
                                    setState((prevState) => ({
                                      ...prevState,
                                      eta_at: {
                                        default: date,
                                        formatted: formatDate(
                                          date,
                                          'YYYY-MM-DD'
                                        ),
                                      },
                                    }))
                                  }
                                  isShowTrigger
                                />
                              )}
                            </div>
                            {errors?.eta_at && (
                              <div
                                className="invalid-feedback"
                                style={{ display: 'block' }}
                              >
                                {errors?.eta_at}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <Form.Time
                            label="Waktu Terima"
                            value={eta_time}
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                eta_time: e,
                              }))
                            }
                            required
                            disabled={is_add_pickup}
                            error={errors?.eta_at}
                          />
                        </div>
                      </div>
                    )}

                    <Form.Textbox
                      label="Delivery Notes"
                      value={instruction}
                      name="instruction"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          instruction: e?.target?.value,
                        }))
                      }
                    />

                    <div className="row">
                      <div className='col-6'>
                        <Form.Input
                          label="Customer Reference Number"
                          type="text"
                          value={ref_code}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              ref_code: e?.target?.value,
                            }))
                          }
                        />
                      </div>

                      <div className='col-6'>
                        <Form.Input
                          label="COD Value"
                          type="number"
                          value={cod_value}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              cod_value: e?.target?.valueNumber,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12 border-top p-3">
                <FormItems
                  initialValue={data?.items}
                  loading={loading}
                  submitted={submitted}
                  errors={errors?.items}
                  setItem={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      items: e,
                    }))
                  }
                  service={service}
                  serviceType={serviceType}
                />
              </div>
            </div>
          ) : (
            <div className="row border bg-white">
              <div className="col-md-12 p-3 pt-4 ">
                <div className="hr-text hr-text-left text-primary mb-3">
                  Destination
                </div>
                {loading ? (
                  <SkeletonsForm count={2} />
                ) : (
                  <div className="row">
                    <div className="col-sm-8">
                      <label className="form-label fs-8">Alamat Penerima</label>
                      <div className="row mb-3">
                        <div className={'col-10 pe-0'}>
                          <Select.Remote
                            label=""
                            placeholder=""
                            url="/location"
                            params={{
                              search: destination ? destination?.name: '',
                              customer_id: customer?.id,
                              type: customer?.id ? 'drop_point': '',
                              exclude: customer ? '' : 'customer'
                            }}
                            value={destination}
                            initialValue={destination?.id}
                            keys={`${customer?.id}-${destination?.id}`}
                            row={(d) => {
                              return { value: d.id, label: d.name };
                            }}
                            onSelected={(c) =>
                              setState((prevState) => ({
                                ...prevState,
                                destination: c,
                              }))
                            }
                            errors={errors?.destination}
                          />
                        </div>
                        <div className="col-2 text-end ps-0">
                          {destination ? (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showFormEdit: true,
                                }))
                              }
                              disabled={
                                Session?.privileges &&
                                privilege?.location?.action !== 'manage'
                              }
                            >
                              <Fi.FiEdit color="white" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary rounded px-2"
                              style={{ minHeight: 30, minWidth: '80%' }}
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  showForm: true,
                                }))
                              }
                              disabled={
                                destination ||
                                (Session?.privileges &&
                                  privilege?.location?.action !== 'manage')
                              }
                            >
                              <Fi.FiPlus color="white" />
                            </button>
                          )}
                        </div>
                        {destination && (
                          <div className="col-12">
                            <div className="card card-form mt-2">
                              <div className="card-body text-uppercase text-muted fs-9 p-2">
                                <p>{destination?.address}</p>

                                <small>{`${destination?.region?.district_name}, ${destination?.region?.regency_name}, ${destination?.region?.province_name}`}</small>
                                <br />
                                <small>
                                  CP: {destination?.contact_person}{' '}
                                  {`/ ${destination?.phone_number}`}
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-8">
                          <div className="mb-3">
                            <label className="form-label fs-8">
                              Jadwal Penerimaan
                            </label>
                            <div
                              className={
                                errors?.eta_at
                                  ? 'border border-danger rounded'
                                  : ''
                              }
                            >
                              <DatePicker
                                type="normal"
                                value={eta_at?.default || null}
                                format="dd/MM/yyyy"
                                onChange={(date) =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    eta_at: {
                                      default: date,
                                      formatted: formatDate(date, 'YYYY-MM-DD'),
                                    },
                                  }))
                                }
                                isShowTrigger
                              />
                            </div>
                            {errors?.eta_at && (
                              <div
                                className="invalid-feedback"
                                style={{ display: 'block' }}
                              >
                                {errors?.eta_at}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <Form.Time
                            label="Waktu Penerimaan"
                            value={eta_time}
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                eta_time: e,
                              }))
                            }
                            error={errors?.eta_at}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <Form.Input
                        label="Customer Reference Number"
                        type="text"
                        value={ref_code}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            ref_code: e?.target?.value,
                          }))
                        }
                      />
                      <div className="row">
                        <div className='col-6'>
                          <Form.Input
                            label="Unit Price"
                            type="number"
                            value={unit_price}
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                unit_price: e?.target?.valueNumber,
                              }))
                            }
                          />
                        </div>

                        <div className='col-6'>
                          <Form.Input
                            label="COD Value"
                            type="number"
                            value={cod_value}
                            onChange={(e) =>
                              setState((prevState) => ({
                                ...prevState,
                                cod_value: e?.target?.valueNumber,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Form.Textbox
                      label="Delivery Note"
                      value={instruction}
                      name="instruction"
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          instruction: e?.target?.value,
                        }))
                      }
                    />
                  </div>
                )}

                <FormItems
                  isEmptyList={isEmptyList}
                  initialValue={data?.items}
                  loading={loading}
                  submitted={submitted}
                  errors={errors?.items}
                  setItem={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      items: e,
                    }))
                  }
                  serviceType={serviceType}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        show={showForm}
        onHide={$handleClose}
        backdrop="static"
        centered
        scrollable
        size="xl"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tambah Alamat</Modal.Title>
        </Modal.Header>
        <FormLocation
          onClose={(e) => {
            setState((prevState) => ({
              ...prevState,
              destination: e,
              showForm: false,
            }));
          }}
          onSuccess={(e) => {
            setState((prevState) => ({
              ...prevState,
              destination: e,
              showForm: false,
            }));
          }}
          customer_id={customer?.id}
          type={customer?.id ? 'drop_point' : 'point'}
          selected
        />
      </Modal>

      <Modal
        show={showFormEdit}
        onHide={$handleClose}
        backdrop="static"
        centered
        scrollable
        size="xl"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Alamat</Modal.Title>
        </Modal.Header>
        <FormLocation
          data={destination}
          onClose={(e) => {
            setState((prevState) => ({
              ...prevState,
              destination: e,
              showFormEdit: false,
            }));
          }}
          onSuccess={(e) => {
            setState((prevState) => ({
              ...prevState,
              destination: e,
              showFormEdit: false,
            }));
          }}
          customer_id={customer?.id}
          type={customer?.id ? 'drop_point' : 'point'}
          selected
        />
      </Modal>

      <Modal
        show={showFormOriginEdit}
        onHide={$handleClose}
        backdrop="static"
        centered
        scrollable
        size="xl"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Alamat</Modal.Title>
        </Modal.Header>
        <FormLocation
          data={origin}
          onClose={(e) => {
            setState((prevState) => ({
              ...prevState,
              origin: e,
              showFormOriginEdit: false,
            }));
          }}
          onSuccess={(e) => {
            setState((prevState) => ({
              ...prevState,
              origin: e,
              showFormOriginEdit: false,
            }));
          }}
          customer_id={customer?.id}
          type={customer?.id ? 'pickup_point' : 'point'}
          selected
        />
      </Modal>

      <Modal
        show={showFormOrigin}
        onHide={$handleClose}
        backdrop="static"
        centered
        scrollable
        size="xl"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tambah Alamat</Modal.Title>
        </Modal.Header>
        <FormLocation
          onClose={(e) => {
            setState((prevState) => ({
              ...prevState,
              origin: e,
              showFormOrigin: false,
            }));
          }}
          onSuccess={(e) => {
            setState((prevState) => ({
              ...prevState,
              origin: e,
              showFormOrigin: false,
            }));
          }}
          customer_id={customer?.id}
          type={customer?.id ? 'pickup_point' : 'point'}
          selected
        />
      </Modal>
    </div>
  );
};
