/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';
// import { useSelector } from 'react-redux';
import { Redirect, Switch, Route, BrowserRouter } from 'react-router-dom';
import { Layout } from 'ui/components';
import { useLocation } from 'react-router';

// Read subrouter in each subfolder
let routes = [];
const rf = require.context('./', true, /^.*_subrouter.js$/);
rf.keys().forEach((fileName) => {
  routes = routes.concat(rf(fileName).default);
});

const AuthorizedRouter = () => {
  // const session = useSelector((state) => state?.Auth?.session);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <BrowserRouter>
      <Layout>
        {splitLocation[1] === 'print' ? null : (
          <Layout.Header sticky fluid consended />
        )}
        <Layout.Body>
          <Switch>
            {routes.map(
              (route, i) => (
                // route?.userId?.includes(session?.user?.usergroup?.id) ? (
                <Route
                  key={i}
                  exact
                  path={route.path}
                  component={route.component}
                />
              )
              // ) : null
            )}
            <Redirect exact from="/*" to="/" />
          </Switch>
        </Layout.Body>
      </Layout>
    </BrowserRouter>
  );
};

export default AuthorizedRouter;
