import React from 'react';
import * as Fi from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Services } from 'services/action';
import cn from 'classnames';

import { formatDate } from 'utilities/common';

import { Form, Select, DatePicker } from 'ui/components';
import { Skeletons } from 'ui/components/skeleton';
import { FormLocation } from 'ui/screens/authorized/_shared';

const initialState = {
  loading: true,
  customer: null,
  data_service: null,
  etd_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
  etd_time: '',
  note: '',
  pickup_address: null,
  ref_code: '',
  service: null,
  service_type: null,
  type: null,
  createLocation: false,
  shipper: null,
  unit_price: null,
};

export const General = ({ onChange, data, resetShipment }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);
  const StateCustomer = useSelector((state) => state?.Auth?.customer);

  const [
    {
      loading,
      customer,
      data_service,
      etd_at,
      note,
      pickup_address,
      ref_code,
      service,
      service_type,
      type,
      etd_time,
      createLocation,
      shipper,
      unit_price,
    },
    setState,
  ] = React.useState(initialState);

  const dummy = Array.from({ length: 4 }, (v, i) => i);

  const $serviceSelected = async (e) => {
    setState((prevState) => ({
      ...prevState,
      service: e,
      service_type: null,
      unit_price: null,
    }));

    if (e?.id === '61cc19d9f42b986ab50c659b') {
      setState((prevState) => ({
        ...prevState,
        service: e,
        service_type: null,
        unit_price: null,
      }));
    }

    if (resetShipment) {
      resetShipment();
    }
  };

  const $loadService = async () => {
    const req = await Dispatch(Services.$getByUseServiceType());

    if (req?.message === 'success') {
      setState((prevState) => ({ ...prevState, data_service: req?.data }));
    }
  };

  const getLocationParams = (c) => {
    let params = {
      order_by: 'name',
    };

    if (pickup_address) {
      params.search = pickup_address?.name
    }

    if (c?.id) {
      params.customer_id = c?.id;
      params.type = 'pickup_point'
    } else {
      params.exclude = 'customer';
    }

    return params;
  };

  React.useEffect(() => {
    $loadService();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    const state = {
      customer,
      etd_at,
      note,
      pickup_address,
      ref_code,
      service_type,
      type,
      service,
      etd_time,
      shipper,
      unit_price,
    };

    onChange(state);
  }, [
    customer,
    etd_at,
    etd_time,
    note,
    pickup_address,
    ref_code,
    service_type,
    type,
    service,
    shipper,
    unit_price,
  ]);

  React.useEffect(() => {
    if (!data) return;
    setState((prevState) => ({
      ...prevState,
      customer: data?.customer,
      etd_at: {
        default: new Date(data?.created_at),
        formatted: formatDate(data?.created_at, 'YYYY-MM-DD'),
      },
      etd_time: formatDate(data?.created_at, 'HH:mm'),
      note: data?.note,
      pickup_address: data?.pickup_address,
      ref_code: data?.ref_code,
      service: data?.service_type?.service,
      service_type: data?.service_type,
      shipper: data?.shipper,
      type: null,
      unit_price: data?.total_charges,
    }));
  }, [data]);

  React.useEffect(() => {
    if (data) return;

    setState((prevState) => ({
      ...prevState,
      customer: StateCustomer,
      shipper: {
        name: StateCustomer?.company_name ? StateCustomer?.company_name : StateCustomer?.name,
        phone: StateCustomer?.pic_phone,
        address: StateCustomer?.address ,
      },
    }));
  }, [StateCustomer]);

  return (
    <div className="sidebar h-100">
      <div className="form-group sidebar-body-create py-3 ps-2 pe-3">
        <div className="mb-3">
          <label className="form-label required fs-8">Pilih Layanan</label>
          <div className="d-flex mt-3 flex-wrap align-items-center justify-content-around gap-1">
            {loading
              ? dummy?.map((d, i) => (
                  <Skeletons key={i} height={75} width={75} />
                ))
              : data_service?.map((e, i) => (
                  <div key={i} className="text-center position-relative">
                    <label
                      className={cn(
                        'service-radio-image cursor-pointer border rounded-2 d-flex flex-column flex-wrap align-items-center justify-content-center'
                      )}
                      style={{ height: 75, width: 75 }}
                    >
                      {service?.id === e?.id && (
                        <div
                          className="position-absolute h-100 w-100 overlay border border-primary border-2 rounded-2 d-flex flex-wrap align-items-center justify-content-center"
                          style={{ zIndex: 2 }}
                        >
                          <div className="">
                            <Fi.FiCheckCircle
                              size={50}
                              color="#2f83ba"
                              style={{ opacity: 1 }}
                            />
                          </div>
                        </div>
                      )}

                      <input
                        type="radio"
                        name="layout"
                        onChange={() => {
                          $serviceSelected(e);
                        }}
                      />
                      <img src={e?.image} alt="" className={cn('img-fluid')} />
                      <div className="page-pretitle mt-n1">{e?.name}</div>
                    </label>
                  </div>
                ))}
          </div>
        </div>

        {service && (
          <div className="mb-3">
            <Select.Remote
              required
              label="Pilih Tipe Layanan"
              placeholder=""
              url="/tms/service/type"
              value={service_type}
              initialValue={service_type?.id}
              params={{
                service_id: service?.id,
                search: service?.id === '61bb02262a457fc68763b774' ?  'Intracity' : ''
              }}
              row={(d) => {
                return {
                  value: d.id,
                  label: d.name,
                };
              }}
              onSelected={(c) => {
                setState((prevState) => ({
                  ...prevState,
                  service_type: c,
                }));
              }}
              disabled={service?.id === '61bb02262a457fc68763b774'}
              keys={service?.id}
              firstData
              hint={
                service_type?.pricing_schema === 'weight_based'
                  ? `min. weight : ${service_type?.min_weight} Kg`
                  : null
              }
            />
          </div>
        )}

        <div className="row pt-2 ">
          <div className="col-6">
            <Form.Input
              label="Pengirim"
              type="text"
              value={shipper?.name}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  shipper: {
                    ...shipper,
                    name: e?.target?.value,
                  },
                }))
              }
              required
              disabled
              error={StateForm?.errors?.shipper}
            />
          </div>
          <div className="col-6">
            <Form.Input
              label="No. Handphone"
              type="text"
              value={shipper?.phone}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  shipper: {
                    ...shipper,
                    phone: e?.target?.value,
                  },
                }))
              }
              required
              disabled
              error={StateForm?.errors['shipper.phone']}
            />
          </div>

          <div className="col-12">
            <Form.Textbox
              label="Alamat"
              type="text"
              disabled
              value={shipper?.address}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  shipper: {
                    ...shipper,
                    address: e?.target?.value,
                  },
                }))
              }
            />
          </div>
        </div>

        {service && service?.auto_manifest && (
          <div className="row pt-2">
            <div className="col-md-6">
              <Form.Input
                label="Unit Price"
                type="number"
                value={unit_price}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    unit_price: e?.target?.valueNumber,
                  }))
                }
              />
            </div>
            <div className="col-md-6">
              <Form.Input
                label="Customer Reference Number"
                type="text"
                value={ref_code}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    ref_code: e?.target?.value,
                  }))
                }
              />
            </div>
          </div>
        )}

        {service && service?.id !== '61cc19d9f42b986ab50c659b' && (
          <div>
            <div className="mb-3">
              <label className="form-label fs-8">Lokasi Pickup</label>
              <div className="row">
                <div className={'col-10 pe-0'}>
                  <Select.Remote
                    label=""
                    placeholder=""
                    url="/location"
                    params={getLocationParams(customer)}
                    value={pickup_address}
                    initialValue={pickup_address?.id}
                    keys={`${customer?.id}-${pickup_address?.id}`}
                    row={(d) => {
                      return { value: d.id, label: d.name };
                    }}
                    onSelected={(c) =>
                      setState((prevState) => ({
                        ...prevState,
                        pickup_address: c,
                      }))
                    }
                    errors={StateForm?.errors?.pickup_address_id}
                  />
                </div>
                <div className="col-2 text-end ps-0">
                  <button
                    type="button"
                    className="btn btn-primary rounded px-2"
                    style={{ minHeight: 30, minWidth: '80%' }}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        createLocation: true,
                      }))
                    }
                  >
                    <Fi.FiPlus color="white" />
                  </button>
                </div>
              </div>

              {pickup_address && (
                <div className="card card-form mt-2">
                  <div className="card-body text-uppercase text-muted fs-9 p-2">
                    <p>{pickup_address?.address}</p>

                    <small>{`${pickup_address?.region?.district_name}, ${pickup_address?.region?.regency_name}, ${pickup_address?.region?.province_name}`}</small>
                    <br />
                    <small>
                      CP: {pickup_address?.contact_person}{' '}
                      {`/ ${pickup_address?.phone_number}`}
                    </small>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-8">
                <div className="mb-3 disabled">
                  <label className="form-label fs-8">Jadwal Pickup</label>
                  <div
                    className={
                      StateForm?.errors?.etd_at
                        ? 'border border-danger rounded'
                        : ''
                    }
                  >
                    <DatePicker
                      type="normal"
                      value={etd_at?.default || null}
                      format="dd/MM/yyyy"
                      onChange={(date) =>
                        setState((prevState) => ({
                          ...prevState,
                          etd_at: {
                            default: date,
                            formatted: formatDate(date, 'YYYY-MM-DD'),
                          },
                        }))
                      }
                      isShowTrigger
                    />
                  </div>
                  {StateForm?.errors?.etd_at && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      {StateForm?.errors?.etd_at}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
                <Form.Time
                  label="Waktu Pickup"
                  value={etd_time}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      etd_time: e,
                    }))
                  }
                  error={StateForm?.errors?.etd_at}
                />
              </div>
            </div>
          </div>
        )}

        <Form.Textbox
          label="Catatan"
          value={note}
          onChange={(e) =>
            setState((prevState) => ({
              ...prevState,
              note: e.target.value,
            }))
          }
        />
      </div>

      <Modal
        show={createLocation}
        onHide={() =>
          setState((prevState) => ({ ...prevState, createLocation: false }))
        }
        backdrop="static"
        centered
        scrollable
        size="xl"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tambah Alamat</Modal.Title>
        </Modal.Header>
        <FormLocation
          onClose={(e) => {
            setState((prevState) => ({
              ...prevState,
              pickup_address: e,
              createLocation: false,
            }));
          }}
          onSuccess={(e) => {
            setState((prevState) => ({
              ...prevState,
              pickup_address: e,
              createLocation: false,
            }));
          }}
          customer_id={customer?.id}
          type={customer?.id ? 'pickup_point' : 'point'}
          selected
        />
      </Modal>
    </div>
  );
};
