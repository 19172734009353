import React from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { Order } from 'services/action';

import { Print } from 'ui/components';
import logoBlack from 'ui/assets/envio-black.png';
import { formatDate, currencyFormat } from 'utilities/common';

const PrintOrder = ({ match }) => {
  const Dispatch = useDispatch();
  const client = useSelector((state) => state?.Auth?.session?.client);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const [data, setData] = React.useState(null);
  const [page, setPage] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [firstPage] = React.useState(10);
  const [limit] = React.useState(15);

  const $onLoad = async (id) => {
    const req = await Dispatch(Order.$show(id));
    if (req?.message === 'success') {
      setData(req?.data);
    }
  };

  React.useEffect(() => {
    setLoaded(false);
    $onLoad(match?.params?.id);
  }, []);

  React.useEffect(() => {
    if (data?.delivery_orders) {
      let pages = _.chunk(data?.delivery_orders, firstPage);
      if (data?.delivery_orders?.length > firstPage) {
        let first = pages[0];
        let last = _.rest(data?.delivery_orders, first?.length);
        pages = _.chunk(last, limit);
        pages.unshift(first);
      } else {
        setPage(pages);
      }

      setPage(pages);
      setLoaded(true);
    }
  }, [data]);

  if (StateLoading || data === null || !loaded) return null;

  return (
    <div>
      {!data || data === null ? null : (
        <Print
          title={`Order - ${data?.code}`}
          size="A4"
          content={renderToString(
            page?.map((p, id) => (
              <section
                className="sheet page-break"
                key={id}
                style={{ padding: '20px 60px', position: 'relative' }}
              >
                {id === 0 ? (
                  <>
                    <table
                      className="body"
                      style={{
                        borderBottom: `2px solid #2f83ba`,
                      }}
                    >
                      <tr style={{ verticalAlign: 'center' }}>
                        <td
                          style={{
                            textAlign: 'left',
                            paddingTop: 30,
                            width: '30%',
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ fontSize: 30, fontWeight: 800 }}
                          >
                            ORDER
                          </p>
                          <p
                            className="bold mb-0"
                            style={{ fontSize: 12, paddingTop: 10 }}
                          >
                            {client?.company_name || client?.name}
                          </p>
                          <p style={{ fontSize: 11, lineHeight: 1.5 }}>
                            {client?.address}
                          </p>
                        </td>

                        <td style={{ width: '50%', textAlign: 'right' }}>
                          <img
                            src={client?.avatar || logoBlack}
                            alt=""
                            height="50px"
                          />
                        </td>
                      </tr>
                    </table>

                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <table className="body ">
                        <tr style={{ verticalAlign: 'top' }}>
                          <td
                            style={{
                              textAlign: 'left',
                              width: '45%',
                              padding: 10,
                              paddingLeft: 0,
                            }}
                          >
                            <p className="bold" style={{ fontSize: 12 }}>
                              Shipper:
                            </p>
                            <p
                              className="mb-0"
                              style={{
                                fontSize: 13,
                                lineHeight: 1.5,
                                marginBlockEnd: 0,
                              }}
                            >
                              {data?.shipper?.name}
                            </p>
                            <p
                              className="mb-0"
                              style={{ fontSize: 11, lineHeight: 1.5 }}
                            >
                              {data?.customer
                                ? `${
                                    data?.customer?.company_name
                                      ? data?.customer?.company_name
                                      : data?.customer?.name
                                  } - ${data?.shipper?.phone}`
                                : data?.shipper?.phone}
                            </p>
                            <p
                              className=""
                              style={{ fontSize: 11, lineHeight: 1.5 }}
                            >
                              {data?.shipper?.address}
                            </p>
                          </td>

                          <td
                            style={{
                              textAlign: 'left',
                              width: '20%',
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: 'left',
                              width: '35%',
                              padding: 10,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p className="bold mb-0" style={{ fontSize: 12 }}>
                                ORDER #
                              </p>
                              <p>{data?.code}</p>
                            </div>

                            {
                              data?.ref_code && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <p className="bold mb-0" style={{ fontSize: 12 }}>
                                    REFF #
                                  </p>
                                  <p>{data?.ref_code}</p>
                                </div>
                              )
                            }

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p className="bold mb-0" style={{ fontSize: 12 }}>
                                ORDER DATE
                              </p>
                              <p>
                                {formatDate(data?.created_at, 'DD/MM/YYYY')}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </>
                ) : null}

                <div style={{ marginTop: 10 }} className="">
                  <table className="bordered">
                    <thead style={{ backgroundColor: '#2f83ba' }}>
                      <tr className="bordered">
                        <td
                          className="center tb-spacing"
                          style={{
                            padding: 5,
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            No.
                          </p>
                        </td>
                        <td
                          className="left tb-spacing"
                          style={{
                            padding: 5,
                            width: '20%',
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            Kode Shipment
                          </p>
                        </td>
                        <td
                          className="left tb-spacing"
                          style={{
                            padding: 5,
                            width: '28%',
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            Origin
                          </p>
                        </td>
                        <td
                          className="left tb-spacing"
                          style={{
                            padding: 5,
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12, width: '28%' }}
                          >
                            Tujuan
                          </p>
                        </td>
                        <td
                          className="center tb-spacing"
                          style={{
                            padding: 5,
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            Total Koli
                          </p>
                        </td>
                        <td
                          className="center tb-spacing"
                          style={{
                            padding: 5,
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            Total Berat
                          </p>
                        </td>
                        <td
                          className="center tb-spacing"
                          style={{
                            padding: 5,
                          }}
                        >
                          <p
                            className="bold mb-0 text-white"
                            style={{ fontSize: 12 }}
                          >
                            COD
                          </p>
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {p?.map((d, i) => (
                        <tr key={i} className="bordered">
                          <td className="bordered center">
                            <p className=" mb-0">
                              {id > 1
                                ? limit + (firstPage * (id - 1) + i + 1)
                                : firstPage * id + i + 1}
                            </p>
                          </td>
                          <td className="bordered left">
                            <p className="bold mb-0">{d?.shipment?.code}</p>
                            {d?.shipment?.ref_code ? (
                              <p className=" mb-0" style={{ fontSize: 9 }}>
                                Ref Code: {d?.shipment?.ref_code}
                              </p>
                            ) : null}
                          </td>
                          <td className="bordered left">
                            {d?.shipment?.origin?.name ===
                            d?.shipment?.origin?.contact_person ? null : (
                              <p className="bold mb-0">
                                {d?.shipment?.origin?.name}
                              </p>
                            )}
                            <p className="bold mb-0">
                              {d?.shipment?.origin?.contact_person} -{' '}
                              {d?.shipment?.origin?.phone_number}
                            </p>
                            <p className="mb-0">
                              {d?.shipment?.origin?.region?.district_name},{' '}
                              {d?.shipment?.origin?.region?.regency_name},{' '}
                              {d?.shipment?.origin?.region?.province_name}
                            </p>
                          </td>
                          <td className="bordered left">
                            {d?.shipment?.destination?.name ===
                            d?.shipment?.destination?.contact_person ? null : (
                              <p className="bold mb-0">
                                {d?.shipment?.destination?.name}
                              </p>
                            )}
                            <p className="bold mb-0">
                              {d?.shipment?.destination?.contact_person} -{' '}
                              {d?.shipment?.destination?.phone_number}
                            </p>
                            <p className="mb-0">
                              {d?.shipment?.destination?.region?.district_name},{' '}
                              {d?.shipment?.destination?.region?.regency_name},{' '}
                              {d?.shipment?.destination?.region?.province_name}
                            </p>
                          </td>
                          <td className="bordered center">
                            <p className=" mb-0">
                              {d?.shipment?.total_quantity}
                            </p>
                          </td>
                          <td className="bordered center">
                            <p className=" mb-0">{d?.shipment?.total_weight}</p>
                          </td>
                          <td className="bordered center">
                            <p className=" mb-0">{currencyFormat(d?.shipment?.cod_value)}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {page?.length === id + 1 && (
                  <div
                  // style={{
                  //   position: 'absolute',
                  //   bottom: 50,
                  //   right: 60,
                  //   left: 60,
                  // }}
                  >
                    <table className="body bordered" style={{ marginTop: 20 }}>
                      <tr className="bordered">
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Shipper</p>
                        </td>
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Driver</p>
                        </td>
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Penerima / Recipient</p>
                        </td>
                      </tr>
                      <tr className="bordered">
                        <td
                          className="bordered"
                          style={{ width: '33%', height: 80 }}
                        ></td>
                        <td
                          className="bordered"
                          style={{ width: '33%', height: 80 }}
                        ></td>
                        <td
                          className="bordered"
                          style={{ width: '33%', height: 80, padding: 0 }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{ marginBottom: 60, fontSize: 8 }}
                            >
                              Diterima dalam kondisi yang baik dan cukup
                            </p>
                            <p className="mb-0 bold" style={{ fontSize: 8 }}>
                              Nama / TTD / Stemple
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr className="bordered">
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Tanggal :</p>
                        </td>
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Tanggal :</p>
                        </td>
                        <td className="bordered" style={{ width: '33%' }}>
                          <p className="bold mb-0">Tanggal :</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
              </section>
            ))
          )}
        />
      )}
    </div>
  );
};

export default PrintOrder;
