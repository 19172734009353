import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Location } from 'services/action';
import { useDispatch } from 'react-redux';
import * as Fa from 'react-icons/fa';
import PlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
  geocodeByAddress,
} from 'react-places-autocomplete';
import _ from 'underscore';
import { Alert } from 'ui/components';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const FormMaps = ({
  data,
  region,
  onSelected,
  onLoading,
  onSync,
  selectedLocation,
  address,
}) => {
  const Dispatch = useDispatch();
  const [map, setMap] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [libraries] = React.useState(['places']);
  const [regionLocation, setRegionLocation] = React.useState(null);
  const [curr, setCurr] = React.useState(null);
  const [warning, setWarning] = React.useState(false);
  const [showSuggestion, setShowSuggestion] = React.useState(false);

  const [center, setCenter] = React.useState({
    lat: -6.170166,
    lng: 106.831375,
  });

  const searchOptions = {
    componentRestrictions: { country: 'id' },
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_KEY,
    libraries: libraries,
    region: 'ID',
    language: 'id',
  });

  const onCenterChanged = React.useCallback(async () => {
    const payload = {
      latitude: map?.getCenter().lat(),
      longitude: map?.getCenter().lng(),
    };

    const resp = await Dispatch(Location.$googlePlace(payload));
    if (resp) {
      onSelected(resp);
      setCurr(resp);
    }
  }, [map]);

  const reCenter = async () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      setCenter({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      });
    });
  };

  const onLoad = React.useCallback(
    async (map) => {
      if (selectedLocation) {
        setCenter({
          lat: selectedLocation?.latitude,
          lng: selectedLocation?.longitude,
        });
      } else {
        reCenter();
      }
      setMap(map);
    },
    [selectedLocation]
  );

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  const $handleSuggestionSelected = async (value) => {
    setValue('');
    setShowSuggestion(false);

    const results = await geocodeByPlaceId(value);
    const latLng = await getLatLng(results[0]);

    let formatted_address = '';
    let shorted_address = '';
    let postal_code = '';

    if (results[0]?.formatted_address || false) {
      let post = _.last(results[0]?.address_components);

      if (post?.types?.includes('postal_code')) {
        postal_code = post?.long_name;
      }

      formatted_address = results[0]?.formatted_address;
      const strings = formatted_address.match(/([^,]*),(.*)/);
      shorted_address = strings[1];
    }

    const payload = {
      longitude: results[0]?.geometry?.location?.lng(),
      latitude: results[0]?.geometry?.location?.lat(),
      address: formatted_address,
      format_address: shorted_address,
      postal_code: postal_code,
      note: ' ',
    };

    setCurr(payload);
    setCenter({
      lat: latLng.lat,
      lng: latLng.lng,
    });
    onCenterChanged();
  };

  const $handleSearch = (val) => {
    setValue(val);
    setShowSuggestion(true);
  };

  const handleDebounceMap = async () => {
    setWarning(true);

    setTimeout(() => {
      setWarning(false);
    }, 2000);

    setCurr(null);
    setCenter(regionLocation);
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(regionLocation);
    map.fitBounds(bounds);

    var listener = new window.google.maps.event.addListener(
      map,
      'idle',
      function () {
        if (map.getZoom() > 15) map.setZoom(15);
        new window.google.maps.event.removeListener(listener);
      }
    );
  };

  React.useEffect(() => {
    if (!region) return;
    if (data?.region?.village_id === region?.village_id) return;

    async function getArea(x) {
      const a = await geocodeByAddress(x);

      const opt = _.filter(a, function (i) {
        if (i?.types?.includes('postal_code')) {
          return i;
        }
      });

      const latLng = await getLatLng(opt[0]);

      if (warning) {
        setCenter({
          lat: latLng?.lat,
          lng: latLng?.lng,
        });
      }

      if (!curr && !warning && !value) {
        setCenter({
          lat: latLng?.lat,
          lng: latLng?.lng,
        });
      }

      setRegionLocation({
        lat: latLng?.lat,
        lng: latLng?.lng,
      });
    }

    const delayDebounceFn = setTimeout(() => {
      getArea(region?.postal_code);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [region, warning]);

  React.useEffect(() => {
    if (!curr || !region || !regionLocation) return;
    if (data?.region?.village_id === region?.village_id) return;

    if (curr?.postal_code && curr?.postal_code !== region?.postal_code) {
      handleDebounceMap();
    }
  }, [curr, region, regionLocation]);

  return isLoaded ? (
    <div className="">
      <div className="form-label">Cari Titik Alamat</div>
      <PlacesAutocomplete
        value={value}
        onChange={$handleSearch}
        searchOptions={searchOptions}
        ref={(c) => {
          if (!c) return;
          c.handleInputOnBlur = () => {};
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="suggestion-container">
            <input
              {...getInputProps({
                autoFocus: false,
              })}
              className="form-control"
            />
            {showSuggestion && (
              <div className="suggestion-box">
                {loading && <p className="mb-0 fw-bold ">Hasil Pencarian</p>}
                {suggestions?.length !== 0 ? (
                  <div className="suggestion-list">
                    {suggestions.map((suggestions, k) => {
                      return (
                        <div
                          onClick={() => {
                            $handleSuggestionSelected(suggestions?.placeId);
                          }}
                          className="suggestion-option d-flex align-items-center py-2 mt-auto border-bottom"
                          key={k}
                          style={{ cursor: 'pointer' }}
                        >
                          <span>
                            <Fa.FaMapMarkerAlt size={18} color="#2f83ba" />
                          </span>
                          <div className="ms-3">
                            <div className="text-dark">
                              {suggestions?.description}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : !loading && value !== '' && suggestions.length === 0 ? (
                  <div className="border-bottom py-3 mt-3 ">
                    <p className="mb-0 fw-bold text-center text-warning">
                      Hasil Tidak Ditemukan
                    </p>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>

      <Alert
        show={warning}
        variant="danger"
        headerContent="Titik lokasi berada diluar area wilayah yang telah dipilih"
        className="mb-0"
      />
      <div className="map-location mt-3">
        <GoogleMap
          mapContainerClassName="map-location"
          center={center}
          zoom={15}
          fullscreenControl={false}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={onCenterChanged}
          onDragStart={onLoading}
          options={{ scrollwheel: false }}
        >
          <div className="markerfixed">
            <Fa.FaMapMarkerAlt size={35} color="#2f83ba" />
          </div>
          {curr && curr?.address && (
            <div className="map-address">
              {address !== curr?.format_address && (
                <button
                  onClick={() => {
                    onSync(curr);
                  }}
                  className="btn btn-success btn-xs"
                >
                  Sesuaikan Input
                </button>
              )}
              <h3>Alamat Berdasarkan Map</h3>
              <p>{curr?.address}</p>
            </div>
          )}
        </GoogleMap>
      </div>
    </div>
  ) : null;
};

export default FormMaps;
