import React from 'react';
import cn from 'classnames';
import * as Fi from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';

import { Select, DateRangePicker } from 'ui/components';
import { formatDate } from 'utilities/common';
import { Table } from 'services/action';

const statusOptions = [
  { value: 'new', label: 'Baru' },
  { value: 'manifested', label: 'Manifested' },
  { value: 'on_pickup', label: 'On Pickup' },
  { value: 'on_transit', label: 'On Transit' },
  { value: 'on_delivery', label: 'On Delivery' },
  { value: 'console', label: 'Transit' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'disputed', label: 'Disputed' },
  { value: 'voided', label: 'Dibatalkan' },
];

const initialState = {
  status: null,
  schedule: null,
  etd: null,
  atd: null,
  eta: null,
  ata: null
};

const Filter = ({ show, onClose, table, setCount }) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector((state) => state?.Table?.data['shipment']);

  const [{ status, schedule, etd, atd, eta, ata }, setState] = React.useState(initialState);

  const $onLoad = async () => {
    handleFilter();

    let date = [];
    let etd = [];
    let atd = [];
    let eta = [];
    let ata = [];

    if (table?.State?.filter?.start_at && table?.State?.filter?.end_at) {
      date.push(new Date(table?.State?.filter?.start_at));
      date.push(new Date(table?.State?.filter?.end_at));
    }

    if (table?.State?.filter?.etd_start_at && table?.State?.filter?.etd_end_at) {
      etd.push(new Date(table?.State?.filter?.etd_start_at));
      etd.push(new Date(table?.State?.filter?.etd_end_at));
    }

    if (table?.State?.filter?.atd_start_at && table?.State?.filter?.atd_end_at) {
      atd.push(new Date(table?.State?.filter?.atd_start_at));
      atd.push(new Date(table?.State?.filter?.atd_end_at));
    }

    if (table?.State?.filter?.eta_start_at && table?.State?.filter?.eta_end_at) {
      eta.push(new Date(table?.State?.filter?.eta_start_at));
      eta.push(new Date(table?.State?.filter?.eta_end_at));
    }

    if (table?.State?.filter?.ata_start_at && table?.State?.filter?.ata_end_at) {
      ata.push(new Date(table?.State?.filter?.ata_start_at));
      ata.push(new Date(table?.State?.filter?.ata_end_at));
    }


    setState((prevState) => ({
      ...prevState,
      schedule: date,
      etd: etd,
      atd: atd,
      eta: eta,
      ata: ata,
      status:
        table?.State?.filter?.document_status === 'new'
          ? statusOptions[0]
          : table?.State?.filter?.document_status === 'manifested'
          ? statusOptions[1]
          : table?.State?.filter?.document_status === 'on_loading'
          ? statusOptions[2]
          : table?.State?.filter?.document_status === 'on_transit'
          ? statusOptions[3]
          : table?.State?.filter?.document_status === 'on_delivery'
          ? statusOptions[4]
          : table?.State?.filter?.document_status === 'console'
          ? statusOptions[5]
          : table?.State?.filter?.document_status === 'delivered'
          ? statusOptions[6]
          : table?.State?.filter?.document_status === 'disputed'
          ? statusOptions[7]
          : table?.State?.filter?.document_status === 'void'
          ? statusOptions[8]
          : null,
    }));
  };

  const handleFilter = async (e) => {
    if (table?.State?.filter) {
      let x = [];
      Object.keys(table?.State?.filter).forEach((k) => {
        if (
          table?.State?.filter[k] &&
          k !== 'recipient_village_id_postalcode' &&
          k !== 'start_at' &&
          k !== 'etd_start_at' &&
          k !== 'atd_start_at' &&
          k !== 'eta_start_at' &&
          k !== 'ata_start_at' &&
          k !== 'load_shipment'
        ) {
          x.push(k);
        }
      });
      setCount(x);
    }
  };

  const handleStatus = (e) => {
    setState((prevState) => ({
      ...prevState,
      status: e,
    }));
    Dispatch(
      Table?.$filter('shipment', 'document_status', e?.value, StateTable)
    );
    handleFilter();
  };

  const handleSchedule = (e) => {
    setState((prevState) => ({
      ...prevState,
      schedule: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));


    console.log(fm, "hihi")

    Dispatch(
      Table?.$filter('shipment', 'date_range', fm, StateTable),
    );

    handleFilter();
  };

  const handleEtd = (e) => {
    setState((prevState) => ({
      ...prevState,
      etd: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));

    Dispatch(
      Table?.$filter('shipment', 'etd_range', fm, StateTable),
    );

    handleFilter();
  };

  const handleAtd = (e) => {
    setState((prevState) => ({
      ...prevState,
      atd: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));

    Dispatch(
      Table?.$filter('shipment', 'atd_range', fm, StateTable),
    );

    handleFilter();
  };


  const handleEta = (e) => {
    setState((prevState) => ({
      ...prevState,
      eta: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));

    Dispatch(
      Table?.$filter('shipment', 'eta_range', fm, StateTable),
    );

    handleFilter();
  };

  const handleAta = (e) => {
    setState((prevState) => ({
      ...prevState,
      ata: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));

    Dispatch(
      Table?.$filter('shipment', 'ata_range', fm, StateTable),
    );

    handleFilter();
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  return (
    <>
      <div
        className={cn(
          show
            ? 'col-md-3 d-none d-md-block py-3 px-3 m-0 border-start'
            : 'd-none'
        )}
      >
        <div className="mb-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className="page-pretitle fs-7 text-secondary">Filter</div>
            <div className="mt-n1">
              <button className="btn btn-action-close " onClick={onClose}>
                <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Status Pengiriman
          </div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            options={statusOptions}
            value={status}
            onChange={handleStatus}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Jadwal Rencana Pikcup
          </div>
          <DateRangePicker
            value={etd}
            placeholder=""
            onChange={handleEtd}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Tanggal Pickup
          </div>
          <DateRangePicker
            value={atd}
            placeholder=""
            onChange={handleAtd}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Jadwal Rencana Pengiriman
          </div>
          <DateRangePicker
            value={eta}
            placeholder=""
            onChange={handleEta}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Tanggal Terkirim
          </div>
          <DateRangePicker
            value={ata}
            placeholder=""
            onChange={handleAta}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Jadwal Dibuat
          </div>
          <DateRangePicker
            value={schedule}
            placeholder=""
            onChange={handleSchedule}
          />
        </div>
      </div>

      <>
        <div
          className={cn('filter-overlay', show ? 'd-block d-md-none' : '')}
          onClick={onClose}
        />
        <div
          className={cn(
            'filter-modal d-block d-md-none py-3',
            show ? 'filter-show' : ''
          )}
        >
          <div className="mb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-pretitle fs-7 text-secondary">Filter</div>
              <div className="mt-n1">
                <button className="btn btn-action-close " onClick={onClose}>
                  <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
                </button>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Status Pengiriman
            </div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              options={statusOptions}
              value={status}
              onChange={handleStatus}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Jadwal Rencana Pikcup
            </div>
            <DateRangePicker
              value={etd}
              placeholder=""
              onChange={handleEtd}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Tanggal Pikcup
            </div>
            <DateRangePicker
              value={atd}
              placeholder=""
              onChange={handleAtd}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Jadwal Rencana Pengiriman
            </div>
            <DateRangePicker
              value={eta}
              placeholder=""
              onChange={handleEta}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Tanggal Terkirim
            </div>
            <DateRangePicker
              value={ata}
              placeholder=""
              onChange={handleAta}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Jadwal Dibuat
            </div>
            <DateRangePicker
              value={schedule}
              placeholder=""
              onChange={handleSchedule}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Filter;
