import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import _ from 'underscore';
import MenuRoute from './menu.route';

const MenuMaster = () => {
  const History = useHistory();
  const location = useLocation();
  const session = useSelector((state) => state?.Auth?.session);
  const [hideChild, setHideChild] = React.useState(null);

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const $checkChildPrivillage = async () => {
    MenuRoute.forEach((menu) => {
      if (session?.privileges) {
        if (menu?.isActive === 'master') {
          let notprivllage = menu?.child?.length;
          menu?.child.forEach((c) => {
            let x = _.findWhere(session?.privileges, {
              service: c?.service,
            });

            if (!x) {
              notprivllage -= 1;
            }
          });

          if (notprivllage === 0) {
            setHideChild(true);
          }
        }
      }
    });
  };

  React.useEffect(() => {
    $checkChildPrivillage();
  }, []);

  return (
    <div className="nav-item d-none d-md-block">
      <ul className="navbar-nav">
        {MenuRoute.map((menu, index) => {
          let link = null;

          if (session?.privileges) {
            link = menu.isMaster ? (
              <li key={index} className="nav-item">
                {!menu?.isDropdown ? (
                  <NavLink
                    to={menu.path}
                    className="nav-link"
                    activeClassName="active"
                    isActive={(match, location) => {
                      const splitLocation = location.pathname.split('/');

                      if (splitLocation[1] === menu.isActive) {
                        return true;
                      }

                      if (
                        menu.isActive === 'user_management' &&
                        splitLocation[2] === 'management'
                      ) {
                        return true;
                      }
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        className="nav-link-icon d-md-none d-lg-inline-block m-0"
                        style={{
                          width: 'auto',
                          height: 'auto',
                        }}
                      >
                        {menu.icon}
                      </span>
                    </div>
                  </NavLink>
                ) : !hideChild ? (
                  <Nav>
                    <NavDropdown
                      active={
                        splitLocation[1] === menu?.isActive ||
                        (menu?.isActive === 'master' &&
                          splitLocation[1] === 'user')
                      }
                      menuVariant="dark"
                      className="no-carret"
                      title={
                        <div className="d-flex justify-content-center align-items-center m-0">
                          <span
                            className="nav-link-icon d-inline-block m-0"
                            style={{
                              width: 'auto',
                              height: 'auto',
                            }}
                          >
                            {menu.icon}
                          </span>
                        </div>
                      }
                    >
                      {menu?.child.map((c, index) => {
                        let menu = null;
                        let x = _.findWhere(session?.privileges, {
                          service: c?.service,
                        });

                        if (x) {
                          if (c?.action?.includes(x?.action)) {
                            menu = (
                              <NavDropdown.Item
                                key={index}
                                onClick={() => History.push(c?.path)}
                                active={
                                  splitLocation[1] === c?.isActive ||
                                  (splitLocation[1] === 'master' &&
                                    splitLocation[2] === c?.isActive) ||
                                  (splitLocation[1] === 'user' &&
                                    splitLocation[2] === 'management' &&
                                    c?.isActive === 'user_management')
                                }
                              >
                                {c?.title}
                              </NavDropdown.Item>
                            );
                          }
                        }
                        return menu;
                      })}
                    </NavDropdown>
                  </Nav>
                ) : null}
              </li>
            ) : null;
          } else {
            link = menu.isMaster ? (
              <li key={index} className="nav-item">
                {!menu?.isDropdown ? (
                  <NavLink
                    to={menu.path}
                    className="nav-link"
                    activeClassName="active"
                    isActive={(match, location) => {
                      const splitLocation = location.pathname.split('/');

                      if (splitLocation[1] === menu.isActive) {
                        return true;
                      }

                      if (
                        menu.isActive === 'user_management' &&
                        splitLocation[2] === 'management'
                      ) {
                        return true;
                      }
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span
                        className="nav-link-icon d-md-none d-lg-inline-block m-0"
                        style={{
                          width: 'auto',
                          height: 'auto',
                        }}
                      >
                        {menu.icon}
                      </span>
                    </div>
                  </NavLink>
                ) : (
                  <Nav>
                    <NavDropdown
                      active={
                        splitLocation[1] === menu?.isActive ||
                        (menu?.isActive === 'master' &&
                          splitLocation[1] === 'user')
                      }
                      menuVariant="dark"
                      className="no-carret"
                      title={
                        <div className="d-flex justify-content-center align-items-center m-0">
                          <span
                            className="nav-link-icon d-inline-block m-0"
                            style={{
                              width: 'auto',
                              height: 'auto',
                            }}
                          >
                            {menu.icon}
                          </span>
                        </div>
                      }
                    >
                      {menu?.child.map((c, index) => {
                        return (
                          <NavDropdown.Item
                            key={index}
                            onClick={() => History.push(c?.path)}
                            active={
                              splitLocation[1] === c?.isActive ||
                              (splitLocation[1] === 'master' &&
                                splitLocation[2] === c?.isActive) ||
                              (splitLocation[1] === 'user' &&
                                splitLocation[2] === 'management' &&
                                c?.isActive === 'user_management')
                            }
                          >
                            {c?.title}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  </Nav>
                )}
              </li>
            ) : null;
          }

          return link;
        })}
      </ul>
    </div>
  );
};

export default MenuMaster;
