import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import emailMask from 'text-mask-addons/dist/emailMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import cn from 'classnames';

const FormInputMasked = ({
  label,
  placeholder,
  required,
  type,
  hint,
  horizontal,
  value,
  onChange,
  error,
  defaultValue,
  disabled,
  maxLength = null,
  readOnly,
  mb0,
  name,
  pattern,
  isPPn,
  className,
  showErrorMessage = true,
}) => {
  const getMask = () => {
    let mask = type;

    switch (type) {
      case 'email':
        // mask = emailMask;
        mask = (s) => Array.from(s).map(() => /(.*?)/);
        break;
      case 'number':
        mask = createNumberMask({
          prefix: '',
          suffix: '',
          thousandsSeparatorSymbol: ',',
          decimalSymbol: '.',
          allowDecimal: true,
          decimalLimit: 2,
        });
        break;
      case 'phone':
        mask = [
          /[0-9]/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
        break;
      case 'date':
        mask = createAutoCorrectedDatePipe('dd/mm/yyyy');
        break;
      case 'text':
        mask = (s) => Array.from(s).map(() => /(.*?)/);
        break;
      default:
        break;
    }

    return mask;
  };

  const handleOnChange = (input) => {
    if (type === 'number') {
      return;
    }

    return onChange(input);
  };

  const handleOnBlur = (input) => {
    if (type === 'number') {
      if (isPPn) {
        const ppnNumb = parseInt(input.target.value);
        if (ppnNumb > 11 || isNaN(ppnNumb)) {
          input.target.value = '11';
          input.target.valueNumber = '11';
        }
      }

      let v = 0;
      if (input.target.value !== '') {
        v = parseFloat(input.target.value.replace(/[^\d.]/g, ''));
      }

      input.target.valueNumber = v;

      return onChange(input);
    }
  };

  const handleOnFocus = (input) => {
    input.target.value.toString();
  };

  return (
    <div className={horizontal ? 'row mb-3' : mb0 ? 'mb-0' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label fs-8 required'
              : horizontal
              ? 'form-label fs-8 col-3 col-form-label text-ellipsis'
              : 'form-label fs-8'
          }
        >
          {label}
        </label>
      ) : null}

      <div className={horizontal ? 'col' : null}>
        <MaskedInput
          pattern={pattern}
          autoComplete="off"
          name={name}
          readOnly={readOnly}
          mask={getMask()}
          className={cn('form-control', error ? 'is-invalid' : '', className)}
          placeholder={placeholder}
          guide={false}
          value={value == null ? '' : value}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          defaultValue={defaultValue}
          disabled={disabled}
          maxLength={maxLength}
          type="type"
        />
        {hint ? <small className="form-hint">{hint}</small> : null}
        {error && showErrorMessage ? (
          <div className="invalid-feedback">{error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default FormInputMasked;
