/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useModal } from 'ui/hooks';
import _ from 'underscore';
import MenuRoute from './menu.route';

const MenuSm = () => {
  const session = useSelector((state) => state?.Auth?.session);
  const History = useHistory();
  const Modal = useModal();

  return (
    <div className="d-block d-md-none">
      <FaBars color="white" size={25} onClick={() => Modal.show()} />

      <Modal.Render fullscreen title="Menu" className="text-center bg-dark">
        <ul className="navbar-nav pt-lg-3">
          {MenuRoute.map((menu, index) => {
            let link = null;
            if (session?.privileges) {
              let x = _.findWhere(session?.privileges, {
                service: menu?.service,
              });

              if (x) {
                if (menu?.service?.includes(x?.service)) {
                  link = !menu?.isDropdown ? (
                    <li key={index} className="nav-item border-b-1">
                      <a
                        className="nav-link"
                        onClick={() => {
                          Modal.hide();
                          History.push(menu?.path);
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu?.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={index} className="nav-item border-b-1">
                      <Accordion>
                        <Accordion.Item eventKey={menu?.title}>
                          <Accordion.Header className="b ">
                            <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu?.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body
                            className="py-0"
                            style={{
                              paddingLeft: '1.5rem',
                              paddingRight: 0,
                            }}
                          >
                            <ul
                              className="nav-vertical nav border-start"
                              style={{ paddingLeft: '1rem' }}
                            >
                              {menu?.child.map((c, index) => {
                                let child = [];
                                let x = _.findWhere(session?.privileges, {
                                  service: c?.service,
                                });

                                if (x) {
                                  if (c?.service.includes(x?.service)) {
                                    child = (
                                      <li
                                        className="nav-item border-b-1"
                                        key={index}
                                      >
                                        <a
                                          onClick={() => {
                                            Modal.hide();
                                            History.push(c?.path);
                                          }}
                                          className="nav-link"
                                        >
                                          {c?.title}
                                        </a>
                                      </li>
                                    );
                                  }
                                } else {
                                  child = (
                                    <li
                                      className="nav-item border-b-1"
                                      key={index}
                                    >
                                      <a
                                        onClick={() => {
                                          Modal.hide();
                                          History.push(c?.path);
                                        }}
                                        className="nav-link"
                                      >
                                        {c?.title}
                                      </a>
                                    </li>
                                  );
                                }
                                return child;
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </li>
                  );
                }
              } else {
                link = menu?.isMaster ? (
                  <li key={index} className="nav-item border-b-1">
                    <Accordion>
                      <Accordion.Item eventKey={menu?.title}>
                        <Accordion.Header className="b ">
                          <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                              {menu?.icon}
                            </span>
                            <span className="nav-link-title">
                              {menu?.title}
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body
                          className="py-0"
                          style={{
                            paddingLeft: '1.5rem',
                            paddingRight: 0,
                          }}
                        >
                          <ul
                            className="nav-vertical nav border-start"
                            style={{ paddingLeft: '1rem' }}
                          >
                            {menu?.child?.map((c, index) => {
                              let child = null;
                              let x = _.findWhere(session?.privileges, {
                                service: c?.service,
                              });

                              if (x) {
                                if (c?.service.includes(x?.service)) {
                                  child = (
                                    <li
                                      className="nav-item border-b-1"
                                      key={index}
                                    >
                                      <a
                                        onClick={() => {
                                          Modal.hide();
                                          History.push(c?.path);
                                        }}
                                        className="nav-link"
                                      >
                                        {c?.title}
                                      </a>
                                    </li>
                                  );
                                }
                              }
                              return child;
                            })}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                ) : null;
              }
            } else {
              link = !menu?.isDropdown ? (
                <li key={index} className="nav-item border-b-1">
                  <a
                    className="nav-link"
                    onClick={() => {
                      Modal.hide();
                      History.push(menu?.path);
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        {menu?.icon}
                      </span>
                      <span className="nav-link-title">{menu?.title}</span>
                    </div>
                  </a>
                </li>
              ) : (
                <li key={index} className="nav-item border-b-1">
                  <Accordion>
                    <Accordion.Item eventKey={menu?.title}>
                      <Accordion.Header className="b ">
                        <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu?.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        className="py-0"
                        style={{
                          paddingLeft: '1.5rem',
                          paddingRight: 0,
                        }}
                      >
                        <ul
                          className="nav-vertical nav border-start"
                          style={{ paddingLeft: '1rem' }}
                        >
                          {menu?.child.map((c, index) => {
                            return (
                              <li className="nav-item border-b-1" key={index}>
                                <a
                                  onClick={() => {
                                    Modal.hide();
                                    History.push(c?.path);
                                  }}
                                  className="nav-link"
                                >
                                  {c?.title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
              );
            }

            return link;
          })}
        </ul>
      </Modal.Render>
    </div>
  );
};

export default MenuSm;
