import React from 'react';
import cn from 'classnames';
import * as Fi from 'react-icons/fi';
import { Select, DateRangePicker } from 'ui/components';
import { formatDate } from 'utilities/common';

const statusOptions = [
  { value: 'new', label: 'Baru' },
  { value: 'process', label: 'Dalam Proses' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'voided', label: 'Void' },
];

const initialState = {
  schedule: null,
  status: null,
};

const Filter = ({ show, onClose, table, setCount }) => {
  const [{ schedule, status }, setState] = React.useState(initialState);

  const $filterStatus = (e) => {
    setState((prevState) => ({
      ...prevState,
      status: e,
    }));

    table?.filter('document_status', e?.value);
    handleFilter();
  };

  const $filterService = (e) => {
    table?.filter('service_type_id', e?.id);
    handleFilter();
  }

  const $filterDate = (e) => {
    setState((prevState) => ({
      ...prevState,
      schedule: e,
    }));

    let fm = [];
    e?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));

    table?.filter('start_at', fm?.length > 0 ? fm[0] : null);
    table?.filter('end_at', fm?.length > 0 ? fm[1] : null);
    handleFilter();
  }

  const handleFilter = async (e) => {
    if (table?.State?.filter) {
      let x = [];
      Object.keys(table?.State?.filter).forEach((k) => {
        if (
          table?.State?.filter[k] &&
          k !== 'destination_village_postalcode' &&
          k !== 'type' &&
          k !== 'start_at' &&
          k !== 'load_shipment'
        ) {
          x.push(k);
        }
      });
      setCount(x);
    }
  };

  React.useEffect(() => {
    handleFilter();

    let date = [];

    if (table?.State?.filter?.start_at && table?.State?.filter?.end_at) {
      date.push(new Date(table?.State?.filter?.start_at));
      date.push(new Date(table?.State?.filter?.end_at));
    }

    setState((prevState) => ({
      ...prevState,
      schedule: date,
      loading: false,
      status:
        table?.State?.filter?.document_status === 'new'
          ? statusOptions[0]
          : table?.State?.filter?.document_status === 'process'
            ? statusOptions[1]
            : table?.State?.filter?.document_status === 'delivered'
              ? statusOptions[2]
              : table?.State?.filter?.document_status === 'voided'
                ? statusOptions[3]
                : null
    }));
  }, []);

  const FilterForm = () => {
    if (!show) return null;

    return (
      <>
        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Status Pengiriman
          </div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            value={status}
            initialValue={status}
            options={statusOptions}
            onChange={$filterStatus}
          />
        </div>
        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Layanan
          </div>
          <Select.Remote
            required
            placeholder=""
            initialValue={table?.State?.filter?.service_type_id}
            keys={table?.State?.filter?.service_type_id}
            params={{
              order_by: 'service_id',
            }}
            url="/tms/service/type"
            row={(d) => {
              return {
                value: d.id,
                label: `${d?.service?.name} - ${d.name}`,
              };
            }}
            onSelected={$filterService}
          />
        </div>
        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Jadwal Pengiriman
          </div>
          <DateRangePicker
            value={schedule}
            placeholder=""
            onChange={$filterDate}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={cn(
          show
            ? 'col-md-3 d-none d-md-block py-3 px-3 m-0 border-start'
            : 'd-none'
        )}
      >
        <div className="mb-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className="page-pretitle fs-7 text-secondary">Filter</div>
            <div className="mt-n1">
              <button className="btn btn-action-close " onClick={onClose}>
                <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
              </button>
            </div>
          </div>
        </div>
        <FilterForm />
      </div>

      <>
        <div
          className={cn('filter-overlay', show ? 'd-block d-md-none' : '')}
          onClick={onClose}
        />
        <div
          className={cn(
            'filter-modal d-block d-md-none py-3',
            show ? 'filter-show' : ''
          )}
        >
          <div className="mb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-pretitle fs-7 text-secondary">Filter</div>
              <div className="mt-n1">
                <button className="btn btn-action-close " onClick={onClose}>
                  <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
                </button>
              </div>
            </div>
          </div>

          <FilterForm />
        </div>
      </>
    </>
  );
};

export default Filter;
