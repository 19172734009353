import React from 'react';
import { Alert } from 'react-bootstrap';

const Alerts = ({ show, variant, headerContent, children, className }) => {
  return (
    <Alert show={show} variant={variant} className={className}>
      <Alert.Heading>{headerContent}</Alert.Heading>
      {children}
    </Alert>
  );
};

export default Alerts;
