import config from 'services/table/const';
import Action from './action';
import { formatDate, currencyFormat, isValidDate, isLate } from 'utilities/common';
import * as Icon from 'react-icons/bs';
import * as Pin from 'react-icons/ri';
import LocationTooltips from 'ui/components/data/location';
import cn from 'classnames';

let table = { ...config };
table.url = '/tms/delivery';
table.filter = { load_shipment: true };
table.columns = {
  icon: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          {row?.shipment?.type === 'ltl' ? (
            <Icon.BsWindowDock color="black" size={20} />
          ) : (
            <Icon.BsTruck color="black" size={25} />
          )}
          <small className="info text-uppercase">{row?.shipment?.type}</small>
        </div>
      );
    },
  },
  order_code: {
    title: 'Kode',
    sortable: true,
    alias: 'id',
    width: 80,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">
            {row?.shipment?.code}
          </div>
          {row?.order?.code && (
            <small className="info">ORD: {row?.order?.code}</small>
          )}
          {row?.order?.ref_code && (
            <small className="info">REF: {row?.order?.ref_code}</small>
          )}
        </div>
      );
    },
  },
  shipper: {
    title: 'Pengirim',
    sortable: true,
    alias: 'shipment.shipper.name',
    width: 80,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.shipment?.shipper?.name}
          </div>
          {row?.order?.customer?.name && (
            <small className="info text-uppercase">
              CUST: {row?.order?.customer?.name}
            </small>
          )}
        </div>
      );
    },
  },
  iconss: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <Pin.RiMapPin2Fill color="gray" size={25} />
        </div>
      );
    },
  },
  origin: {
    title: 'Origin',
    sortable: false,
    alias: 'shipment.origin.name',
    width: 120,
    component: (row) => {
      return (
        <LocationTooltips data={row?.shipment?.origin}>
          <div className='row'>
            <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
              {row?.shipment?.origin?.name}
            </div>
            {isValidDate(row?.shipment?.etd_at) && (
              <small className="info text-uppercase">
                ETD: {formatDate(row?.shipment?.etd_at)}
              </small>
            )}
            {isValidDate(row?.shipment?.atd_at) && (
              <small className={cn('info text-uppercase', isLate(row?.shipment?.etd_at, row?.shipment?.atd_at) ? 'text-danger' : 'text-success')}>
                ATD: {formatDate(row?.shipment?.atd_at)}
              </small>
            )}
          </div>
        </LocationTooltips>
      );
    },
  },
  icons: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <Pin.RiMapPin2Fill color="gray" size={25} />
        </div>
      );
    },
  },
  destination: {
    title: 'Destination',
    sortable: false,
    alias: 'shipment.destination.name',
    width: 120,
    component: (row) => {
      return (
        <LocationTooltips data={row?.shipment?.destination}>
          <div className='row'>
            <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
              {row?.shipment?.destination?.name}
            </div>
            <small className="info text-uppercase">
              ETA: {formatDate(row?.shipment?.eta_at)}
            </small>
            {isValidDate(row?.shipment?.ata_at) && (
              <small className={cn('info text-uppercase', isLate(row?.shipment?.eta_at, row?.shipment?.ata_at) ? 'text-danger' : 'text-success')}>
                ATA: {formatDate(row?.shipment?.ata_at)}
              </small>
            )}
          </div>
        </LocationTooltips>
      );
    },
  },

  weight: {
    title: 'Berat',
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {currencyFormat(row?.shipment?.total_weight)} KG
          </div>
          <small className="info text-uppercase">
            {row?.shipment?.total_quantity} Koli
          </small>
        </div>
      );
    },
  },

  total_charges: {
    title: 'Total Harga',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
  },

  document_status: {
    title: 'Status',
    sortable: false,
    class: 'text-center',
    classHeader: 'text-center',
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <span className={`badge status-${row?.document_status}-lt ms-auto `}>
            {row?.document_status
              ?.replace('_', ' ')
              ?.replace('console', 'transit')}
          </span>
          {row?.current_hub?.name && (
            <small className="info text-uppercase">
              CL: {row?.current_hub?.name}
            </small>
          )}
        </div>
      );
    },
  },

  action: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
