import * as Action from './action';

const defineInitialState = () => ({
  isAuthenticated: false,
  client: null,
  customer: null,
  session: null,
  token: '',
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$reset.ACTION:
      return defineInitialState();
    case Action.$signin.REQUEST:
      return {
        ...state,
        customer: null,
        client: null,
        session: null,
        token: '',
      };
    case Action.$signin.SUCCESS:
      return {
        isAuthenticated: true,
        client: action.response.client,
        customer: action.response.customer,
        session: { user: action.response.user },
        token: action.response.token,
      };
    case Action.$fetchProfile.SUCCESS:
      return {
        ...state,
        client: action.response.client,
        customer: action.response.customer,
        session: { user: action.response.user },
      };
    case Action.$logout.ACTION:
      return {
        isAuthenticated: false,
        client: null,
        customer: null,
        session: null,
        token: '',
      };
    default:
      return state;
  }
}

export function persister({ isAuthenticated, client, customer, session, token }) {
  return {
    isAuthenticated,
    client,
    customer,
    session,
    token,
  };
}
