import React from 'react';
import { connect } from 'react-redux';
import SigninScreen from 'ui/screens/unauthorized/signin';

import Screen from './ui/screens/authorized/router';
import SplashScreen from './ui/screens/splash';
import 'react-medium-image-zoom/dist/styles.css';

const App = ({ appReady, isAuthenticated }) => {
  const Ready = () => {
    if (!appReady) return <SplashScreen />;

    return isAuthenticated ? <Screen /> : <SigninScreen />;
  };

  return <Ready />;
};

const mapStateToProps = (state) => {
  return {
    appReady: state.App.isReady,
    isAuthenticated: state.Auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
