import React from 'react';
import { Skeletons } from 'ui/components/skeleton';
import LocationTooltips from 'ui/components/data/location';
import { formatDate } from 'utilities/common';
import * as Icon from 'react-icons/bs';

const CardShipment = ({ isLoading, data }) => {
    return isLoading ? (
        <div className='card p-3 rounded-2 bg-white'>
            <Skeletons height="400px" />
        </div>
    ) : (
        <div className='card p-3 rounded-2 bg-white'>
            <h3 className="card-title dx-card-title border-bottom pb-2">
                Belum Diproses
            </h3>
            <div className="feature-listing">
                <div className="table-responsive table-overflow">
                    <table
                        className="table table-hover table-vcenter card-table datatable"
                        width="100%"
                    >
                        <tbody>
                            {data?.map((row, i) => (
                                <tr key={i}>
                                    <td>
                                        <div className="table-col text-center">
                                            {row?.shipment?.type === 'ltl' ? (
                                                <Icon.BsWindowDock color="black" size={20} />
                                            ) : (
                                                <Icon.BsTruck color="black" size={25} />
                                            )}
                                            <small className="info text-uppercase">{row?.shipment?.type}</small>
                                        </div>
                                    </td>
                                    <td width={250}>
                                        <div className="table-col">
                                            <div className="text-ellipsis fs-8 fw-semibold">
                                                {row?.shipment?.code}
                                            </div>
                                            <small className="info text-uppercase">CL: {row?.current_hub?.name}</small>
                                            <small className="info text-uppercase">
                                                C: {formatDate(row?.order?.created_at)}
                                            </small>
                                        </div>
                                    </td>
                                    <td width={300}>
                                        <LocationTooltips data={row?.shipment?.destination}>
                                            <div className='row'>
                                                <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                                                    {row?.shipment?.destination?.name}
                                                </div>
                                                <small className="info text-uppercase">CUST: {row?.order?.customer?.name}</small>
                                                <small className="info text-uppercase">
                                                    ETA: {formatDate(row?.shipment?.eta_at)}
                                                </small>
                                            </div>
                                        </LocationTooltips>
                                    </td>
                                    <td width={100}>
                                        <span className={`badge status-${row?.document_status}-lt ms-auto `}>
                                            {row?.document_status
                                                ?.replace('_', ' ')
                                                ?.replace('console', 'transit')}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CardShipment;