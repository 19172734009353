import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Auth } from 'services/action';

const MenuProfile = () => {
  const session = useSelector((state) => state?.Auth?.session);

  const History = useHistory();
  const Dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <div className="nav-item">
      <Nav>
        <NavDropdown
          menuVariant="dark"
          id="profile"
          title={
            <div className="nav-link d-flex lh-1 text-reset p-0">
              <span className="avatar avatar-sm rounded-circle bg-white-lt ">
                {session?.user?.name[0].toUpperCase()}
              </span>
              <div className="d-none d-lg-block ps-2 ml-n5 table-col">
                <div className='text-uppercase fw-semibold fs-8 text-ellipsis'>{session?.user?.name}</div>
                <small className='mt-1 info text-muted text-uppercase'> {session?.hub?.name} </small>
              </div>
            </div>
          }
          className="no-carret"
        >
          <NavDropdown.Item
            onClick={() => History.push('/profile/setting')}
            active={splitLocation[2] === 'setting'}
          >
            Profile Setting
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => Dispatch(Auth.$logout())}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </div>
  );
};

export default MenuProfile;
