import React from 'react';
import {
  FaCheckCircle,
  FaEnvelope,
  FaLock,
  FaLongArrowAltLeft,
  FaUserAlt,
} from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Card } from 'ui/components';

import { Auth } from 'services/action';

const TMS_URL = `${process.env.REACT_APP_TMS_SYSTEM_URL}`;

const SigninScreen = () => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const [state, setState] = React.useState(0);
  const history = require('history').createBrowserHistory();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [token, setToken] = React.useState();
  const [confirm_password, setCPassword] = React.useState('');

  const [emailSend, setEmailSend] = React.useState(false);
  const [showNotif, setShowNotif] = React.useState(false);

  const $handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: username,
      password: password,
      app: 'customer'
    };

    await Dispatch(Auth.$signin(payload));
  };

  const $handleForget = async (e) => {
    e.preventDefault();

    const payload = {
      link_redirect: TMS_URL,
      email: email,
    };

    const req = await Dispatch(Auth.$forget(payload));

    if (req?.message === 'success') {
      setEmailSend(true);

      setTimeout(function () {
        setEmailSend(false);
        setState(0);
      }, 10000);
    }
  };

  const $handleReset = async (e) => {
    e.preventDefault();

    const payload = {
      password: password,
      confirm_password: confirm_password,
      reset_token: token,
    };

    const req = await Dispatch(Auth.$resetPassword(payload));

    if (req?.message === 'success') {
      history.replace({ ...history?.location, search: '' });
      setState(0);
      setShowNotif(true);
    }
  };

  const $handleClose = () => {
    setEmailSend(false);
  };

  const handleSwitch = async (e) => {
    setState(state + 1);
  };

  const handlePrev = async (e) => {
    setState(0);
  };

  React.useEffect(() => {
    if (history?.location?.search !== '') {
      setState(2);
      setToken(history?.location?.search?.replace('?reset_token=', ''));
    }
  }, []);

  switch (state) {
    case 0:
      return (
        <div className="markdown row p-0">
          <div className="col-lg-3 signin d-none d-lg-block"></div>
          <div
            className="col-lg-9 d-flex flex-column justify-content-center align-items-center mt-md-n5"
            style={{ minHeight: '100vh' }}
          >
            <div className="mb-3 text-center ">
              <div className="page-pretitle">Welcome to envio logistics</div>
              <h2 className="page-title">Log into your account</h2>
            </div>

            {showNotif ? (
              <div className="bg-success-lt text-center px-4 px-md-5 py-2 py-md-3 mb-3 border-success border rounded">
                <div className="mb-3">
                  <FaCheckCircle color="green" size={50} />
                </div>

                <div className="text-secondary fs-6">
                  <div>Password telah berhasil diubah</div>
                </div>
              </div>
            ) : null}

            <Card className="p-2 login">
              <div className="login-form">
                <form onSubmit={$handleSubmit}>
                  <div className="form-group mb-3 ">
                    <Form.InputAddon
                      icon={<FaUserAlt />}
                      value={username}
                      onChange={(e) =>
                        setUsername(e.target.value)
                      }
                      type="text"
                      placeholder="Username"
                      label="Username"
                      error={StateForm?.errors?.username}
                      lg
                    />
                  </div>
                  <div className="form-group mb-3 ">
                    <Form.InputAddon
                      icon={<FaLock />}
                      value={password}
                      onChange={(e) =>
                        setPassword(e.target.value)
                      }
                      label="Password"
                      type="password"
                      placeholder="Password"
                      error={StateForm?.errors?.password}
                      lg
                    />
                  </div>
                  <div className="form-footer">
                    <button
                      type="submit"
                      disabled={StateForm?.loading}
                      className="btn btn-primary w-100 py-3"
                    >
                      {!StateForm?.loading ? (
                        'Submit'
                      ) : (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </button>
                  </div>
                  <div
                    className="page-pretitle text-primary mt-2 text-center cursor-pointer"
                    onClick={handleSwitch}
                  >
                    Lupa Password ?
                  </div>
                </form>
              </div>
            </Card>
            <small className="text-muted mt-3">&copy; 2021 envio.co.id</small>
          </div>
        </div>
      );

    case 1:
      return (
        <div className="markdown row p-0">
          <div className="col-lg-3 signin d-none d-lg-block"></div>
          <div
            className="col-lg-9 d-flex flex-column justify-content-center align-items-center mt-n5"
            style={{ minHeight: '100vh' }}
          >
            <div className="mb-3 text-center ">
              <div className="page-pretitle">Welcome to envio logistics</div>
              <h2 className="page-title d-block">Lupa password</h2>
            </div>
            <Card className="p-2 login">
              <div
                className="page-pretitle text-primary align-self-start mb-3 cursor-pointer"
                onClick={handlePrev}
              >
                <FaLongArrowAltLeft /> Kembali ke Login
              </div>
              <div className="login-form">
                <form onSubmit={$handleForget}>
                  <div className="form-group mb-3 ">
                    <Form.InputAddon
                      icon={<FaEnvelope />}
                      value={email}
                      onChange={(e) =>
                        setEmail(e.target.value)
                      }
                      type="text"
                      placeholder="Email"
                      label="Email"
                      error={StateForm?.errors?.email}
                      lg
                      hint="pastikan email anda aktif"
                    />
                  </div>

                  <div className="form-footer">
                    <button
                      type="submit"
                      disabled={StateForm?.loading}
                      className="btn btn-primary w-100 py-3"
                    >
                      {!StateForm?.loading ? (
                        'Submit'
                      ) : (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </Card>
            <small className="text-muted mt-3">&copy; 2021 envio.co.id</small>

            <Modal
              show={emailSend}
              onHide={$handleClose}
              backdrop="static"
              centered
              className="text-center"
              size="small"
            >
              <Modal.Body>
                <FaCheckCircle className="mb-2 text-success" size={90} />
                <div className="text-semibold">
                  Email telah berhasil terkirim, silakan mengecek inbox email
                  anda secara berkala.
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="markdown row p-0">
          <div className="col-lg-3 signin d-none d-lg-block"></div>
          <div
            className="col-lg-9 d-flex flex-column justify-content-center align-items-center mt-n5"
            style={{ minHeight: '100vh' }}
          >
            <div className="mb-3 text-center ">
              <div className="page-pretitle">Welcome to envio logistics</div>
              <h2 className="page-title d-block">Reset password</h2>
            </div>
            <Card className="p-2 login">
              <div
                className="page-pretitle text-primary align-self-start mb-3 cursor-pointer"
                onClick={handlePrev}
              >
                <FaLongArrowAltLeft /> Kembali ke Login
              </div>
              <div className="login-form">
                <form onSubmit={$handleReset}>
                  <div className="form-group mb-3 ">
                    <Form.InputAddon
                      icon={<FaLock />}
                      value={password}
                      onChange={(e) =>
                        setPassword(e.target.value)
                      }
                      label="Password"
                      type="password"
                      placeholder="Password"
                      error={StateForm?.errors?.password}
                      lg
                    />
                  </div>
                  <div className="form-group mb-3 ">
                    <Form.InputAddon
                      icon={<FaLock />}
                      value={confirm_password}
                      onChange={(e) =>
                        setCPassword(e.target.value)
                      }
                      label="Konfirmasi Password"
                      type="password"
                      placeholder="Konfirmasi Password"
                      error={StateForm?.errors?.confirm_password}
                      lg
                    />
                  </div>

                  {StateForm?.errors?.reset_token ? (
                    <div className="invalid-feedback d-block">
                      {StateForm?.errors?.reset_token}
                    </div>
                  ) : null}

                  <div className="form-footer">
                    <button
                      type="submit"
                      disabled={StateForm?.loading}
                      className="btn btn-primary w-100 py-3"
                    >
                      {!StateForm?.loading ? (
                        'Submit'
                      ) : (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </Card>
            <small className="text-muted mt-3">&copy; 2021 envio.co.id</small>
          </div>
        </div>
      );
    default:
      break;
  }
};

export default SigninScreen;
