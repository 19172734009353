import React, { Suspense } from 'react';
import { Skeletons } from 'ui/components/skeleton';

const Maps = React.lazy(() => import('ui/components/mapbox/map.dashboard'));

const CardMap = ({ isLoading, filter }) => {

    return isLoading ? (
        <Skeletons height="450px" />
    ) : (
        <div className='card p-3 rounded-2 bg-white'>
            <Suspense fallback={<Skeletons height="450px" />}>
                <Maps filter={filter} />
            </Suspense>
        </div>
    )
}

export default CardMap;