import PrintOrder from './order';

const routes = [
  {
    path: '/print/order/:id',
    component: PrintOrder,
    action: ['read_only', 'manage'],
    service: ['nvo.tms.delivery'],
  },
];

export default routes;
