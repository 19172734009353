import React, { Suspense } from 'react';
import { Skeletons } from 'ui/components/skeleton';

const Maps = React.lazy(() =>
  import('ui/components/mapbox/map.order.trucking')
);

export const MapTrucking = ({ data, onSubmit }) => {
  const [loading, setLoading] = React.useState(true);

  const dummy = Array.from({ length: 4 }, (v, i) => i);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <div className="hr-text hr-text-left text-primary mb-3 mt-md-3">Rute</div>
      {loading || !data ? (
        <div className="row">
          <div className="col-md-5">
            {dummy?.map((d, i) => (
              <div className="mb-2" key={i}>
                <Skeletons height="100px" />
              </div>
            ))}
          </div>
          <div className="col-md-7">
            <Skeletons height="500px" />
          </div>
        </div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Maps
            locations={data}
            className="map-container-vertical border"
            onSubmit={onSubmit}
          />
        </Suspense>
      )}
    </>
  );
};
