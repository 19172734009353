import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $processing, $done } from 'services/activity/action';

export const MODULE = 'Manifest';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $getByDelivery = ActionCreator.Async(
  MODULE,
  'GetByDelivery',
  (shipmentid, payload) => {
    return async (dispatch) => {
      dispatch($processing($getByDelivery.OPERATION));

      return await Rest.GET(
        `${API_URL}/tms/manifest/bydelivery/${shipmentid}`,
        payload
      )
        .then((response) => {
          dispatch($getByDelivery.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getByDelivery.OPERATION)));
    };
  }
);

export const $getLegsByDelivery = ActionCreator.Async(
  MODULE,
  'GetLegsByDelivery',
  (shipmentid, payload) => {
    return async (dispatch) => {
      dispatch($processing($getLegsByDelivery.OPERATION));

      return await Rest.GET(
        `${API_URL}/tms/manifest/leg/bydelivery/${shipmentid}`,
        payload
      )
        .then((response) => {
          dispatch($getLegsByDelivery.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getLegsByDelivery.OPERATION)));
    };
  }
);