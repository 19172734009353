import React from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import _ from 'underscore';

import { Select } from 'services/action';

const Remote = ({
  name,
  url,
  initialValue,
  row,
  params,
  onSelected,
  placeholder,
  errors,
  label,
  required,
  keys = null,
  disabled = false,
  isMulti,
  isSearch = false,
  hint,
  horizontal,
  onClear,
  UseOnClear = false,
  research,
  onResearch,
  firstData = false
}) => {
  const Dispatch = useDispatch();
  const [value, setValue] = React.useState(null);

  const $request = async (params) => {
    const resp = await Dispatch(Select.$get(url, params));

    let needValue = false;
    setValue(null);

    if (
      typeof initialValue !== 'undefined' &&
      typeof initialValue !== 'object' &&
      initialValue !== null
    ) {
      needValue = true;
    }

    let result = [];
    const total_result = _.size(resp?.data);

    if (resp?.message === 'success') {
      result = _.map(resp?.data, function (d) {
        const no = { ...row(d), original: d };

        if (needValue) {
          if (d?.id === initialValue) {
            setValue(no);
          } else if (d?.village_id === initialValue) {
            setValue(no);
          } else if (d?.regency_id === initialValue) {
            setValue(no);
          }
        } else {
          if (firstData && total_result === 1) {
            setValue(no)
            handleInputChange(no,  { action: null });
          }
        }

        // else {
        //   if (total_result === 1) {
        //     setValue(no);
        //     handleInputChange(no, { action: null });
        //     if (onResearch) {
        //       onResearch(no);
        //       handleInputChange(no, { action: null });
        //     }
        //   }
        // }

        return no;
      });
    }

    return result;
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue === '' && research) {
      inputValue = research;
    }

    const p = {
      ...params,
      search: inputValue ? inputValue : params?.search ? params?.search : '',
      page: params?.page || 1,
      limit: params?.limit || 100,
    };

    if (!isSearch || p?.search) {
      return $request(p);
    }
  };

  const handleInputChange = (newValue, { action }) => {
    setValue(newValue);

    if (isMulti) {
      onSelected({ ...value, ...(newValue || null) });
    } else {
      onSelected(newValue?.original || null);
    }

    if (UseOnClear) {
      if (action === 'clear') {
        onClear();
      }
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [keys]);

  return (
    <div className={horizontal ? 'row' : ''} key={research}>
      {label ? (
        <label
          className={
            required
              ? 'form-label fs-8 required'
              : horizontal
              ? 'form-label fs-8 col-3 col-form-label'
              : 'form-label fs-8'
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? 'col' : null}>
        <AsyncSelect
          name={name}
          classNamePrefix={errors ? 'is-invalid' : 'select'}
          cacheOptions
          placeholder={placeholder}
          value={value}
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleInputChange}
          isClearable
          isSearchable
          className="bg-white"
          isDisabled={disabled}
          autocomplete="off"
          key={keys}
          style={{ zIndex: 2020 }}
          isMulti={isMulti}
        />
        {hint ? <small className="form-hint pt-1 fs-9">{hint}</small> : null}
        {errors ? (
          <div className="invalid-feedback d-block">{errors}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Remote;
