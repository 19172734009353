import React from 'react';
import * as Fa from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { ListHorizontal } from 'ui/components';
import { SkeletonText } from 'ui/components/skeleton';

import { formatPhone, waFormat } from 'utilities/common';

export const ContactInformation = ({ id, data, title }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const dummy = Array.from({ length: 3 }, (v, i) => i);

  return (
    <section className="" id={id}>
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          {title}
        </div>
      )}

      <div className="row pt-2">
        {StateLoading ? (
          dummy?.map((d, i) => (
            <div className="col-12" key={i}>
              <ListHorizontal
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            </div>
          ))
        ) : (
          <>
            <div className="col-12">
              <ListHorizontal
                title="Nama"
                content={data?.name}
              />
            </div>
            <div className="col-12">
              <ListHorizontal
                title="Kontak"
                content={
                  <div className="row m-0 justify-content-between align-items-center ">
                    <div className="col-9 p-0">
                      <div className="title fw-semibold">
                        {id === 'customer'
                          ? data?.pic_name
                          : data?.contact_person}
                      </div>
                      <div className="page-pretitle ">
                        {waFormat(
                          id === 'customer'
                            ? data?.pic_phone
                            : data?.phone_number
                        )}
                      </div>
                    </div>
                    {formatPhone(
                      id === 'customer' ? data?.pic_phone : data?.phone_number
                    ) ? (
                      <div className="col-3 text-end">
                        <button
                          className="btn btn-success p-2"
                          onClick={() =>
                            window.open(
                              `https://wa.me/${waFormat(
                                id === 'customer'
                                  ? data?.pic_phone
                                  : data?.phone_number
                              )}`,
                              '_blank'
                            )
                          }
                          type="button"
                        >
                          <Fa.FaWhatsapp size="20" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                }
              />
            </div>
            <div className="col-12">
              <ListHorizontal
                title="Alamat"
                content={
                  <div className="">
                    <div className="title fw-semibold text-ellipsis">{data?.address}</div>
                    {id !== 'customer' && (
                      <div className="page-pretitle">
                        {`${data?.region?.village_name}, ${data?.region?.district_name}, ${data?.region?.regency_name}, ${data?.region?.province_name}`}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};
