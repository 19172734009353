import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $processing } from 'services/activity/action';
import { $done } from 'services/activity/action';

export const MODULE = 'Services';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $getByUseServiceType = ActionCreator.Async(
  MODULE,
  'GetByUseServiceType',
  () => {
    return async (dispatch) => {
      dispatch($processing($getByUseServiceType.OPERATION));

      return await Rest.GET(`${API_URL}/tms/service/use`)
        .then((response) => {
          dispatch($getByUseServiceType.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getByUseServiceType.OPERATION)));
    };
  }
);


export const $getServiceType = ActionCreator.Async(
  MODULE,
  'GetServiceType',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($getServiceType.OPERATION));

      return await Rest.GET(`${API_URL}/tms/service/type`, payload)
        .then((response) => {
          dispatch($getServiceType.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getServiceType.OPERATION)));
    };
  }
);

export const $show = ActionCreator.Async(MODULE, 'Show', (id) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/tms/service/${id}`)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});
