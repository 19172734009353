import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'underscore';
import { formatDate } from 'utilities/common';
import { Order } from 'services/action';

import { Page, Button } from 'ui/components';
import { BulkImport, General, ShipmentList, Shipments } from './component/form';
import { UTCFormat } from 'utilities/common';

const initialState = {
  form_general: null,
  isLoading: true,
  shipment_lists: [],
  update: null,
  shipmentClone: null,
};

const CreateScreen = () => {
  const History = useHistory();
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);

  const [{ form_general, shipment_lists, update, shipmentClone }, setState] =
    React.useState(initialState);

  const $handleDelete = async (id) => {
    let newList = [...shipment_lists];
    newList.splice(id, 1);
    setState((prevState) => ({
      ...prevState,
      shipment_lists: newList,
      update: null,
    }));
  };

  const $handleUpdate = async (d, id) => {
    const u = { id: id, data: shipment_lists[id], firstData: id === 0 };
    setState((prevState) => ({
      ...prevState,
      update: u,
    }));
  };

  const $onUpdate = async (d) => {
    let newList = [...shipment_lists];
    newList[update?.id] = d;

    setState((prevState) => ({
      ...prevState,
      shipment_lists: newList,
      update: null,
    }));
  };

  const $onAddPoint = async (type) => {
    let x = Object.assign({}, _.last(shipment_lists));

    x.unit_price = 0;
    x.cod_value = 0;
    x.items = [{}];

    if (type === 'pickup') {
      x.type = 'pickup';
      x.origin = null;
      x.etd_time = '';
      x.etd_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    } else if (type === 'reverse') {
      const o = x.origin;
      const d = x.destination;
      x.type = 'reverse';
      x.origin = d;
      x.destination = o;
      x.etd_time = '';
      x.etd_at = x.eta_at;
      x.ref_code = ''
    } else if (type === 'point') {
      x.type = 'point'
      x.destination = null
      x.origin = null
      x.eta_time = '';
      x.ref_code = '';
      x.eta_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    } else {
      x.type = 'drop_point';
      x.destination = null;
      x.eta_time = '';
      x.ref_code = '';
      x.eta_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    }

    setState((prevState) => ({
      ...prevState,
      shipmentClone: x,
    }));
  };

  const $handleSubmit = async () => {
    let newList = [];

    if (form_general?.service?.auto_manifest) {
      await shipment_lists.forEach((data) => {
        newList.push({
          origin_id: data?.origin?.id,
          destination_id: data?.destination?.id,
          etd_at: UTCFormat(data?.etd_at?.formatted, data?.etd_time, true),
          eta_at: UTCFormat(data?.eta_at?.formatted, data?.eta_time, true),
          items: data?.items,
          type: data?.type,
          pickup_note: data?.pickup_note,
          instruction: data?.instruction,
          ref_code: data?.ref_code,
          cod_value: data?.cod_value,
        });
      });
    } else {
      await shipment_lists.forEach((data) => {
        newList.push({
          destination_id: data?.destination?.id,
          eta_at: UTCFormat(data?.eta_at?.formatted, data?.eta_time, true),
          items: data?.items,
          instruction: data?.instruction,
          ref_code: data?.ref_code,
          unit_price: data?.unit_price,
          cod_value: data?.cod_value,
        });
      });
    }

    const payload = {
      customer_id: form_general?.customer?.id,
      ref_code: form_general?.ref_code,
      service_type_id: form_general?.service_type?.id,
      note: form_general?.note,
      shipper: form_general?.shipper,
      shipments: newList,
    };

    if (form_general?.service?.auto_manifest) {
      payload.type = 'ftl';
      payload.unit_price = form_general?.unit_price;
    } else {
      payload.type = 'ltl';
      payload.pickup_address_id = form_general?.pickup_address?.id;
      if (payload?.pickup_address_id) {
        payload.etd_at = UTCFormat(
          form_general?.etd_at?.formatted,
          form_general?.etd_time,
          true
        );
      }
    }

    const req = await Dispatch(Order.$create(payload));

    if (req?.message === 'success') {
      History.push(`/order/${req?.data?.id}`);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  return (
    <Page type="form" onSubmit={$handleSubmit} className="detail-fixed">
      <Page.Header
        title="Buat Order Baru"
        className="bg-light"
        backTo="/order"
      />

      <Page.Body className="row bg-light border-top">
        <div
          className="col-12 col-md-4 position-md-fixed bg-white h-100 border-end border-sm-none"
          style={{ paddingRight: 10 }}
        >
          <General
            onChange={(e) =>
              setState((prevState) => ({ ...prevState, form_general: e }))
            }
            resetShipment={() =>
              setState((prevState) => ({
                ...prevState,
                shipment_lists: [],
                update: null,
                shipmentClone: null,
              }))
            }
          />
        </div>

        <div className="col-md-8 col-12 offset-md-4 pe-0 ps-0 ps-md-0 mt-5 mt-md-0">
          <Shipments
            service={form_general?.service}
            serviceType={form_general?.service_type}
            data={update?.data}
            lastData={shipmentClone}
            customer={form_general?.customer}
            onSave={(e) =>
              setState((prevState) => ({
                ...prevState,
                shipment_lists: [...prevState?.shipment_lists, e],
              }))
            }
            onUpdate={$onUpdate}
            onAddPoint={$onAddPoint}
            firstData={update?.firstData}
            isEmptyList={shipment_lists?.length === 0 ? true : false}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                update: null,
              }));
            }}
          />
          {StateForm?.errors?.shipments && (
            <div className="p-3 pt-0">
              <div className="alert alert-danger">
                {StateForm?.errors?.shipments}
              </div>
            </div>
          )}

          {form_general?.service && (
            <BulkImport
              customer={form_general?.customer}
              serviceName={form_general?.service?.auto_manifest ? 'trucking': 'cargo'}
              onImported={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  shipment_lists: [...prevState?.shipment_lists, e],
                }))
              }
            />
          )}

          <ShipmentList
            data={shipment_lists}
            service={form_general?.service}
            onDelete={$handleDelete}
            onUpdate={$handleUpdate}
            setTotal={(e) =>
              setState((prevState) => ({
                ...prevState,
                total: e,
              }))
            }
          />
        </div>
      </Page.Body>

      <Page.Footer className="bg-light justify-content-center gap-md-2 pt-2 pb-0 pb-md-3 mb-2 mb-md-0 border-top d-md-flex d-block flex-wrap">
        <Button
          type="button"
          className="btn-secondary py-md-2"
          onClick={() => {
            History.replace(`/order`);
          }}
        >
          Batal &amp; Kembali
        </Button>
        <Button
          type="submit"
          loading={StateForm?.loading}
          disabled={shipment_lists?.length === 0 ? true : false}
          className="btn-primary py-md-2"
        >
          Simpan
        </Button>
      </Page.Footer>
    </Page>
  );
};

export default CreateScreen;
