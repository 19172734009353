import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import * as Fi from 'react-icons/fi';

const initialState = {
  privilege: null,
};

const Action = ({ data }) => {
  const Session = useSelector((state) => state.Auth?.session);

  const [{ privilege }, setState] = React.useState(initialState);

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.tms.order',
    });
    setState((prevState) => ({ ...prevState, privilege: x }));
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  if (privilege?.action === 'manage' || !Session?.privileges) {
    return (
      <>
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Detil</Tooltip>}
          >
            <Link
              className="btn btn-light px-2"
              role="button"
              to={`/order/${data?.id}`}
            >
              <Fi.FiArrowRight />
            </Link>
          </OverlayTrigger>
      </>
    );
  }

  return false;
};

export default Action;
