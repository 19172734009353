import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $processing, $done } from '../activity/action';
import { $failure, $requesting, $success } from '../form/action';
import { Table } from 'services/action';
import { toast } from 'react-toastify';

export const MODULE = 'Auth';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $signin = ActionCreator.Async(MODULE, 'signin', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/auth/signin`, payload)
      .then((response) => {
        if (!response?.data?.user?.is_superuser) {
          dispatch($signin.success({ response: response?.data }));
          dispatch($success.action());
          return true;
        }
        dispatch($failure({ error: null }));
        return false;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $fetchProfile = ActionCreator.Async(MODULE, 'fetchProfile', () => {
  return async (dispatch) => {
    dispatch($processing($fetchProfile.OPERATION));

    return await Rest.GET(`${API_URL}/auth/me`)
      .then((response) => {
        dispatch($fetchProfile.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($fetchProfile.OPERATION)));
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/user/me`, payload)
      .then((response) => {
        dispatch($update.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $forget = ActionCreator.Async(
  MODULE,
  'Forget.Password',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/auth/forget/password`, payload)
        .then((response) => {
          dispatch($forget.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $resetPassword = ActionCreator.Async(
  MODULE,
  'Reset.Password',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/auth/reset/password`, payload)
        .then((response) => {
          dispatch($resetPassword.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $logout = ActionCreator.Default(MODULE, 'logout', () => {
  return (dispatch) => {
    const message = 'Anda sudah berhasil keluar';
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch(Table.$reset());
    dispatch($success.action({ message }));
    dispatch($logout.action());
    return;
  };
});
