import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dashboard } from 'services/action';
import _ from 'underscore';
import { Page, DatePicker } from 'ui/components';
import { Skeletons } from 'ui/components/skeleton';
import { formatDate } from 'utilities/common';
import * as Section from './component';

const initialState = {
  filter: {
    date: new Date(),
    format: formatDate(new Date(), 'YYYY-MM'),
  },
  data_shipment: null,
  loading: true,
};

const DashboardScreen = () => {
  const Dispatch = useDispatch();
  const ShipmentLoading = useSelector(
    (state) => state?.Activity?.services['Dashboard.Order']
  );
  const Session = useSelector((state) => state.Auth?.session);

  const [
    { filter, data_shipment, loading },
    setState,
  ] = React.useState(initialState);

  const $onLoad = async (e) => {
    const payload = {};

    if (e) {
      payload.monthly = e;
    }

    const result = await Dispatch(Dashboard.$order(payload));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, data_shipment: result?.data }));
    }
  };

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.tms.manifest',
    });
    setState((prevState) => ({
      ...prevState,
      privilege: x,
    }));
  };

  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    $onLoad(filter?.format);
    setState((prevState) => ({ ...prevState, loading: false }));
  }, [filter]);

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  return (
    <Fragment>
      <Page>
        <Page.Header
          pretitle=""
          title="Dashboard"
          isLoading={loading}
        >
          {loading ? (
            <Skeletons height="30px" width="250px" />
          ) : (
            <DatePicker
              selectionMode="month"
              value={filter?.date}
              onChange={(date) => {
                setState((prevState) => ({
                  ...prevState,
                  filter: { date: date, format: formatDate(date, 'YYYY-MM') },
                }));
              }}
              isShowTrigger={true}
            />
          )}
        </Page.Header>
        <Page.Body className="bg-light p-3 pt-0">
          <div className="row">
            <Section.CardMap filter={filter} />
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Section.CardStatisticShipment
                isLoading={ShipmentLoading}
                data={data_shipment?.shipment_statistic}
                graph={data_shipment?.shipment_graph_daily_service}
              />
            </div>
            <div className="col-md-6">
              <Section.CardShipment
                isLoading={ShipmentLoading}
                data={data_shipment?.shipment_not_dispatch}
              />
            </div>
          </div>
        </Page.Body>
      </Page>
    </Fragment>
  );
};

export default DashboardScreen;
