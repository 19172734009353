import React from 'react';
import Menu from '../menu';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import LogoWhite from 'ui/assets/envio-white.png';

const Header = ({ fluid, consended }) => {
  const History = useHistory();
  const client = useSelector((state) => state?.Auth?.client);

  return (
    <header className="navbar navbar-expand-md navbar-dark d-print-none ">
      <div className={fluid ? 'container-fluid' : 'container-xl'}>
        <Menu.Mobile />
        <h1
          className={cn(
            'navbar-brand d-none-navbar-horizontal cursor-pointer',
            client?.logo ? 'bg-light-lt p-1 rounded-3' : 'navbar-brand-autodark'
          )}
          onClick={() => History.push('/')}
        >
          <img
            src={client?.logo || LogoWhite}
            alt=""
            className="img-fluid"
            style={{ maxWidth: 150, maxHeight: 30 }}
            id="logo-operator"
          />
        </h1>
        <div className="navbar-nav flex-row order-md-last">
          <Menu.Right />
          <Menu.Profile />
        </div>
        {consended ? <Menu.Consended fluid={fluid} /> : null}
      </div>
    </header>
  );
};

export default Header;
