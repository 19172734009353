import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Order } from 'services/action';
import { Page, Button } from 'ui/components';
import _ from 'underscore';
import { UTCFormat } from 'utilities/common';
import { formatDate } from 'utilities/common';
import { General, ShipmentList, Shipments } from './component/form';

const initialState = {
  form_general: null,
  shipment_lists: [],
  update: null,
  data: null,
  shipmentClone: null,
};

const UpdateScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const StateForm = useSelector((state) => state.Form);

  const [
    { form_general, shipment_lists, update, data, shipmentClone },
    setState,
  ] = React.useState(initialState);

  const $onLoad = async (id) => {
    const req = await Dispatch(Order.$show(id));

    if (req?.message === 'success' && req?.data) {
      setState((prevState) => ({ ...prevState, data: req?.data }));
    } else {
      History.push('/order');
    }
  };

  const $handleDelete = async (id) => {
    let newList = [...shipment_lists];
    newList.splice(id, 1);
    setState((prevState) => ({ ...prevState, shipment_lists: newList }));
  };

  const $handleUpdate = async (d, id) => {
    setState((prevState) => ({
      ...prevState,
      update: { id: id, data: shipment_lists[id] },
    }));
  };

  const $onUpdate = async (d) => {
    let newList = [...shipment_lists];
    newList[update?.id] = d;

    setState((prevState) => ({
      ...prevState,
      shipment_lists: newList,
      update: null,
    }));
  };

  const $onAddPoint = async (type) => {
    let x = Object.assign({}, _.last(shipment_lists));

    x.items = [{}];
    x.delivery_order_id = '';
    x.unit_price = 0;
    x.cod_value = 0;

    if (type === 'pickup') {
      x.type = 'pickup';
      x.origin = null;
      x.etd_at = null;
      x.etd_time = '';
      x.etd_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    } else if (type === 'reverse') {
      const o = x.origin;
      const d = x.destination;
      x.type = 'reverse';
      x.origin = d;
      x.destination = o;
      x.etd_time = '';
      x.etd_at = x.eta_at;
    } else if (type === 'point') {
      x.type = 'point'
      x.destination = null
      x.origin = null
      x.eta_time = '';
      x.ref_code = '';
      x.eta_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    } else {
      x.type = 'drop_point';
      x.destination = null;
      x.eta_time = '';
      x.ref_code = '';
      x.eta_at = {
        default: new Date(),
        formatted: formatDate(new Date(), 'YYYY-MM-DD'),
      };
    }

    setState((prevState) => ({
      ...prevState,
      shipmentClone: x,
    }));
  };

  const $handleSubmit = async () => {
    let newList = [];

    if (form_general?.service?.auto_manifest) {
      await shipment_lists.forEach((data) => {
        newList.push({
          delivery_order_id: data?.delivery_order_id,
          origin_id: data?.origin?.id,
          destination_id: data?.destination?.id,
          etd_at: UTCFormat(data?.etd_at?.formatted, data?.etd_time, true),
          eta_at: UTCFormat(data?.eta_at?.formatted, data?.eta_time, true),
          items: data?.items,
          type: data?.type,
          pickup_note: data?.pickup_note,
          instruction: data?.instruction,
          ref_code: data?.ref_code,
          cod_value: data?.cod_value,
        });
      });
    } else {
      await shipment_lists.forEach((data) => {
        newList.push({
          delivery_order_id: data?.delivery_order_id,
          destination_id: data?.destination?.id,
          eta_at: UTCFormat(data?.eta_at?.formatted, data?.eta_time, true),
          items: data?.items,
          ref_code: data?.ref_code,
          unit_price: data?.unit_price,
          cod_value: data?.cod_value,
          instruction: data?.instruction,
        });
      });
    }

    const payload = {
      customer_id: form_general?.customer?.id,
      ref_code: form_general?.ref_code,
      service_type_id: form_general?.service_type?.id,
      note: form_general?.note,
      shipper: form_general?.shipper,
      shipments: newList,
    };

    if (form_general?.service?.auto_manifest) {
      payload.type = 'ftl';
      payload.unit_price = form_general?.unit_price;
    } else {
      payload.type = 'ltl';
      payload.pickup_address_id = form_general?.pickup_address?.id;
      if (payload?.pickup_address_id) {
        payload.etd_at = UTCFormat(
          form_general?.etd_at?.formatted,
          form_general?.etd_time,
          true
        );
      }
    }

    const req = await Dispatch(Order.$update(data?.id, payload));

    if (req?.message === 'success') {
      History.push(`/order/${req?.data?.id}`);
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    let d = [];
    _.each(data?.delivery_orders, function (i) {
      if (i?.shipment?.type === 'ltl') {
        d.push({
          ...i?.shipment,
          delivery_order_id: i?.id,
          origin: null,
          etd_at: null,
          eta_at: {
            default: new Date(i?.shipment?.eta_at),
            formatted: formatDate(i?.shipment?.eta_at, 'YYYY-MM-DD'),
          },
          eta_time: formatDate(i?.shipment?.eta_at, 'HH:mm'),
          unit_price: i?.unit_price,
          cod_value: i?.shipment?.cod_value,
        });
      } else {
        d.push({
          ...i?.shipment,
          delivery_order_id: i?.id,
          etd_at: {
            default: new Date(i?.shipment?.etd_at),
            formatted: formatDate(i?.shipment?.etd_at, 'YYYY-MM-DD'),
          },
          etd_time: formatDate(i?.shipment?.etd_at, 'HH:mm'),
          eta_at: {
            default: new Date(i?.shipment?.eta_at),
            formatted: formatDate(i?.shipment?.eta_at, 'YYYY-MM-DD'),
          },
          eta_time: formatDate(i?.shipment?.eta_at, 'HH:mm'),
          type: i?.type,
          cod_value: i?.shipment?.cod_value,
        });
      }
    });

    setState((prevState) => ({ ...prevState, shipment_lists: d }));
  }, [data]);

  return (
    <Page type="form" onSubmit={$handleSubmit} className="detail-fixed">
      <Page.Header title="Perbaharui" className="bg-light" backTo="/order" />

      <Page.Body className="row bg-light border-top">
        <div
          className="col-12 col-md-4 position-md-fixed bg-white h-100 ps-3 border-end border-sm-none"
          style={{ paddingRight: 10 }}
        >
          <General
            data={data}
            onChange={(e) =>
              setState((prevState) => ({ ...prevState, form_general: e }))
            }
            // resetShipment={() =>
            //   setState((prevState) => ({ ...prevState, shipment_lists: [] }))
            // }
          />
        </div>

        <div className="col-md-8 col-12 offset-md-4 pe-0 ps-0 ps-md-0 mt-3 mt-md-0">
          <Shipments
            service={form_general?.service}
            serviceType={form_general?.service_type}
            data={update?.data}
            lastData={shipmentClone}
            customer={form_general?.customer}
            onSave={(e) =>
              setState((prevState) => ({
                ...prevState,
                shipment_lists: [...prevState?.shipment_lists, e],
              }))
            }
            onUpdate={$onUpdate}
            onAddPoint={$onAddPoint}
            firstData={update?.firstData}
            isEmptyList={shipment_lists?.length === 0 ? true : false}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                update: null,
              }));
            }}
          />
          {StateForm?.errors?.shipments && (
            <div className="p-3 pt-0">
              <div className="alert alert-danger">
                {StateForm?.errors?.shipments}
              </div>
            </div>
          )}
          <ShipmentList
            data={shipment_lists}
            service={form_general?.service}
            onDelete={$handleDelete}
            onUpdate={$handleUpdate}
            setTotal={(e) =>
              setState((prevState) => ({
                ...prevState,
                total: e,
              }))
            }
          />
        </div>
      </Page.Body>

      <Page.Footer className="bg-light justify-content-center gap-md-2 pt-2 pb-3 mb-2 mb-md-0 border-top d-md-flex d-block flex-wrap">
        <Button
          type="button"
          className="btn-secondary py-md-2"
          onClick={() => {
            History.replace(`/order`);
          }}
        >
          Batal &amp; Kembali
        </Button>
        <Button
          type="submit"
          loading={StateForm?.loading}
          className="btn-primary py-md-2"
        >
          Simpan
        </Button>
      </Page.Footer>
    </Page>
  );
};

export default UpdateScreen;
