import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tracking } from 'services/action';

import { List } from 'ui/components';
import { SkeletonText } from 'ui/components/skeleton';

const initialState = {
  data: null,
};

export const Log = ({ code }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Tracking.Get']
  );

  const dummy = Array.from({ length: 3 }, (v, i) => i);
  const [{ data }, setState] = React.useState(initialState);

  const $onLoad = async (code) => {
    const res = await Dispatch(Tracking.$get(code));

    if (res?.message === 'success') {
      setState((prevState) => ({ ...prevState, data: res?.data }));
    }
  };

  React.useEffect(() => {
    if (!code) return;

    $onLoad(code);
  }, [code]);

  return (
    <section className="pt-2 mb-3" id="log">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Log
        </div>
      )}

      <div className="log-container border-0 pt-2">
        <div className="log-timeline">
          <ul className="log">
            {StateLoading
              ? dummy?.map((d, i) => (
                <li className="log-item" ng-repeat="itembx" key={i}>
                  <div className="row justify-content-between">
                    <div className="col-4 mt-n1">
                      <SkeletonText count={1} />
                    </div>
                    <div className="col-2 mt-n1">
                      <SkeletonText count={1} />
                    </div>
                  </div>
                  <div className="text-muted mt-n1">
                    <SkeletonText count={1} />
                  </div>
                </li>
              ))
              : data?.logs?.map((d, i) => (
                <List
                  key={i}
                  content={
                    <span>{d?.message?.replace('console', 'transit')}</span>
                  }
                  log={d?.recorded_at}
                />
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
