import React from 'react';
import { formatDate } from 'utilities/common';

const List = ({ log, content }) => {
  return (
    <li className="log-item" ng-repeat="itembx">
      <div className="log-timestamp">
        <div className="d-flex justify-content-between">
          <div>{formatDate(log, 'DD MMM YYYY')}</div>
          <div>{formatDate(log, 'HH:mm')}</div>
        </div>
      </div>
      <div className="text-muted">{content}</div>
    </li>
  );
};

export default List;
