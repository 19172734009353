import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Auth } from 'services/action';
import { Page, Form, Button, Alert } from 'ui/components';

const UserScreen = () => {
  const History = useHistory();
  const Dispatch = useDispatch();

  const hub = useSelector((state) => state?.Auth?.hub?.id);
  const StateForm = useSelector((state) => state.Form);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Auth.fetchProfile']
  );

  const [data, setData] = React.useState(null);
  const [password, setPassword] = React.useState('');
  const [cpassword, setCPassword] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);

  const $onLoad = async () => {
    const d = await Dispatch(Auth.$fetchProfile());
    if (d?.message === 'success') {
      setData(d?.data?.user);
    }
  };

  const $handleSubmit = async (e) => {
    const payload = {
      hub_id: hub,
      name: data?.name,
      phone: data?.phone,
      password: password,
      confirm_password: cpassword,
    };

    const update = await Dispatch(Auth.$update(payload));

    if (update?.message === 'success') {
      setShowAlert(true);
      Dispatch(Auth.$fetchProfile());
    }
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  if (StateLoading || !data) return null;

  return (
    <Page
      type="form"
      onSubmit={$handleSubmit}
      className="col-lg-6 col-md-10 bordered"
    >
      <Page.Header pretitle="Perbaharui" title="Akun" />
      <Page.Body className="padding bordered">
        <div className="row">
          <Alert
            show={showAlert}
            variant="success"
            headerContent="Your profile has been successfully updated"
            className="mb-0"
          ></Alert>
          <div className="col-md-6">
            <div className="hr-text hr-text-left text-primary mt-3 mb-3">
              Detil Akun
            </div>
            <div className="form-group">
              <Form.Input
                required
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
                label="Nama"
                error={StateForm?.errors?.name}
              />
              <Form.Input
                required
                value={data?.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                type="phone"
                label="Nomor Handphone"
                error={StateForm?.errors?.phone}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="hr-text hr-text-left text-primary mt-3 mb-3">
              Ganti Password
            </div>
            <div className="form-group">
              <Form.Input
                value={password}
                onChange={(e) =>
                  setPassword(e.target.value)
                }
                label="Password"
                type="text"
                error={StateForm?.errors?.password}
              />
              <Form.Input
                value={cpassword}
                onChange={(e) =>
                  setCPassword(e.target.value)
                }
                label="Konfirmasi Password"
                type="text"
                error={StateForm?.errors?.confirm_password}
              />
            </div>
          </div>
        </div>
      </Page.Body>
      <Page.Footer className="padding d-grid gap-2 d-md-flex justify-content-md-center">
        <Button
          type="button"
          className="btn-secondary py-md-2"
          onClick={() => {
            History.replace(`/`);
          }}
        >
          Batal &amp; Kembali
        </Button>
        <Button type="submit" className="py-md-2" loading={StateForm?.loading}>
          Simpan
        </Button>
      </Page.Footer>
    </Page>
  );
};

export default UserScreen;
