import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Fi from 'react-icons/fi';
import cn from 'classnames';

// import { Uploader } from 'ui/components';
import { Skeletons, SkeletonText } from 'ui/components/skeleton';
import { Shipment } from 'services/action';

const initialState = {
  isShow: false,
  zoomImg: null,
  data: null,
  temp: null,
  imgShow: 7,
};

export const Document = ({ id, status }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Tracking.Get']
  );

  const dummy = Array.from({ length: 4 }, (v, i) => i);

  const [{ data, isShow, zoomImg, imgShow }, setState] =
    React.useState(initialState);

  const $onLoad = async (id) => {
    const res = await Dispatch(Shipment.$getImage(id));

    if (res?.message === 'success') {
      setState((prevState) => ({ ...prevState, data: res?.data }));
    }
  };

  React.useEffect(() => {
    if (!id) return;
    $onLoad(id);
  }, [id]);

  if (!data) return null;

  return (
    <section className="pt-2 mb-3" id="doc">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Dokumentasi Pengiriman
        </div>
      )}

      {StateLoading ? (
        <div className="d-flex flex-wrap gap-2 align-items-center justify-content-center pt-2">
          {dummy?.map((d, i) => (
            <Skeletons width="50px" height="50px" key={i} />
          ))}
          <Skeletons width="50px" height="50px" />
        </div>
      ) : (
        <div
          className={cn(
            'd-flex flex-wrap pt-2',
            'gap-2 align-items-center justify-content-center'
          )}
        >
          {/* {(privilege?.action === 'manage' || !Session?.privileges) && (
            <>
              {status !== 'void' && (
                <Uploader
                  small
                  setData={(e) =>
                    setState((prevState) => ({ ...prevState, temp: e }))
                  }
                  postImage={(e) => console.log(e)}
                />
              )}
            </>
          )} */}

          {data?.slice(0, imgShow)?.map((d, i) => (
            <div key={i} style={{ width: 50, height: 50 }}>
              <img
                className="img-hover small rounded-3"
                src={d?.image}
                alt=""
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isShow: true,
                    zoomImg: d,
                  }));
                }}
              />
            </div>
          ))}

          {data?.length > 7 ? (
            imgShow < data?.length ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-secondary-lt text-uppercase fw-bold rounded-3 uploader"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    imgShow: data?.length,
                  }))
                }
                style={{ cursor: 'pointer', height: 50, width: 50 }}
              >
                <div
                  className=""
                  style={{
                    textOrientation: 'upright',
                    writingMode: 'horizontal-tb',
                    textAlign: 'center',
                    fontSize: 10,
                  }}
                >
                  <Fi.FiChevronsRight size={20} />
                </div>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-secondary-lt text-uppercase fw-bold rounded-3 uploader"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    imgShow: 7,
                  }))
                }
                style={{ cursor: 'pointer', height: 50, width: 50 }}
              >
                <div
                  className=""
                  style={{
                    textOrientation: 'upright',
                    writingMode: 'horizontal-tb',
                    textAlign: 'center',
                    fontSize: 10,
                  }}
                >
                  <Fi.FiChevronsLeft size={20} />
                </div>
              </div>
            )
          ) : null}
        </div>
      )}

      <Modal
        show={isShow}
        onHide={() =>
          setState((prevState) => ({
            ...prevState,
            isShow: false,
          }))
        }
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div
            className="btn-close"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isShow: false,
              }))
            }
          />
          <div className="text-center mt-4" style={{ maxHeight: 300 }}>
            <img
              src={zoomImg?.image?.replace('_thumb', '')}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 300 }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};
