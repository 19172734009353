import React from 'react';
import { useSelector } from 'react-redux';

import { ListHorizontal } from 'ui/components';
import { SkeletonText } from 'ui/components/skeleton';

import { currencyFormat } from 'utilities/common';

export const InformationPricing = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['{ Suspense }.Show']
  );

  const dummy = Array.from({ length: 8 }, (v, i) => i);

  return (
    <section className="pt-2 mb-3" id="pricing">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Rincian Harga
        </div>
      )}

      <div className="row pt-2">
        {StateLoading ? (
          dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <ListHorizontal
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            </div>
          ))
        ) : (
          <>
            <div className="col-md-6 col-12">
              <ListHorizontal
                title={
                  data?.service_type
                    ? `Harga Pengiriman (${data?.service_type?.pricing_schema?.replaceAll(
                        '_',
                        ' '
                      )})`
                    : 'Harga Pengiriman'
                }
                content={currencyFormat(data?.unit_price || 0)}
              />

              <ListHorizontal
                title="Total Harga"
                content={currencyFormat(data?.total_charges)}
              />
            </div>

            <div className="col-md-6 col-12">
              <ListHorizontal
                title="Cost"
                content={currencyFormat(data?.total_cost)}
              />

              <ListHorizontal
                title="Biaya Tambahan"
                content={currencyFormat(data?.additional_charges)}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};
