import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import {  $done } from '../activity/action';

export const MODULE = 'Dashboard';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $order = ActionCreator.Async(MODULE, 'Order', (payload) => {
  return async (dispatch) => {

    return await Rest.GET(`${API_URL}/tms/delivery/dashboard`, payload)
      .then((response) => {
        dispatch($order.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($order.OPERATION)));
  }
})