import React from 'react';
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Fi from 'react-icons/fi';
import cn from 'classnames';

import { Skeletons } from 'ui/components/skeleton';
import { Order } from 'services/action';

const initialState = {
  showVoid: false,
  showDelete: false,
};

export const Action = ({ data, onSubmit }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const StateForm = useSelector((state) => state.Form);
  const SessionCustomer = useSelector((state) => state.Auth?.customer);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Order.Show']
  );

  const [
    {
      showVoid,
      showDelete,
    },
    setState,
  ] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      showVoid: false,
      showDelete: false,
    }));
  };

  const $handleVoid = async () => {
    const req = await Dispatch(Order.$void(data?.id));
    if (req?.message === 'success') {
      onSubmit();
      $handleClose();
    }
  };

  const $handleDelete = async () => {
    const req = await Dispatch(Order.$delete(data?.id));
    if (req?.message === 'success') {
      $handleClose();
      History.replace('/order');
    }
  };

  const dummy = Array.from({ length: 6 }, (v, i) => i);

  const StandardAction = () => {
    return (
      data?.document_status === 'new' && (
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Perbaharui</Tooltip>}
          >
            <Link
              to={`/order/${data?.id}/update`}
              className={cn('btn btn-primary px-2')}
            >
              <Fi.FiEdit size="17" />
            </Link>
          </OverlayTrigger>
        </>
      )
    );
  };

  const SettingAction = () => {
    return (
      <Dropdown className="d-inline p-0 m-0 split-carret" as="div" align="end">
        <Dropdown.Toggle
          id="dropdown-autoclose-true"
          className="d-flex p-0 justify-content-between "
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Pengaturan</Tooltip>}
          >
            <div
              className=" border-end border-light"
              style={{ padding: '4.4px 8px' }}
            >
              <Fi.FiSettings size="17" className=" border-end" />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-0 rounded-2">
          {data?.document_status === 'new' && (
            <>
              <div
                className="dropdown-item d-flex align-items-center text-danger  cursor-pointer  "
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showVoid: true,
                  }))
                }
              >
                <Fi.FiX size={15} color="#FF1818" className="me-2" />
                Batalkan order
              </div>
              <div
                className="dropdown-item d-flex align-items-center text-danger  cursor-pointer  "
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showDelete: true,
                  }))
                }
              >
                <Fi.FiTrash2 size={15} color="#FF1818" className="me-2" />
                Hapus order
              </div>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (SessionCustomer?.name !== data?.created_by) {
    return null
  }

  return (
    <div className="d-flex align-items-center gap-1 px-3 px-md-0 pb-2 pb-md-0">
      {StateLoading ? (
        dummy?.map((d, i) => <Skeletons key={i} height="35px" width="35px" />)
      ) : (
        <>
          {data?.document_status !== 'voided' && (
            <>
              <StandardAction />
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-top`}>Print Order</Tooltip>}
              >
                <Link
                  to={`/print/order/${data?.id}`}
                  className="btn btn-secondary px-2"
                  target="_blank"
                >
                  <Fi.FiPrinter size={17} />
                </Link>
              </OverlayTrigger>

              {data?.document_status !== 'process' && <SettingAction />}
            </>
          )}
        </>
      )}

      {/* modal showVoid */}
      <Modal
        show={showVoid}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dibatalkan tidak bisa diproses ulang
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleVoid}
                disabled={StateForm?.loading}
              >
                Konfirmasi
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal delete */}
      <Modal
        show={showDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
