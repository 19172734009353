import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $success } from '../form/action';
import { $processing, $done } from 'services/activity/action';

export const MODULE = 'Shipment';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/tms/delivery`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $show = ActionCreator.Async(MODULE, 'Show', (id, payload) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/tms/delivery/${id}`, payload)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});

export const $getImage = ActionCreator.Async(
  MODULE,
  'Get.Image',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($processing($getImage.OPERATION));

      return await Rest.GET(
        `${API_URL}/tms/delivery/shipment/image/${id}/shipment`,
        payload
      )
        .then((response) => {
          dispatch($getImage.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getImage.OPERATION)));
    };
  }
);

export const $deleteImage = ActionCreator.Async(
  MODULE,
  'Delete.Image',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($deleteImage.OPERATION));

      return await Rest.DELETE(`${API_URL}/tms/delivery/shipment/image/${id}`)
        .then((response) => {
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($deleteImage.OPERATION)));
    };
  }
);
