import React from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import * as Bs from 'react-icons/bs';

const initialState = {
  isShow: false,
};

const Tutorial = () => {
  const [{ isShow }, setState] = React.useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const $handleClose = () => {
    clearState();
  };

  return (
    <>
      <div className="d-flex align-items-center py-2">
        <Bs.BsInfoCircleFill size={20} color="#2f83ba" className="me-2" />
        <span>
          Daftar detail pengiriman.{' '}
          <span
            className="fw-bold text-primary ms-1 text-decoration-underline cursor-pointer"
            onClick={() =>
              setState((prevState) => ({ ...prevState, isShow: true }))
            }
          >
            Pelajari Shipment
          </span>
        </span>
      </div>

      <Modal show={isShow} onHide={$handleClose} centered size="md">
        <Modal.Header className="text-center d-block p-0" closeButton>
          <div className="py-3">
            <div className="fw-bold fs-6">Pelajari Shipment</div>
            <div className="fw-semibold">
              Shipment adalah dokumen individual untuk aktifitas pengiriman barang.
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="px-2 py-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="pb-2">
                <div className="fs-6 fw-semibold">Status Shipment</div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                <div className="mb-2">
                  Terdapat beberapa status pada shipment, yaitu :
                </div>

                <div className='table-responsive'>
                  <table className="table table-striped" width={'100%'}>
                    <thead>
                      <th className='text-center' width={'40%'}>Status</th>
                      <th width={'60%'}>Keterangan</th>
                    </thead>
                    <tr>
                      <td className='text-center'><span className="badge status-new-lt ms-auto">New</span></td>
                      <td>Shipment baru yang belum di proses.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-manifested-lt ms-auto">Manifested</span></td>
                      <td>Shipment yang sudah dibuatkan manifest (Dispatch).</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-on_pickup-lt ms-auto">On Pickup</span></td>
                      <td>Shipment yang sedang dalam perjalanan untuk pickup.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-on_transit-lt ms-auto">On Transit</span></td>
                      <td>Shipment yang sedang dalam perjalanan untuk transit.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-on_delivery-lt ms-auto">On Delivery</span></td>
                      <td>Shipment yang sedang dalam perjalanan untuk pengiriman.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-console-lt ms-auto">Transit</span></td>
                      <td>Shipment yang sedang dalam perjalanan untuk pengiriman.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-delivered-lt ms-auto">Delivered</span></td>
                      <td>Shipment yang sudah terkirim dan sudah diterima oleh penerima.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-disputed-lt ms-auto">Disputed</span></td>
                      <td>Shipment yang sudah dilakukan pengiriman akan tetapi gagal untuk dikirim.</td>
                    </tr>
                    <tr>
                      <td className='text-center'><span className="badge status-void-lt ms-auto">Void</span></td>
                      <td>Shipment yang sudah terkirim dan sudah diterima oleh penerima.</td>
                    </tr>
                  </table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header className="py-2">
                <div className="fs-6 fw-semibold">
                  Bagaimana cara untuk melakukan perubahan (update) data shipment ?
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Untuk mengupdate semua data shipment dapat dilakukan pada detail dokumen order yang dibuat untuk shipment terkait. Tetapi untuk mengupdate detail barang dapat dilakukan dengan menekan tombol 'Perbaharui Item' pada list shipment.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="py-2">
                <div className="fs-6 fw-semibold">
                  Apa yang harus dilakukan kepada shipment ?
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Anda dapat melakukan dispatch atau pembuatan manifest sesuai dengan kebutuhan (first mile, mid mile atau last mile).
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="py-2">
                <div className="fs-6 fw-semibold">
                  Apakah shipment yang sudah dibuat bisa dihapus ?
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Shipment bisa dihapus apabila belum dibuatkan manifest untuk
                shipment tersebut. Aksi menghapus shipment ada pada detail order yang dibuat untuk shipment tersebut.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="py-2">
                <div className="fs-6 fw-semibold">
                  Bagaimana cara melihat detail shipment ?
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-3 py-2 border-bottom">
                Untuk melihat detail shipment yang telah dibuat, user dapat
                menekan icon titik tiga di paling kanan baris shipment lalu
                pilih Detail.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tutorial;
