import React from 'react';
import cn from 'classnames';
import _ from 'underscore';
import * as Fi from 'react-icons/fi';
import { NavHashLink } from 'react-router-hash-link';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { SkeletonTable, SkeletonText } from 'ui/components/skeleton';

export const ShipmentList = ({
  data,
  loading,
  onDelete,
  onUpdate,
  setTotal,
  service,
}) => {
  const StateForm = useSelector((state) => state?.Form);

  React.useEffect(() => {
    let sum = _.reduce(
      data,
      function (sum, item) {
        return sum + item?.total_charges;
      },
      0
    );

    setTotal(sum);
  }, [data]);

  const calculateTotal = (items, type) => {
    const x = _.reduce(
      items,
      function (sum, i) {
        return type === 'quantity' ? sum + i.quantity : sum + i.weight;
      },
      0
    );

    return x;
  };

  if (loading) {
    return (
      <div className="border rounded bg-white padding-20 mb-3">
        <div className="pb-2">
          <div style={{ width: 150 }}>
            <SkeletonText count={1} />
          </div>
        </div>
        <SkeletonTable headerCount={7} bodyCount={3} />
      </div>
    );
  }

  if (!loading && (data?.length === 0 || data === null)) {
    return null;
  }

  return (
    <div
      id="table-shipment"
      className="bg-white py-md-3 px-3 border-top border-bottom"
    >
      <div className="row">
        <div className="col-12">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            Daftar Shipment {service?.name}
          </div>
          <div className="table-responsive bg-white">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td>No</td>
                  {service?.auto_manifest && (
                    <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                      Pickup Point
                    </td>
                  )}
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                    {service?.auto_manifest
                      ? 'Drop Point'
                      : 'Destination'}
                  </td>
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      width: '15%',
                      textAlign: 'center',
                    }}
                  >
                    Total Koli
                  </td>
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      width: '15%',
                      textAlign: 'center',
                    }}
                  >
                    Total Berat (Kg)
                  </td>
                  <td></td>
                </tr>
              </thead>

              <tbody>
                {data?.map((d, i) => (
                  <tr
                    key={i}
                    className={cn(
                      StateForm?.errors[`shipments.${i}.recipient`] ||
                        StateForm?.errors[`shipments.${i}.origin_id`] ||
                        StateForm?.errors[`shipments.${i}.recipient.village`] ||
                        StateForm?.errors[`shipments.${i}.sender.village`] ||
                        StateForm?.errors[
                          `shipments.${i}.items.${i}.item_name`
                        ] ||
                        StateForm?.errors[
                          `shipments.${i}.items.${i}.quantity`
                        ] ||
                        StateForm?.errors[`shipments.${i}.items.${i}.weight`]
                        ? 'text-dangers border-danger'
                        : null
                    )}
                  >
                    <td>{i + 1}</td>
                    {service?.auto_manifest && (
                      <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                        <div className="table-col text-uppercase">
                          <div className="fs-8 fw-semibold">
                            {d?.origin?.name}
                          </div>
                          <small className="info text-ellipsis">{`${d?.origin?.region?.district_name}, ${d?.origin?.region?.regency_name}, ${d?.origin?.region?.province_name}`}</small>
                        </div>
                        {StateForm?.errors[`shipments.${i}.origin_id`] ? (
                          <div className="invalid-feedback d-block ">
                            {StateForm?.errors[`shipments.${i}.origin_id`]}
                          </div>
                        ) : null}

                        {StateForm?.errors[`shipments.${i}.eta_at`] ? (
                          <div className="invalid-feedback d-block ">
                            {StateForm?.errors[`shipments.${i}.eta_at`]}
                          </div>
                        ) : null}

                        {d?.items?.map((d, idx) =>
                          StateForm?.errors[
                            `shipments.${idx}.items.${i}.item_name`
                          ] ||
                          StateForm?.errors[
                            `shipments.${idx}.items.${i}.quantity`
                          ] ? (
                            <div
                              className="invalid-feedback d-block "
                              key={idx}
                            >
                              Barang {idx + 1} harus diisi
                            </div>
                          ) : null
                        )}
                      </td>
                    )}
                    <td style={{ whiteSpace: 'nowrap', width: '15%' }}>
                      <div className="table-col text-uppercase">
                        <div className="fs-8 fw-semibold">
                          {d?.destination?.name}
                        </div>
                        <small className="info text-ellipsis">{`${d?.destination?.region?.district_name}, ${d?.destination?.region?.regency_name}, ${d?.destination?.region?.province_name}`}</small>
                      </div>
                      {StateForm?.errors[`shipments.${i}.destination_id`] ||
                      StateForm?.errors[`shipments.${i}.destination_id`] ? (
                        <div className="invalid-feedback d-block ">
                          Alamat penerima tidak lengkap
                        </div>
                      ) : null}

                      {d?.items?.map((d, idx) =>
                        StateForm?.errors[
                          `shipments.${idx}.items.${i}.item_name`
                        ] ||
                        StateForm?.errors[
                          `shipments.${idx}.items.${i}.quantity`
                        ] ? (
                          <div className="invalid-feedback d-block " key={idx}>
                            Barang {idx + 1} harus diisi
                          </div>
                        ) : null
                      )}

                      {StateForm?.errors[`shipments.${i}.eta_at`] ? (
                        <div className="invalid-feedback d-block ">
                          {StateForm?.errors[`shipments.${i}.eta_at`]}
                        </div>
                      ) : null}
                    </td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      {calculateTotal(d?.items, 'quantity')}

                      {StateForm?.errors[
                        `shipments.${i}.items.${i}.quantity`
                      ] ? (
                        <div className="invalid-feedback d-block ">
                          {
                            StateForm?.errors[
                              `shipments.${i}.items.${i}.quantity`
                            ]
                          }
                        </div>
                      ) : null}
                    </td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      {calculateTotal(d?.items, 'weight')}
                    </td>
                    <td className="text-end">
                      <div
                        className="btn-toolbar justify-content-end"
                        role="toolbar"
                      >
                        <div className="btn-group" role="group">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Perbaharui</Tooltip>
                            }
                          >
                            <NavHashLink
                              to="#form-shipment"
                              className="btn btn-ghost-primary px-2"
                              onClick={() => onUpdate(d, i)}
                            >
                              <Fi.FiEdit size={17} />
                            </NavHashLink>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Hapus</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              onClick={() => onDelete(i)}
                              className="btn btn-ghost-danger px-2"
                            >
                              <Fi.FiTrash2 size={17} />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
