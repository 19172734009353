import React from 'react';
import { Skeletons } from 'ui/components/skeleton';
import cn from 'classnames';
import CardGraph from './card.graph'

export const CardShipment = ({ isLoading, data, graph }) => {
    const [sla, setSla] = React.useState(0);
    const [slaClass, setSlaClass] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!data) return;

        let sla = ((data?.total - data?.overdue) / data?.total * 100).toFixed(2)
        if (isNaN(sla)) {
            sla = 0
        }

        setSla(sla)

        const cl = (sla > 90) ? 'success' : (sla > 50) ? 'warning' : 'danger';
        setSlaClass(cl)
        setLoading(false)
    }, [data]);

    return loading ? (
        <div className='card p-3 rounded-2 bg-white'>
            <Skeletons height="460px" />
        </div>
    ) : (
        <div className='card p-3 rounded-2 bg-white'>
            <h3 className="card-title dx-card-title border-bottom pb-2">
                Statistic Shipment
            </h3>
            <div className="d-flex justify-content-evenly gap-2">
                <div className='col-5 rounded-2 border border-primary bg-primary-lt text-center p-3'>
                    <div className="fs-4 fw-semibold text-left text-secondary">
                        {data?.total || 0}
                    </div>
                    <div className="page-pretitle text-left">Total Shipment</div>
                </div>
                <div className={cn('col-5 rounded-2 border text-center p-3', `bg-${slaClass}-lt`, `border-${slaClass}`)}>
                    <div className={cn('fs-4 fw-semibold text-left', `text-${slaClass}`)}>
                        {sla || 0}%
                    </div>
                    <div className={cn('page-pretitle', `text-${slaClass}`)}>
                        SLA Delivery Time
                    </div>
                </div>
            </div>
            <div className="mt-3 row">
                <div className="col-3">
                    <div className='rounded-2 border bg-light-lt text-center p-2'>
                        <div className="fs-5 fw-semibold text-left text-secondary">
                            {data?.unprocess || 0}
                        </div>
                        <div className="page-pretitle text-secondary">Baru</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className='rounded-2 border bg-light-lt text-center p-2'>
                        <div className="fs-5 fw-semibold text-left text-secondary">
                            {data?.console || 0}
                        </div>
                        <div className="page-pretitle text-primary">In Transit</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className='rounded-2 border bg-light-lt text-center p-2'>
                        <div className="fs-5 fw-semibold text-left text-secondary">
                            {data?.process || 0}
                        </div>
                        <div className="page-pretitle text-warning">In Process</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className='rounded-2 border bg-light-lt text-center p-2'>
                        <div className="fs-5 fw-semibold text-left text-secondary">
                            {data?.delivered || 0}
                        </div>
                        <div className="page-pretitle text-success">Delivered</div>
                    </div>
                </div>
            </div>

            <div className="mt-3 border-top pt-3">
                <CardGraph data={graph} />
            </div>
        </div>
    )
}
