import React from 'react';
import * as Fi from 'react-icons/fi';
import cn from 'classnames';
import _ from 'underscore';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Page } from 'ui/components';
import {
  SkeletonTable,
  Skeletons,
  SkeletonPageFooter,
} from 'ui/components/skeleton';

import useTable from 'ui/components/table';

import tableConfig from './component/table/config';
import Filter from './component/table/filter';
import Tutorial from './component/table/tutorial';

const initialState = {
  displayFilter: false,
  count: [],
  privilege: null,
};

const OrderScreen = () => {
  const Session = useSelector((state) => state.Auth?.session);
  const OrderTable = useTable('order', tableConfig(Session));
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const [{ displayFilter, count }, setState] =
    React.useState(initialState);

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.tms.delivery',
    });
    setState((prevState) => ({
      ...prevState,
      privilege: x,
    }));
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  return (
    <Page className="fixed ">
      <Page.Header
        pretitle=""
        title={'Daftar Order Pengiriman'}
        isLoading={StateLoading}
        action={{ url: '/order/create', text: 'Buat Order Baru' }}
      />

      <Page.Navigation>
        <Tutorial />
        <OrderTable.Tools downloadable isLoading={StateLoading}>
          {StateLoading ? (
            <div className="ms-2">
              <Skeletons height="35px" width="50px" />
            </div>
          ) : (
            <Button
              variant={displayFilter ? 'primary' : 'secondary'}
              className="ms-2 position-relative"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  displayFilter: !displayFilter,
                }))
              }
              style={{ height: 35 }}
            >
              <Fi.FiFilter size={20} color="white" />
              {count?.length > 0 ? (
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger">
                  {count?.length}
                </span>
              ) : null}
            </Button>
          )}
        </OrderTable.Tools>
      </Page.Navigation>

      <Page.Body className="row m-0 p-0">
        <div
          className={cn(
            displayFilter ? 'col-md-9  m-0 p-0' : 'col-md-12   m-0 p-0'
          )}
        >
          {StateLoading ? (
            <SkeletonTable headerCount={9} bodyCount={9} />
          ) : (
            <OrderTable.Render />
          )}
        </div>

        <Filter
          table={OrderTable}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              displayFilter: false,
            }))
          }
          show={displayFilter}
          setCount={(x) =>
            setState((prevState) => ({
              ...prevState,
              count: x,
            }))
          }
        />
      </Page.Body>
      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <OrderTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default OrderScreen;
