import React from 'react';
import { useSelector } from 'react-redux';

import { ListHorizontal } from 'ui/components';
import { SkeletonText } from 'ui/components/skeleton';

export const InformationShipment = ({ data }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['{ Suspense }.Show']
  );

  const dummy = Array.from({ length: 10 }, (v, i) => i);

  return (
    <section className="pt-2" id="shipment">
      {StateLoading ? (
        <div style={{ width: 250 }}>
          <SkeletonText count={1} />
        </div>
      ) : (
        <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
          Informasi Shipment
        </div>
      )}

      <div className="row mb-4">
        <div className="col-12">
          {StateLoading ? (
            dummy?.map((d, i) => (
              <ListHorizontal
                key={i}
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            ))
          ) : (
            <>
              {data?.shipment?.ref_code && (
                <ListHorizontal
                  title="Kode Ref"
                  content={data?.shipment?.ref_code}
                />
              )}
              {data?.shipment?.tracking_code && (
                <ListHorizontal
                  title="Kode Tracking "
                  content={data?.shipment?.tracking_code}
                />
              )}

              {data?.order?.customer && (
                <ListHorizontal
                  title="Shipper"
                  content={data?.shipment?.shipper?.name}
                />
              )}

              <ListHorizontal
                title="Total Berat (Kg)"
                content={data?.shipment?.total_weight}
              />
              <ListHorizontal
                title="Total Koli"
                content={data?.shipment?.total_quantity}
              />

              {data?.note && (
                <ListHorizontal title="Catatan" content={data?.note} />
              )}

              {data?.current_hub && (
                <ListHorizontal
                  title="Current Hub"
                  content={
                    <span className={`badge bg-primary-lt ms-auto`}>
                      {data?.current_hub?.name}
                    </span>
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
