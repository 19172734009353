import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $failure, $requesting, $success } from '../form/action';
import { $processing, $done } from 'services/activity/action';

export const MODULE = 'Order';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/tms/delivery/order`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $show = ActionCreator.Async(MODULE, 'Show', (id, payload) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/tms/delivery/order/${id}`, payload)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});

export const $create = ActionCreator.Async(MODULE, 'Create', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/tms/delivery/order`, payload)
      .then((response) => {
        dispatch($create.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/tms/delivery/order/${id}`, payload)
      .then((response) => {
        dispatch($update.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $delete = ActionCreator.Async(MODULE, 'Delete', (id) => {
  return async (dispatch) => {
    dispatch($processing($delete.OPERATION));

    return await Rest.DELETE(`${API_URL}/tms/delivery/order/${id}`)
      .then((response) => {
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($delete.OPERATION)));
  };
});